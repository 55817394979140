import { useEffect } from "react";
import { Link } from "react-router-dom";
import { TermNavItemI } from "./types";

function TermNavItem({
  section,
  activeLink,
  setActiveLink,
  parentNode,
  className,
}: TermNavItemI) {

  const handleTermsNavLinkClick = (id: string) => {
    setActiveLink(id)
    const parentNodes = document.querySelectorAll('.terms__nav_link_parent_node');
    parentNodes.forEach((node) => node.classList.remove('active'))

  }

  useEffect(() => {
    const activeNode = document.querySelector(`[href='/terms#${activeLink}'`) as HTMLAnchorElement;
    const activeParentNodeTitle = activeNode?.parentNode?.parentNode?.parentNode?.firstChild as HTMLAnchorElement;

    if (activeLink === section.id) {
      activeParentNodeTitle.classList.add('active')
    } 
  }, [activeLink, section.id])
  
  return (
    <li
      className="terms__navigation_list_item"
      onClick={() => handleTermsNavLinkClick(section.id)}
    >
      <Link 
        to={`#${section.id}`}
        className={`terms__nav_link ${activeLink === section.id && 'active'} ${!!!parentNode && 'terms__nav_link_parent_node'}`}
      >
        {section.section}
      </Link>
      {!!section.subSections &&
        <ul className="terms__nav_link__subsections_list">  
          {section.subSections.map((subSection, i) => {
            return (
              <TermNavItem
                key={`subSection_${section.id}_${i}`} 
                section={subSection}
                activeLink={activeLink}
                setActiveLink={setActiveLink}
                parentNode={section.id}
                className="terms__nav_link__subsection"
              />
            )
          })}
        </ul>
      }
    </li>
  )
}

export default TermNavItem;
