import { useContext, useState, useEffect, useRef, ChangeEvent } from 'react';
import { Link } from 'react-router-dom';
import { ModalContext } from '../Modal/ModalProvider';
import './placeBidModal.sass';
import SingleArtCard from '../../components/SingleArtCard/SingleArtCard';
import Button from '../../components/Button/Button';
import ButtonApprove from './ButtonApprove/ButtonApprove';
import { TooltipIcon } from '../../components/Svg';
import ButtonPlaceBid from './ButtonPlaceBid/ButtonPlaceBid';
import { CURRENCY, WEI } from '../../data/constants';
import { erc20Contract, web3 } from '../../network/service/web3';
import { TransactionReceipt } from 'web3-core';
import { useAppSelector } from '../../store/hooks';
import { selectUserAddress } from '../../store/features/userData/userDataSlice';
import { auctionContractAddress } from '../../network/networkConfig';
import { getRoundedValue } from '../../utils/getRoundedValue';
import {
  showToastApproveUSDT,
  showToastCustomTextError,
  showToastEnterNumber,
  showToastNotEnoughFunds
} from '../../data/toasts';
import { placeBidModalLinkHow, placeBidModalTooltipAllow } from '../modalTexts';

interface PlaceBidModalInterface {
  tokenId: string | number,
  isLoaded: boolean,
  usdBalance: number,
  minimumBid: number,
  startPrice: number,
  highestBid: number,
  setIsBidPlaced: React.Dispatch<React.SetStateAction<boolean>>,
  setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>,
  beneficiary: string,
  setBidTransactionHash: any
}

function PlaceBidModal(props: PlaceBidModalInterface) {
  const {
    tokenId,
    isLoaded,
    usdBalance,
    minimumBid,
    startPrice,
    highestBid,
    setIsBidPlaced,
    setIsProcessing,
    beneficiary,
    setBidTransactionHash,
  } = props;

  const account = useAppSelector(selectUserAddress);

  const { handleModalClose } = useContext(ModalContext);
  const [isBalanceLoaded, setIsBalanceLoaded] = useState(false);
  const [isAllowanceApproved, setIsAllowanceApproved] = useState(false);
  const [bidValue, setBidValue] = useState(0);
  const [isBidEnough, setIsBidEnough] = useState(false);
  const [allowance, setAllowance] = useState(0);
  const modalRef = useRef<HTMLDivElement>(null);
  const [approveTransactionHash, setApproveTransactionHash] = useState('');
  const [approveInProcess, setApproveInProcess] = useState(false);

  const minBid = startPrice > highestBid ? startPrice : minimumBid;

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    let currentValue = 0;

    if (!isNaN(Number(value))) {
      currentValue = Number(value)
    } else {
      console.log('Not a Number');
    }

    if (currentValue === null) {
      e.target.style.borderColor = "#EB4141";
      showToastEnterNumber();
      setBidValue(0);
    } else if (currentValue > usdBalance) {
      e.target.style.borderColor = "#EB4141";
      setBidValue(currentValue);
      showToastNotEnoughFunds();
    } else if (currentValue < getRoundedValue(minBid)) {
      setIsBidEnough(false);
      setBidValue(currentValue);
      e.target.style.borderColor = '';
    } else {
      setIsBidEnough(true);
      setBidValue(currentValue);
      e.target.style.borderColor = '';
    }
  }

  const handleInputBlur = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.value !== '') {
      e.target.style.borderColor = '#E7E8FF';
      e.target.style.backgroundColor = '#fff';
    } else {
      e.target.style.borderColor = '';
      e.target.style.backgroundColor = '';
    }
  }

  useEffect(() => {
    if (usdBalance !== 0) {
      setIsBalanceLoaded(true)
    } else {
      return
    }
  }, [usdBalance])

  useEffect(() => {
    if (allowance >= bidValue) {
      setIsAllowanceApproved(true)
    } else {
      erc20Contract.methods.allowance(account, auctionContractAddress).call()
        .then((result: any) => {
          const allowance = result/WEI;
          setAllowance(allowance);
          if (allowance >= bidValue) {
            setIsAllowanceApproved(true)
          } else {  
            setIsAllowanceApproved(false)
          }
        })
    }
  }, [account, allowance, bidValue])

  useEffect(() => {
    function handleClickOutside(event: Event) {
      if (modalRef.current && !modalRef.current.contains(event.target as Node)) {
        handleModalClose()
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef, handleModalClose])

  useEffect(() => {
    if (account === beneficiary) {
      handleModalClose()
    }
  }, [account, beneficiary, handleModalClose])

  useEffect(() => {
    if (isAllowanceApproved) {
      erc20Contract.methods.allowance(account, auctionContractAddress).call()
        .then((result: any) => {
          const allowance = result/WEI;
          setAllowance(allowance);
        })
    }
  }, [account, bidValue, isAllowanceApproved])

useEffect(() => {
  if (approveTransactionHash) {
    erc20Contract.events.Approval({
      filter: {
        owner: account,
        spender: auctionContractAddress,
      },
    }, (error: Error, result: any) => {
        web3.eth.getTransactionReceipt(approveTransactionHash)
          .then((receipt: TransactionReceipt) => {
            if (!!receipt && !!receipt.status) {
              showToastApproveUSDT();
              setIsAllowanceApproved(true)
              setApproveInProcess(false)
            } else if (!!receipt && !!!receipt.status){
              showToastCustomTextError('An error occurred while USDT usage approving')
            }
          })
    })
    return () => setApproveTransactionHash('')
  }
}, [account, approveTransactionHash])

  return (
    <div
      ref={modalRef}
      className="place_a_bid_modal__container"
    > 
      <div className="place_a_bid__artwork_card">
        {<SingleArtCard tokenId={tokenId} isLoaded={isLoaded} isBidModal={true}/>}
      </div>
      <div className="place_a_bid__bid_form">
        <header className="modal__header">
          <div className="modal__title">
            <h3 className="modal__heading">
              Place a bid to start an auction
            </h3> 
            <p className="modal__subtitle">
              You must bid at least <span className="place_a_bid__header_min_amount">
                {getRoundedValue(minBid)}
              </span> {CURRENCY}
            </p> 
          </div>
        </header>
        <div className="place_a_bid__input_container">
          <label 
            htmlFor="place_a_bid_input"
            className="place_a_bid__input_label"
          >
            <span className="place_a_bid__input_title">Place a bid</span>
            { isBalanceLoaded ? 
              <span className="place_a_bid__input_available_text">Your available balance is  
                <span className="place_a_bid__input_available_balance"
                  > {usdBalance} {CURRENCY}
                </span>
              </span>
              :
              <span className="place_a_bid__input_available_text">
                You don't have USDT
              </span>
            }
          </label>
          <input 
            type="number"
            id="place_a_bid_input"
            name="place_a_bid_input" 
            className="place_a_bid_input" 
            placeholder={`0 ${CURRENCY}`}
            value={bidValue ? bidValue : ''}
            onChange={(e) => handleInputChange(e)}
            onBlur={(e) => handleInputBlur(e)}
          />
        </div>
        <div className="place_a_bid__buttons_container">
          { bidValue >= getRoundedValue(minBid) && allowance <= bidValue && !isAllowanceApproved &&
            <div className="single_artwork__bidding button_with_tooltip">
              <ButtonApprove
                className={ usdBalance !== 0 && !approveInProcess ? 
                    'active' 
                  : usdBalance !== 0 && approveInProcess ?
                    'skeleton' 
                  : ''} 
                usdBalance={usdBalance}
                processing={usdBalance !== 0 && approveInProcess}
                setAllowance={setAllowance}
                setApproveInProcess={setApproveInProcess}
                setApproveTransactionHash={setApproveTransactionHash}
              />
              <div className="place_a_bid__tooltip">
                <TooltipIcon width="20px"/>
                <p className="place_a_bid__tooltip_text">
                  {placeBidModalTooltipAllow}
                </p>
              </div>
            </div>
          }
          <div className="single_artwork__bidding">
            { bidValue === 0 ? 
              <Button className={`button__big ${bidValue === 0 && 'not_active'}`}
              >
                Place a bid
              </Button>
            : bidValue !== 0 && !isBidEnough ?
              <Button className="button__big not_active"
              >
                You must bid at least {getRoundedValue(minBid)} {CURRENCY}
              </Button>
            : allowance === 0 && !isAllowanceApproved ? 
              <Button className="button__big not_active"
              > 
                Place a bid
              </Button>
            : isAllowanceApproved && isBidEnough &&
              <ButtonPlaceBid
                tokenId={tokenId}
                bidValue={bidValue}
                setIsBidPlaced={setIsBidPlaced}
                handleModalClose={handleModalClose}
                setIsProcessing={setIsProcessing}
                setBidTransactionHash={setBidTransactionHash}
              />
            }
          </div>
        </div>
        <div className="place_a_bid__how">
          Haven’t taken part in auctions?
          <Link to={placeBidModalLinkHow}> Read how it works</Link>
        </div>
      </div>
    </div>
  )
};

export default PlaceBidModal;
