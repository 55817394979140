import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 25 25" {...props}>
      <path d="M13.0666 2.8667C12.8898 2.8667 12.7202 2.93694 12.5952 3.06196C12.4701 3.18699 12.3999 3.35656 12.3999 3.53337C12.3999 3.71018 12.4701 3.87975 12.5952 4.00477C12.7202 4.12979 12.8898 4.20003 13.0666 4.20003H19.4599L13.2932 10.3667C13.2234 10.4265 13.1668 10.5 13.1268 10.5827C13.0867 10.6654 13.0642 10.7555 13.0607 10.8473C13.0572 10.9391 13.0726 11.0307 13.1061 11.1163C13.1397 11.2018 13.1905 11.2795 13.2555 11.3445C13.3204 11.4094 13.3981 11.4603 13.4837 11.4938C13.5692 11.5273 13.6608 11.5428 13.7526 11.5392C13.8444 11.5357 13.9345 11.5132 14.0172 11.4732C14.0999 11.4332 14.1735 11.3765 14.2332 11.3067L20.3999 5.14003V11.5334C20.3999 11.7102 20.4701 11.8797 20.5952 12.0048C20.7202 12.1298 20.8898 12.2 21.0666 12.2C21.2434 12.2 21.413 12.1298 21.538 12.0048C21.663 11.8797 21.7332 11.7102 21.7332 11.5334V3.8667C21.7332 3.31441 21.2855 2.8667 20.7332 2.8667H13.0666Z"/>
      <path d="M3.06689 12.8669C3.06689 12.6901 3.13713 12.5205 3.26216 12.3955C3.38718 12.2704 3.55675 12.2002 3.73356 12.2002C3.91037 12.2002 4.07994 12.2704 4.20497 12.3955C4.32999 12.5205 4.40023 12.6901 4.40023 12.8669V19.2602L10.5669 13.0935C10.6267 13.0237 10.7002 12.9671 10.7829 12.927C10.8656 12.887 10.9557 12.8645 11.0475 12.861C11.1393 12.8574 11.2309 12.8729 11.3164 12.9064C11.402 12.94 11.4797 12.9908 11.5447 13.0558C11.6096 13.1207 11.6605 13.1984 11.694 13.284C11.7275 13.3695 11.743 13.4611 11.7394 13.5529C11.7359 13.6447 11.7134 13.7348 11.6734 13.8175C11.6334 13.9002 11.5767 13.9738 11.5069 14.0335L5.34023 20.2002H11.7336C11.9104 20.2002 12.0799 20.2704 12.205 20.3955C12.33 20.5205 12.4002 20.6901 12.4002 20.8669C12.4002 21.0437 12.33 21.2132 12.205 21.3383C12.0799 21.4633 11.9104 21.5335 11.7336 21.5335H4.06689C3.51461 21.5335 3.06689 21.0858 3.06689 20.5335V12.8669Z"/>
    </Svg>
  );
};

export default Icon;
