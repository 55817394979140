import { forwardRef } from "react";
import { CheckboxChecked, Checkbox } from "../Svg";
import './checkbox.sass';

interface Props {
  id: string;
  label: string | React.ReactNode;
  setIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
  isChecked: boolean;
  setShowWarning: React.Dispatch<React.SetStateAction<boolean>>;
  data?: string | number;
  className?: string;
}

const RefCheckbox = forwardRef<HTMLInputElement, Props>((
  props,
  ref
) => {
  const {
    id,
    label,
    setIsChecked,
    isChecked,
    setShowWarning,
    data,
    className
  } = props;


  const handleIsChecked = () => {
    setIsChecked(!isChecked)
    setShowWarning(false)
  }

  return (
    <div className={`styled_checkbox ${className}`}>
      <label
        htmlFor={id}
        className={`styled_checkbox__label ${data !== '' && 'disabled'}`}>
        <input
          ref={ref}
          type="checkbox" 
          id={id}
          name={id} 
          required
          className="styled_checkbox__checkbox"
          onChange={handleIsChecked}
        />
        {isChecked ? <CheckboxChecked/> : <Checkbox/>}
        <span>{label}</span>
      </label>
    </div>
  )
})

export default RefCheckbox;