export function decimalAdjust(value: number, exp: number) {
  if (typeof exp === 'undefined' || +exp === 0) {
    return Math.ceil(value);
  }
  if (isNaN(value) || !(typeof exp === 'number' && exp % 1 === 0)) {
    return NaN;
  }
  let val = value.toString().split('e');
  let v = Math.ceil(+(val[0] + 'e' + (val[1] ? (+val[1] - exp) : -exp)));
  let num = v.toString().split('e');
  
  return +(num[0] + 'e' + (num[1] ? (+num[1] + exp) : exp));
}

export function getRoundedValue(number: number){
  return decimalAdjust(number, -2);
}