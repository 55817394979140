import { useWeb3React } from '@web3-react/core';
import Button from "../../../components/Button/Button";
import { WEI } from '../../../data/constants';
import { showToastSmthWrong } from '../../../data/toasts';
import { auctionContractAddress } from "../../../network/networkConfig";
import { auctionContract } from "../../../network/service/web3";
import { selectUserAddress } from "../../../store/features/userData/userDataSlice";
import { useAppSelector } from "../../../store/hooks";

interface Props {
  tokenId: string | number;
  startPrice: number;
  setIsSchedulingInProgress: React.Dispatch<React.SetStateAction<boolean>>;
}

function ButtonPutOnSale (props: Props) {
  const { active } = useWeb3React();
  const account = useAppSelector(selectUserAddress);

  const {
    tokenId,
    startPrice,
    setIsSchedulingInProgress,
  } = props;

  const startAuction = (
    connector: any,
    from: string, 
    toContract: string, 
    data: any, 
  ) => {
    connector.request({
      method: 'eth_sendTransaction',
      params: [{
        from: from,
        to: toContract,
        data: data
      }]
    }) 
    .then((result: string) => {
      setIsSchedulingInProgress(true);
    })
    .catch((error: Error) => {
      showToastSmthWrong();
      console.log(error)
    })
  }

  const handleStartAuction = async () => {
    if (active && account && window.BinanceChain && localStorage.getItem('connectorId') === 'BSC') {
      const BinanceChain = window.BinanceChain;
      const price = Math.round(startPrice*WEI);
        startAuction(
          BinanceChain, 
          account, 
          auctionContractAddress,
          auctionContract.methods
            .bid(tokenId, price)
            .encodeABI()
        );
    } else if (active && account && window.ethereum) {
      const ethereum = window.ethereum;
      const price = Math.round(startPrice*WEI);
        startAuction(
          ethereum, 
          account, 
          auctionContractAddress,
          auctionContract.methods
            .scheduleAuction(tokenId, `${price}`)
            .encodeABI()
        );
    } else {
      console.log('Unexpected error occurred')
    }
  }
  
  return (
    <Button className="button__big modal_with_artwork__active_button"
      onClick={() => handleStartAuction()}
    >
      Put on sale
    </Button>
  )
}

export default ButtonPutOnSale;
