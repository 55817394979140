import Button from './Button';
import Close from '../Svg/icons/Close';

interface Props {
  onClick: (args?: any) => void;
}

function CloseButton({ onClick }: Props) {
  return (
    <Button className="button__close" onClick={onClick}>
      <Close/>
    </Button>
  )
};

export default CloseButton;