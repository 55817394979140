import SkeletonArtBidData from "./SkeletonArtBidData";
import SkeletonArtTitles from "./SkeletonArtTitles";

function SkeletonArtData() {
  return (
    <div className="single_art_card__data">
      <SkeletonArtTitles/>
      <SkeletonArtBidData/>
    </div>
  )
};

export default SkeletonArtData;