import { selectUserAddress } from "../../../store/features/userData/userDataSlice";
import { useAppSelector } from "../../../store/hooks";
import CurrentBid from "./CurrentBid";
import UserLastBid from "./UserLastBid";

interface SingleArtworkPresentBiddingInterface {
  minimumBid: number,
  startPrice: number,
  highestBid: number,
  highestBidder: string,
  winningPrice: number,
  isAuctionCompleted: boolean,
  isAuctionActive: boolean,
  isAuctionFailed: boolean,
  lastUserBid: number,
  isScheduled: boolean,
  bidder: string,
  endTime: number,
  timeIsUp: boolean,
  timeIsEnded: boolean,
  isProcessing: boolean,
}

function SingleArtworkPresentBidding (props: SingleArtworkPresentBiddingInterface) {
  const {
    minimumBid,
    startPrice,
    highestBid,
    highestBidder,
    winningPrice,
    isAuctionCompleted,
    isAuctionActive,
    isAuctionFailed,
    lastUserBid,
    isScheduled,
    bidder,
    endTime,
    timeIsUp,
    timeIsEnded,
    isProcessing,
  } = props;

  const account = useAppSelector(selectUserAddress);

  const minBid = startPrice > highestBid ?
      startPrice
    : timeIsUp ? 
      highestBid 
    : minimumBid;

  const timeEnded = endTime > 0 && timeIsUp && highestBid === 0;

  return(
    <div className="single_artwork__present_bidding_container">
      <UserLastBid
        bidder={bidder}
        account={account}
        highestBidder={highestBidder}
        lastUserBid={lastUserBid}
        isScheduled={isScheduled}
        isAuctionActive={isAuctionActive}
        isAuctionCompleted={isAuctionCompleted}
        isProcessing={isProcessing}
      />
      <CurrentBid
        account={account}
        highestBidder={highestBidder}
        lastUserBid={lastUserBid}
        isScheduled={isScheduled}
        isAuctionActive={isAuctionActive}
        isAuctionCompleted={isAuctionCompleted}
        isProcessing={isProcessing}
        timeIsEnded={timeIsEnded}
        timeIsUp={timeIsUp}
        timeEnded={timeEnded}
        isAuctionFailed={isAuctionFailed}
        startPrice={startPrice}
        minBid={minBid}
        highestBid={highestBid}
        winningPrice={winningPrice}
      />
    </div>
  )
}

export default SingleArtworkPresentBidding;