import { useContext, useEffect } from 'react';
import CloseButton from '../../components/Button/CloseButton';
import { ModalContext } from './ModalProvider';
import './modal.sass';
import Modal from 'react-modal';
import { useLocation } from 'react-router-dom';
import { usePreviousData } from '../../hooks/usePrevious';

if (process.env.NODE_ENV !== 'test') Modal.setAppElement('#root');

const customStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    zIndex: 1000,
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding:'0px',
    background: 'transparent',
    border: 'none',
  }
};

  function AppModal () {
  
  const { handleModalClose, modalContent, modalIsOpen, modalData} = useContext(ModalContext);
  const hideCloseButton = false;
  const location = useLocation();

  const prevLocation = usePreviousData(location.pathname);

  useEffect(() => {
    if (prevLocation !== location.pathname) {
      handleModalClose();
    }
  }, [handleModalClose, location, prevLocation])

  return (
    <Modal
      style={customStyles}
      isOpen={modalIsOpen}
      shouldCloseOnOverlayClick={true}
      onRequestClose={handleModalClose}
    >
      <div className={`modal__container ${modalData?.className}`}>
        <header className="modal__header">
          {!hideCloseButton && <CloseButton onClick={() => handleModalClose()}/>}
          <div className="modal__title">
            <h3 className="modal__heading">
              {modalData && modalData.title ? modalData.title : ''}
            </h3>
            { modalData && modalData.subTitle ? 
              <p className="modal__subtitle">
                {modalData.subTitle}
              </p> 
              : ''
            }
          </div>
        </header>
        <div className="modal__body">
          {modalContent}
        </div>
      </div>
    </Modal>
  )
};

export default AppModal;
