function SkeletonArtBidData() {
  return (
    <div className="single_art_card__bid_container">
      <p className="single_art_card__price__container skeleton">
      </p>
      <div className="place_a_bid__button skeleton"></div>
    </div>
  )
}

export default SkeletonArtBidData;