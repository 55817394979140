import './skeletonArtCard.sass';
import SkeletonArtData from './SkeletonArtData';
import SkeletonArtImage from './SkeletonArtImage';

function SkeletonArtCard() {

  return (
    <div className="single_art_card__container">
      <SkeletonArtImage/>
      <SkeletonArtData/>
    </div>
  )
}

export default SkeletonArtCard;
