import Button from "../../../components/Button/Button";
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { useAppSelector } from '../../../store/hooks';
import { selectUserAddress } from '../../../store/features/userData/userDataSlice';
import { auctionContractAddress, contractAddress } from '../../../network/networkConfig';
import { contract } from '../../../network/service/web3';
import { showToastApproveAuctionSignTransactionError } from "../../../data/toasts";

interface Props {
  isInputsFilled: boolean;
  tokenId: string | number;
  setIsApproveInProgress: React.Dispatch<React.SetStateAction<boolean>>;
  setIsApproveProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  setIsApproved: React.Dispatch<React.SetStateAction<boolean>>;
  handleStartPrice: () => void;
}

function ButtonApproveNFT({
  isInputsFilled,
  tokenId,
  setIsApproveInProgress,
  setIsApproveProcessing,
  setIsApproved,
  handleStartPrice,
}: Props) {
  const { active } = useWeb3React<Web3Provider>();
  const account = useAppSelector(selectUserAddress);

  const sendTransaction = (
    connector: any,
    from: string, 
    toContract: string, 
    data: any, 
  ) => {
    connector.request({
      method: 'eth_sendTransaction',
      params: [{
        from: from,
        to: toContract,
        data: data
      }]
    }) 
    .then((result: boolean) => {
      setIsApproveProcessing(true);
      setIsApproveInProgress(true);
      setIsApproved(true);
    })
    .catch((error: Error) => {
      showToastApproveAuctionSignTransactionError();
      console.log(error)
    })
  }

  const handleApprove = () => {
    if (active && account && window.BinanceChain && localStorage.getItem('connectorId') === 'BSC') {
      const BinanceChain = window.BinanceChain;

      sendTransaction(
        BinanceChain, 
        account, 
        contractAddress,
        contract.methods
          .approve(auctionContractAddress, tokenId)
          .encodeABI()
      );
    } else if (active && account && window.ethereum) {
      const ethereum = window.ethereum;

      sendTransaction(
        ethereum, 
        account, 
        contractAddress,
        contract.methods
          .approve(auctionContractAddress, tokenId)
          .encodeABI()
      );
    } else {
      console.log('Unexpected error occurred')
    }
  }

  
  return (
    <>
      { isInputsFilled ?
        <Button
          className="button__big"
          onClick={handleApprove}
        >Auction consent</Button>
      :
        <Button
          className="button__big not_active"
          onClick={handleStartPrice}
        >Auction consent</Button>
      }
    </>
  )
}

export default ButtonApproveNFT;
