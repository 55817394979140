import { CURRENCY } from "../../../data/constants";
import { getRoundedValue } from "../../../utils/getRoundedValue";

interface Props {
  bidder: string,
  account: string,
  highestBidder: string,
  lastUserBid: number,
  isScheduled: boolean,
  isAuctionActive: boolean,
  isAuctionCompleted: boolean,
  isProcessing: boolean,
}

function UserLastBid (props: Props) {
  const {
    bidder,
    account,
    highestBidder,
    lastUserBid,
    isScheduled,
    isAuctionActive,
    isAuctionCompleted,
    isProcessing,
  } = props

  const userBidIsProcessing = isProcessing && bidder === account;
  const userBidIsHighest = isScheduled && bidder === account &&
    bidder !== '' && !isAuctionActive && !isAuctionCompleted && 
    !isProcessing && highestBidder === account;
  const userTakePartInActiveAuction = isScheduled && isAuctionActive && 
    lastUserBid !== 0 && bidder === account &&
    highestBidder !== account && !isProcessing;
  const userTookPartInEndedAuction = isScheduled && bidder === account &&
    bidder !== '' && !isAuctionActive && 
    !isAuctionCompleted && !isProcessing;

  return (
    <>
    { userTakePartInActiveAuction ?
      <div className="single_artwork__user_bid_container">
        <p className="single_artwork__present_bidding_title">
          Your last bid
        </p>
        <div className="single_artwork__user_bid">
          <span className="user_bid">{getRoundedValue(lastUserBid)}</span> {CURRENCY}
        </div>
      </div>
    : userTookPartInEndedAuction ?
      <div className="single_artwork__user_bid_container">
        <p className="single_artwork__present_bidding_title">
          Your last bid
        </p>
        <div className="single_artwork__user_bid">
          <span className="user_bid">{getRoundedValue(lastUserBid)}</span> {CURRENCY}
        </div>
      </div>
    : userBidIsProcessing ?
      <div className="single_artwork__user_bid_container">
        <p className="single_artwork__present_bidding_title">
          Your last bid
        </p>
        <div className="single_artwork__user_bid">
          <span className="user_bid skeleton">processing</span>
        </div>
      </div>
    : userBidIsHighest ? '' 
    : ''
    }</>
  )
}

export default UserLastBid;