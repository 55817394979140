import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../store';
import { SingleArtworkAuctionsInterface, StateStatusInterface, Tokens } from '../types';
import { fetchAuctionEvents } from './fetchAuctionEvents';
import { EventData } from "web3-eth-contract";

export interface AuctionEventsInterface extends StateStatusInterface {
  artworksAuctions: Tokens,
}

const initialState: AuctionEventsInterface = {
  artworksAuctions: [],
  status: 'loaded',
};

const tokens: Tokens = new Map();

export const getArtworksAuctions = createAsyncThunk(
  'auctionEvents/getArtworksAuctions',
  async () => {
    const response = await fetchAuctionEvents('allEvents');
    
    for (let event of response) {
      let tokenId = Number(event.returnValues.artId);
      if (!tokens.has(tokenId)) {
        tokens.set(tokenId, {
          tokenId: tokenId,
          auctionsHistory: [],
        });
        tokens.get(tokenId).auctionsHistory.push(event);
      } else {
        tokens.get(tokenId).auctionsHistory.push(event);
      }
    }
    return Array.from(tokens.values());
  }
)

export const getNewAuctionEventToArtworkById = (
  tokenId: string | number,
  event: EventData,
) : AppThunk => (
  dispatch,
  getState,
) => {
  const currentAuctions = selectArtworksAuctionsByTokenId(getState(), tokenId);
  const isUniqueEvent = currentAuctions.auctionsHistory[0].signature !== event.signature && 
    currentAuctions.auctionsHistory[1]?.signature !== event.signature;

  if (isUniqueEvent || currentAuctions.auctionsHistory[0].transactionHash !== event.transactionHash) {
    dispatch(setNewBidToArtworkById({tokenId, event}))
  }
}

export const getNewArtworkAuction = (
  tokenId: string | number,
  event: EventData,
) : AppThunk => (
  dispatch,
  getState,
) => {
  const allAuctions = selectArtworksAuctions(getState());
  const hasTokenId = allAuctions.some((artwork: SingleArtworkAuctionsInterface) => 
    artwork.tokenId === +event.returnValues.artId);
  
  if (!hasTokenId) {
    let newAuction: SingleArtworkAuctionsInterface = {
      tokenId: tokenId,
      auctionsHistory: [],
    }
    newAuction.auctionsHistory.push(event);
    dispatch(setNewArtworkAuction(newAuction))
  }
}

export const auctionEventsSlice = createSlice({
  name: 'auctionEvents',
  initialState,
  reducers: {
    setNewBidToArtworkById: (
      state, 
      action: 
        PayloadAction<{
          tokenId: string | number, 
          event: EventData
        }>
    ) => {
      state.artworksAuctions
        .find((auction: SingleArtworkAuctionsInterface) => 
          auction.tokenId === action.payload.tokenId)
        .auctionsHistory.unshift(action.payload.event)
    },
    setNewArtworkAuction: (
      state, 
      action: 
        PayloadAction<SingleArtworkAuctionsInterface>
    ) => {
      state.artworksAuctions.unshift(action.payload)
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getArtworksAuctions.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getArtworksAuctions.fulfilled, (state, action) => {
        state.status = 'loaded';
        state.artworksAuctions = action.payload;
      })
      .addCase(getArtworksAuctions.rejected, (state) => {
        state.status = 'failed';
      })
  },
});

export const { setNewBidToArtworkById, setNewArtworkAuction } = auctionEventsSlice.actions;

export const selectArtworksAuctions = (state: RootState) => state.auctionEvents.artworksAuctions;
export const selectArtworksAuctionsStatus = (state: RootState) => state.auctionEvents.status;
export const selectArtworksAuctionsByTokenId = (state: RootState, 
  tokenId: string | number) => {
    const auctions = state.auctionEvents.artworksAuctions;
    
    return auctions.find((auction:any )=> auction.tokenId === tokenId
  )};

export const selectScheduledAuctions = (state: RootState) => {
  const scheduled = state.auctionEvents.artworksAuctions.filter((
    auction: SingleArtworkAuctionsInterface
  ) => 
    auction.auctionsHistory[0].event === 'AuctionScheduled'
  );
  const sheduledByPrice = scheduled.sort((
    a: SingleArtworkAuctionsInterface,
    b: SingleArtworkAuctionsInterface
  ) => Number(a.auctionsHistory[0].returnValues.startPrice) > Number(b.auctionsHistory[0].returnValues.startPrice) ? 
    -1 : 1);
  
  return sheduledByPrice
};

export const selectActiveAuctions = (state: RootState) => {
  const activeAuctions = state.auctionEvents.artworksAuctions.filter((
    auction: SingleArtworkAuctionsInterface) => {
      const ended = auction.auctionsHistory.find((event: EventData) => {
        return event.event === 'AuctionEndTimeChanged' || event.event === 'AuctionStart' 
      });

      const endTime = ended?.returnValues.endTime;
      const active = auction.auctionsHistory[0].event === 'AuctionStart' || auction.auctionsHistory[0].event === 'Bid' || auction.auctionsHistory[0].event === 'AuctionEndTimeChanged';
      
      return active && (+new Date(endTime*1000) - +new Date()) > 0
    }
  );
  const activeAuctionsByPrice = activeAuctions.sort((
    a: SingleArtworkAuctionsInterface,
    b: SingleArtworkAuctionsInterface
  ) => { 

    const isANotBid = a.auctionsHistory[0].event !== 'Bid';
    const isBNotBid = b.auctionsHistory[0].event !== 'Bid';
    const isBothNotBid = isANotBid && isBNotBid;
    const isOnlyANotBid = isANotBid && !isBNotBid;
    const isOnlyBNotBid = !isANotBid && isBNotBid;

    return !isBothNotBid &&
        Number(a.auctionsHistory[0].returnValues.minNextBid) > 
        Number(b.auctionsHistory[0].returnValues.minNextBid) ?
        -1
      : isBothNotBid &&
        Number(a.auctionsHistory[1].returnValues.minNextBid) > 
        Number(b.auctionsHistory[1].returnValues.minNextBid) ?
        -1 
      : isOnlyANotBid &&
        Number(a.auctionsHistory[1].returnValues.minNextBid) > 
        Number(b.auctionsHistory[0].returnValues.minNextBid) ?
        -1
      : isOnlyBNotBid &&
        Number(a.auctionsHistory[0].returnValues.minNextBid) > 
        Number(b.auctionsHistory[1].returnValues.minNextBid) ?
        -1
      : 1
  });

  return activeAuctionsByPrice
}

export default auctionEventsSlice.reducer;