import Button from '../../Button/Button';
import { auctionContract } from '../../../network/service/web3';
import { auctionContractAddress } from '../../../network/networkConfig';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { useAppSelector } from '../../../store/hooks';
import { selectUserAddress } from '../../../store/features/userData/userDataSlice';
import { showToastSmthWrong } from '../../../data/toasts';
import { etherscanTransactionLink } from '../../../data/constants';

declare global {
  interface Window {
    BinanceChain:any;
    ethereum: any;
  }
}

interface ButtonCompleteAuctionInterface {
  beneficiary: string,
  highestBidder: string,
  tokenId: string | number,
  setCurrentOwner:  React.Dispatch<React.SetStateAction<string>>;
  setIsAuctionCompleted: React.Dispatch<React.SetStateAction<boolean>>,
  setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>,
  setCompleteInProgress: React.Dispatch<React.SetStateAction<boolean>>,
}

function ButtonCompleteAuction (props: ButtonCompleteAuctionInterface) {
  const { active } = useWeb3React<Web3Provider>();
  const account = useAppSelector(selectUserAddress);
  const { 
    beneficiary,
    highestBidder,
    tokenId,
    setCurrentOwner,
    setIsAuctionCompleted,
    setIsProcessing,
    setCompleteInProgress,
} = props;

  const sendTicket = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>, 
    transactionHash: string
  ) => {
    e.preventDefault();
    const etherscanLink = `${etherscanTransactionLink}${transactionHash}`
    const request = { 
      request: { 
        requester: { 
          name: `${account}` 
        }, 
        subject: "Auction completed" , 
        comment: { 
          body: `Please, send the Winner his Artwork.\nHis address: ${highestBidder}\nArtwork ID: ${tokenId}\nTransaction: ${etherscanLink}` }
      } 
    };
    const url = process.env.REACT_APP_ZENDESK_LINK!;

    await fetch(url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(request)
    });
  }

  const sendCompleteAuction = (
    connector: any,
    from: string, 
    toContract: string, 
    data: any, 
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    connector.request({
      method: 'eth_sendTransaction',
      params: [{
        from: from,
        to: toContract,
        data: data
      }]
    }) 
    .then((result: string) => {
      setCurrentOwner(highestBidder)
      setIsAuctionCompleted(true)
      setIsProcessing(true)
      setCompleteInProgress(true)
      sendTicket(e, result)
    })
    .catch((error: Error) => {
      showToastSmthWrong()
      console.log(error)
    })
  }

  const handleCompleteAuction = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    if (active && account && window.BinanceChain && localStorage.getItem('connectorId') === 'BSC') {
      const BinanceChain = window.BinanceChain;

      if (account === beneficiary || account === highestBidder) {
        sendCompleteAuction(
          BinanceChain, 
          account, 
          auctionContractAddress,
          auctionContract.methods
            .completeAuction(tokenId)
            .encodeABI(),
          e,
        );
      }
    } else if (active && account && window.ethereum) {
      const ethereum = window.ethereum;
      
      if (account === beneficiary || account === highestBidder) {
        sendCompleteAuction(
          ethereum, 
          account, 
          auctionContractAddress,
          auctionContract.methods
            .completeAuction(tokenId)
            .encodeABI(),
          e,
        );
      }
    } else {
      console.log('Unexpected error occurred')
    }
  }

  return (
    <Button className="button__big"
      onClick={(e) => handleCompleteAuction(e)}
    >
      Complete Auction
    </Button>
  )
}

export default ButtonCompleteAuction;
