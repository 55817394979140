import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { StateStatusInterface } from '../types';
import { fetchNftEvents } from './fetchNftEvents';

export interface NftEventsInterface extends StateStatusInterface {
  nftEvents: any[];
}

const initialState: NftEventsInterface = {
  nftEvents: [],
  status: 'loaded',
};

export const getNftEvents = createAsyncThunk(
  'nftEvents/getNftEvents',
  async () => {
    const response = await fetchNftEvents('Transfer')
    return response
  }
)

export const nftEventsSlice = createSlice({
  name: 'nftEvents',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getNftEvents.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getNftEvents.fulfilled, (state, action) => {
        state.status = 'loaded';
        state.nftEvents = action.payload;
      });
  },
});

export const selectNftEvents = (state: RootState) => state.nftEvents.nftEvents;
export const selectNftEventsLoadingStatus = (state: RootState) => state.nftEvents.status;

export default nftEventsSlice.reducer;