import PassKYC from '../../components/PassKYC/PassKYC';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount/ScrollToTopOnMount';

function PassKycContainer() {
  return (
    <>
      <ScrollToTopOnMount/>
      <section className="container pass_kyc__container">
        <PassKYC />
      </section>
    </>
  )
}

export default PassKycContainer;
