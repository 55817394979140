import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 25" fill="none" {...props}>
      <path d="M19.5 3.3999H4.5C4.10218 3.3999 3.72064 3.55794 3.43934 3.83924C3.15804 4.12055 3 4.50208 3 4.8999V19.8999C3 20.2977 3.15804 20.6793 3.43934 20.9606C3.72064 21.2419 4.10218 21.3999 4.5 21.3999H19.5C19.8978 21.3999 20.2794 21.2419 20.5607 20.9606C20.842 20.6793 21 20.2977 21 19.8999V4.8999C21 4.50208 20.842 4.12055 20.5607 3.83924C20.2794 3.55794 19.8978 3.3999 19.5 3.3999ZM4.5 19.8999V4.8999H19.5V19.8999H4.5Z" fill="#0E1039"/>
    </Svg>
  );
};

export default Icon;
