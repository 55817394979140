import { useEffect, useState } from "react";
import './timer.sass';

interface TimerInterface {
  className?: string,
  endTime: number,
  setTimeIsEnded: React.Dispatch<React.SetStateAction<boolean>>,
}

function Timer (props: TimerInterface) {
  const {
    className,
    endTime,
    setTimeIsEnded,
  } = props;

  const calculateTimeLeft = () => {
    const difference = +new Date(endTime*1000) - +new Date();
    let timeLeft: {[key: string]: number} = {}

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24) + Math.floor(difference / (1000 * 60 * 60 * 24)) * 24,
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    }
    return timeLeft;
  };

  const calculateTimeIsUp = () => {
    const difference = +new Date(endTime*1000) - +new Date();

    if (difference > 0) {
      return false
    } else {
      return true
    }
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    let timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
      setTimeIsEnded(calculateTimeIsUp());

    }, 1000);

    if (calculateTimeIsUp() === true) {
      clearTimeout(timer)
    }
    
    return () => clearTimeout(timer);
  });

  return (
    <span className={`auction_timer ${className}`}>
      { Object.keys(timeLeft).length ? 
        <>
          {timeLeft.hours < 10 ? '0' : ''}{timeLeft.hours} <span className="timer_dots">:</span> {timeLeft.minutes < 10 ? '0' : ''}{timeLeft.minutes} <span className="timer_dots">:</span> {timeLeft.seconds <10 ? '0' : ''}{timeLeft.seconds}
        </>
        : 
          ''
      }
    </span>
  )
}

export default Timer;
