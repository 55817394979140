import { useState, useRef, useContext, ChangeEvent, useEffect } from 'react';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import './uploadArtDetails.sass';
import { ArrowBackIcon } from '../Svg';
import Button from '../Button/Button';
import { UploadContext } from './UploadProvider';
import { ipfsInfuraUploadDetails } from '../../ipsf/ipfsInfuraUpload';
import { CURRENCY } from '../../data/constants';
import { useAppSelector } from '../../store/hooks';
import { selectUserAddress } from '../../store/features/userData/userDataSlice';
import { handleInputBlur } from '../../utils/handleInputBlur';
import RefInput from '../RefInput/RefInput';
import {
  showToastEnterAllData,
  showToastEnterName,
  showToastEnterNumber,
  showToastEnterPrice,
  showToastEnterPriceName,
  showToastEnterTitle,
  showToastEnterTitleName,
  showToastEnterTitlePrice, 
  showToastErrorSignMessage,
  showToastSignature,
  showToastSmthWrong
} from '../../data/toasts';

declare global {
  interface Window {
    BinanceChain: any;
  }
}

type SignMessage = (
  account: string,
  message: string,
  provider?: any,
) => Promise<string>;

function UploadArtDetails () {
  const { library } = useWeb3React();

  const account = useAppSelector(selectUserAddress);

  const { 
    artHash, 
    docHash,
    signedMessage,
    setSignedMessage,
    detailsHash,
    setDetailsHash,
    artworkPrice,
    setArtworkPrice,
  } = useContext(UploadContext);

  const formRef = useRef<HTMLFormElement>(null);
  const inputTitleRef = useRef<HTMLInputElement>(null);
  const inputPriceRef = useRef<HTMLInputElement>(null);
  const inputArtistRef = useRef<HTMLInputElement>(null);
  const inputDescriptionRef = useRef<HTMLTextAreaElement>(null);

  const [artworkTitle, setArtworkTitle] = useState<string>('');
  const [artworkArtist, setArtworkArtist] = useState<string>('');
  const [artworkDescription, setArtworkDescription] = useState<string>('');
  const [isDataEntered, setIsDataEntered] = useState<boolean>(false);

  const history = useHistory<unknown>();

  const handleNextStep = () => {
    history.push("/upload/done")
  }

  const detailsData = JSON.stringify({
    author: `${artworkArtist}`,
    title: `${artworkTitle}`,
    description: `${artworkDescription}`,
    image: `${artHash}`,
    document: `${docHash}`,
  });

  const handleDetails = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, 
    setAttr: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    const value = e.target.value;
    if (value) {
      if (e.target.id === 'price' && isNaN(+value)) {
        e.target.style.borderColor = "#EB4141";
        showToastEnterNumber();
      } else if (value.trim().length === 0) {
        e.target.style.borderColor = "#EB4141";
        showToastSmthWrong('This field should not be empty');
      } else if (!(/^[a-zA-Z0-9\u00C0-\u017F()*_\-!?@|<>;#$%^&*,.{}"/\\'\s]+$/).test(value)) {
        showToastSmthWrong('Please, use latin keyboard layout');
      } else {
        setAttr(value);
        e.target.style.borderColor = '#706FF6';
        e.target.style.backgroundColor = '#fff';
      }
    } else {
      setAttr('');
      e.target.style.borderColor = '';
      e.target.style.backgroundColor = '';
    }
  }

  const handleSetSignature = (signature: string) => {
    setSignedMessage(signature);
    showToastSignature(signature)
    history.push("/upload/done");
  }

  const handleErrorToast = (error: Error) => {
    console.log(error)
    showToastErrorSignMessage();
  }

  const binanceSignMessage: SignMessage = (
    account: string,
    message: string,
    BinanceChain: any,
  ) => {
    return BinanceChain.bnbSign(account, message)
      .then((result: { signature: string; }) => {
        handleSetSignature(result.signature);
      })
      .catch((error: Error) => {
        handleErrorToast(error);
      })
  }

  const metaMaskSignMessage: SignMessage = (
    account: string,
    message: string,
  ) => {
    return library
      .getSigner(account)
      .signMessage(message)
      .then((signature: string) => {
        handleSetSignature(signature);
      })
      .catch((error: Error) => {
        handleErrorToast(error);
      })
  }

  const uploadDetails = (e: Event) => {
    e.preventDefault();

    if (inputTitleRef.current?.value === '' && 
        inputPriceRef.current?.value === '' &&
        inputArtistRef.current?.value === ''
    ) {
      inputTitleRef.current.style.borderColor = "#EB4141";
      inputPriceRef.current.style.borderColor = "#EB4141";
      inputArtistRef.current.style.borderColor = "#EB4141";
      showToastEnterAllData();
    } else if (
      inputTitleRef.current?.value === '' && 
      inputPriceRef.current?.value === ''
    ) {
      inputTitleRef.current.style.borderColor = "#EB4141";
      inputPriceRef.current.style.borderColor = "#EB4141";
      showToastEnterTitlePrice();
    } else if (
      inputTitleRef.current?.value === '' && 
      inputArtistRef.current?.value === ''
    ) {
      inputTitleRef.current.style.borderColor = "#EB4141";
      inputArtistRef.current.style.borderColor = "#EB4141";
      showToastEnterTitleName();
    } else if (
      inputPriceRef.current?.value === '' && 
      inputArtistRef.current?.value === ''
    ) {
      inputPriceRef.current.style.borderColor = "#EB4141";
      inputArtistRef.current.style.borderColor = "#EB4141";
      showToastEnterPriceName();
    } else if (inputTitleRef.current?.value === '') {
      inputTitleRef.current.style.borderColor = "#EB4141";
      showToastEnterTitle();
    } else if (inputArtistRef.current?.value === '') {
      inputArtistRef.current.style.borderColor = "#EB4141";
      showToastEnterName();
    } else if (inputPriceRef.current?.value === '') {
      inputPriceRef.current.style.borderColor = "#EB4141";
      showToastEnterPrice();
    } else {
      ipfsInfuraUploadDetails(detailsData)
        .then((result) => {
          setDetailsHash(result.path);
          if (window.BinanceChain && localStorage.getItem('connectorId') === 'BSC') {
            const BinanceChain = window.BinanceChain;
            binanceSignMessage(account, `${artHash}\n${docHash}\n${result.path}`, BinanceChain);
          } else {
            metaMaskSignMessage(account, `${artHash}\n${docHash}\n${result.path}`);
          }
        })
    }
  }

  const signMessage = () => {
    if (window.BinanceChain && localStorage.getItem('connectorId') === 'BSC') {
      const BinanceChain = window.BinanceChain;
      binanceSignMessage(account, `${artHash}\n${docHash}\n${detailsHash}`, BinanceChain)
    } else {
      metaMaskSignMessage(account, `${artHash}\n${docHash}\n${detailsHash}`);
    }
  }

  useEffect(() => {
    if (artworkTitle.length > 0 && artworkPrice.length > 0 && artworkArtist.length > 0) {
      setIsDataEntered(true);
    } else {
      setIsDataEntered(false);
    }
  }, [artworkArtist, artworkPrice, artworkTitle])
  
  useEffect(() => {
    const textarea = document.querySelector('#description') as HTMLTextAreaElement;
    if (detailsHash && inputTitleRef.current && inputPriceRef.current && inputArtistRef.current && textarea) {
      inputTitleRef.current.disabled = true;
      inputPriceRef.current.disabled = true;
      inputArtistRef.current.disabled = true;
      textarea.disabled = true;
    }
  }, [detailsHash])

  return (
    <>
      { !artHash && 
        <Redirect from='/upload/step_2' to='/upload/step_1'/>
      } 
      <div className="upload__back_to_account">
        <Link to="/upload/step_1"><ArrowBackIcon /> Back to step 1</Link>
      </div>
      <h3 className="h3_title upload_section__title">Upload your item</h3>
      <div className="upload_section__steps">Step 2 of 2</div>
      <section className="container artwork_upload__details_container">
        <form 
          className="artwork_upload__details"
          ref={formRef}
        > 
          <RefInput
            ref={inputTitleRef}
            type="text"  
            id="title" 
            placeholder="enter title here"
            isRequired
            className="details_input"
            onChange={(e) => handleDetails(e, setArtworkTitle)}
            label="Enter the title of the artwork"
          />
          <RefInput
            ref={inputPriceRef}
            type="text"  
            id="price" 
            placeholder={`enter the minimum price in ${CURRENCY} here`}
            isRequired
            className="details_input"
            onChange={(e) => handleDetails(e, setArtworkPrice)}
            label="Minimum price"
          />
          <RefInput
            ref={inputArtistRef}
            type="text" 
            id="artist" 
            placeholder="enter artist name here"
            isRequired
            className="details_input"
            onChange={(e) => handleDetails(e, setArtworkArtist)}
            label="Artist"
          />
          <div className="details__item">
            <textarea 
              name="description" 
              id="description" 
              maxLength={250}
              placeholder="enter a description of the painting if you wish here less than 250 symbols"
              className="artwork_upload__details_holder details_text"
              onChange={(e) => handleDetails(e, setArtworkDescription)}
              ref={inputDescriptionRef}
              onBlur={(e) => handleInputBlur(e)}
            />
            <label 
              htmlFor="description"
            >
              Enter a description of the artwork if you want
            </label>
          </div>
        </form>
      </section>

      <section className="container artwork_upload__container">
        <div className="artwork_upload__details_buttons">
          { !detailsHash ?
              <Button 
                className={`button__big ${isDataEntered ? 'active' : 'not_active'}`}
                onClick = {isDataEntered ? (e) => uploadDetails(e) : undefined} 
              >
                Sign confirmation
              </Button>
          : !signedMessage ?
            <Button
              className="button__big"
              onClick={signMessage}
            >
              Sign confirmation
            </Button>
            :
            <Button 
              className="button__big"
              onClick={() => handleNextStep()}
            >
                Send to moderation
            </Button>
          }
        </div>
      </section>
    </>
  )
};

export default UploadArtDetails;
