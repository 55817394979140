import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState, AppThunk } from '../../store';
import { Artwork } from '../../../network/types';
import { SingleArtworkAuctionsInterface, StateStatusInterface } from '../types';
import { selectAllArtworks, selectArtworksLoadingStatus } from "../allArtworks/allArtworksSlice"
import { EventData } from "web3-eth-contract";

export interface UserDataInterface extends StateStatusInterface {
  userAddress: string,
  userArtworks: Artwork[],
  isCreator: boolean,
  isTrader: boolean,
  referrer: string,
  publicName: string,
  userBids: EventData[],
}

const initialState: UserDataInterface = {
  userAddress: '',
  userArtworks: [],
  isCreator: false,
  isTrader: false,
  status: 'loaded',
  referrer: '',
  publicName: '',
  userBids: [],
};

export const getUserArtworks = (account: string): AppThunk => (
  dispatch,
  getState
) => {

  const isArtworksLoaded = selectArtworksLoadingStatus(getState())
  const artworks = selectAllArtworks(getState());

  if (isArtworksLoaded && artworks.length > 0) {
    const userArtworks = artworks.filter((artwork: any) => {
      return artwork.owner === account
    })
    dispatch(setUserArtworks(userArtworks))
  }
}

export const getUserIsCreator = (value: boolean): AppThunk => (
  dispatch,
) => {
  dispatch(setUserIsCreator(value))
}

export const getUserIsTrader = (value: boolean): AppThunk => (
  dispatch,
) => {
  dispatch(setUserIsTrader(value))
}

export const getUserReferrer = (value: string): AppThunk => (
  dispatch,
) => {
  dispatch(setUserReferrer(value))
}

export const getUserPublicName = (value: string): AppThunk => (
  dispatch,
) => {
  dispatch(setUserPublicName(value))
}

export const getUserBids = (account: string): AppThunk => (
  dispatch,
  getState,
) => {
  const userBids = selectUserBids(getState(), account);
  dispatch(setUserBids(userBids));
}

export const getUserBid = (data: EventData, account: string): AppThunk => (
  dispatch,
  getState,
) => {
  const userBids = selectUserBids(getState(), account);
  const hasBidded = userBids.find((bid: EventData) => +bid.returnValues.artId === +data.returnValues.artId);
  if (hasBidded !== undefined && +hasBidded.returnValues.artId === +data.returnValues.artId) {
    const newUserBids = userBids.filter((bid: EventData) => +bid.returnValues.artId !== +data.returnValues.artId);
    dispatch(deleteUserBid(newUserBids));
  } 
  dispatch(setUserBid(data))
}

export const getUserBidDeletion = (bidId: number, account: string) : AppThunk => (
  dispatch,
  getState,
) => {
  const userBids = selectUserBids(getState(), account);
  const newBids = userBids.filter((bid: EventData) => +bid.returnValues.artId !== bidId)
  dispatch(deleteUserBid(newBids))
}

export const clearProfile = (): AppThunk => (
  dispatch,
) => {
  dispatch(setUserAddress(''));
  dispatch(setUserArtworks([]));
  dispatch(setUserIsCreator(false));
  dispatch(setUserIsTrader(false));
  dispatch(setUserReferrer(''));
  dispatch(setUserPublicName(''));
  dispatch(setUserBids([]));
}

export const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setUserAddress: (state, action: PayloadAction<string>) => {
      state.userAddress = action.payload;
    },
    setUserArtworks: (state, action: PayloadAction<Artwork[]>) => {
      state.userArtworks = action.payload;
    },
    setUserIsCreator: (state, action: PayloadAction<boolean>) => {
      state.isCreator = action.payload;
    },
    setUserIsTrader: (state, action: PayloadAction<boolean>) => {
      state.isTrader = action.payload;
    },
    setUserReferrer: (state, action: PayloadAction<string>) => {
      state.referrer = action.payload;
    },
    setUserPublicName: (state, action: PayloadAction<string>) => {
      state.publicName = action.payload;
    },
    setUserBids: (state, action: PayloadAction<EventData[]>) => {
      state.userBids = action.payload;
    },
    setUserBid: (state, action: PayloadAction<EventData>) => {
      state.userBids.push(action.payload);
    },
    deleteUserBid: (state, action: PayloadAction<EventData[]>) => { 
      state.userBids = action.payload
    },
  },
});

export const selectUserAddress = (state: RootState) => state.userData.userAddress;
export const selectUserArtworks = (state: RootState) => state.userData.userArtworks;
export const selectUserIsCreator = (state: RootState) => state.userData.isCreator;
export const selectUserIsTrader = (state: RootState) => state.userData.isTrader;
export const selectUserReferrer = (state: RootState) => state.userData.referrer;
export const selectUserPublicName = (state: RootState) => state.userData.publicName;
export const selectAllUserBids = (state: RootState) => state.userData.userBids;
export const selectUserBids = (
  state: RootState,
  account: string
) => {
  const auctions = state.auctionEvents.artworksAuctions;

  const allUserAuctions= auctions.filter((auction: SingleArtworkAuctionsInterface) => 
    auction.auctionsHistory.some((event: EventData) =>
      event.returnValues.bidder === account || event.returnValues.winner === account
    )
  );

  const userAuctions = allUserAuctions.filter((userAuction: SingleArtworkAuctionsInterface) => {
    const userBidIndex = userAuction.auctionsHistory.findIndex((event: EventData) => 
      event.returnValues.bidder === account || event.returnValues.winner === account
    );
    const startedAuctionIndex = userAuction.auctionsHistory.findIndex((event: EventData) => 
      event.event === 'AuctionScheduled'
    );
    const successAuctionIndex = userAuction.auctionsHistory.findIndex((event: EventData) => 
      event.event === 'AuctionComplete'
    );

    return userBidIndex < startedAuctionIndex &&
      successAuctionIndex !== 0 &&
      userAuction.auctionsHistory[userBidIndex].event !== 'AuctionComplete'
  })

  const userBids = userAuctions.map((userAuction: SingleArtworkAuctionsInterface) => 
    userAuction.auctionsHistory.find((event: EventData) =>
      event.returnValues.bidder === account || event.returnValues.winner === account
    )
  );

  return userBids
}

export const {
  setUserAddress,
  setUserArtworks,
  setUserIsCreator,
  setUserIsTrader,
  setUserReferrer,
  setUserPublicName,
  setUserBids,
  setUserBid,
  deleteUserBid,
} = userDataSlice.actions;

export default userDataSlice.reducer;