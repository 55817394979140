import { Link } from 'react-router-dom';
import Button from './Button';

function ShowGalleryButton () {
  return (
    <Link to="items">
      <Button className="button__big">Show Gallery</Button>
    </Link>
  )
}

export default ShowGalleryButton;