import { useEffect, useRef } from "react";
import { EventData } from "web3-eth-contract";

export const usePreviousData = (data: string | number) => {
  const ref = useRef<string | number>();
  useEffect(() => {
    ref.current = data;
  });
  return ref.current;
}

export const usePreviousEventData = (event: EventData | undefined) => {
  const ref = useRef<EventData>();
  useEffect(() => {
    ref.current = event;
  });
  return ref.current;
}

export const useHasEventChanged = (event: EventData | undefined) => {
  const prevEvent = usePreviousEventData(event);
  return prevEvent !== event
}
