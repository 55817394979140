import Metamask from "./icons/Metamask";
import BinanceChain from "./icons/Binance";
import { Config, ConnectorNames } from "./types";
import { ModalData } from "../Modal/types";
import { Link } from 'react-router-dom';
import { walletModalLinkLearnWallets } from "../modalTexts";

const connectors: Config[] = [
  {
    title: "Metamask",
    icon: Metamask,
    connectorId: ConnectorNames.Injected,
  },{
    title: "Binance Chain Wallet",
    icon: BinanceChain,
    connectorId: ConnectorNames.BSC,
  },
];

export const WalletModalData: ModalData = {
  title: 'Connect',
  subTitle: <>You need a wallet to use our platform <br/>New to Ethereum? <Link to={walletModalLinkLearnWallets}
    className="wallet__link">
      Learn more about wallets
    </Link>
  </>,
  className: "modal__wallet",
}

export default connectors;
export const connectorLocalStorageKey = "connectorId";