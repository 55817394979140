import { Redirect } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount/ScrollToTopOnMount';
import GetInvite from '../../components/GetInvite/GetInvite';

function GetInviteContainer() {
  const { active } = useWeb3React<Web3Provider>();

  return (
    <>
      <ScrollToTopOnMount/>
      <section className="container get_invite__container">
        {!active && <Redirect from="/invite" to="/"/>}
        <GetInvite />
      </section>
    </>
  )
}

export default GetInviteContainer;