import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="39" height="49" viewBox="0 0 39 49" fill="none"{...props}>
      <path d="M1.16391 6.16634V42.833C1.16391 44.0486 1.6468 45.2144 2.50634 46.0739C3.36588 46.9335 4.53167 47.4163 5.74724 47.4163H33.2472C34.4628 47.4163 35.6286 46.9335 36.4881 46.0739C37.3477 45.2144 37.8306 44.0486 37.8306 42.833V16.1168C37.8305 15.5062 37.7084 14.9017 37.4715 14.339C37.2346 13.7762 36.8876 13.2665 36.451 12.8397L26.276 2.88926C25.4197 2.05202 24.2698 1.58316 23.0722 1.58301H5.74724C4.53167 1.58301 3.36588 2.06589 2.50634 2.92544C1.6468 3.78498 1.16391 4.95077 1.16391 6.16634V6.16634Z" stroke="#0E1039" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.625 26.791H26.375" stroke="#0E1039" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M12.625 35.958H19.5" stroke="#0E1039" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24.0778 1.58301V10.7497C24.0778 11.9653 24.5607 13.131 25.4202 13.9906C26.2798 14.8501 27.4456 15.333 28.6612 15.333H37.8278" stroke="#0E1039" strokeWidth="2" strokeLinejoin="round"/>
    </Svg>
  );
};

export default Icon;
