import { useEffect, useState } from "react";
import './letterPage.sass';
import { handleCopyText } from "../../utils/handleCopyText";
import { handleLetterTextChange } from "../../utils/handleLetterTextChange";
import { addLineBreak, handleLineBreakTagged } from "../../utils/handleLineBreak";
import Button from "../Button/Button";
import CopyButton from "../Button/CopyButton";

interface PageText {
  header: string;
  description: JSX.Element;
  letter: string;
  moderationInfo: string;
}

interface MessageData {
    subject: string;
    body: {
      heading: string;
      message: string;
    };
}

interface Props {
  pageText: PageText;
  setLetterHeading: React.Dispatch<React.SetStateAction<string>>;
  messageData: MessageData;
  letterHeading: string;
  contactEmail: string;
}

function LetterPage({
  pageText,
  setLetterHeading,
  messageData,
  letterHeading,
  contactEmail,
}: Props) {

  const [message, setMessage] = useState(`${messageData.body.heading}${messageData.body.message}`);

  useEffect(() => {
    if (letterHeading) {
      setMessage(`${letterHeading}${messageData.body.message}`)
    }

  }, [letterHeading, messageData?.body.message])

  return (
    <>
      <h3 className="h3_title pass_kyc__title">{pageText.header}</h3>
      <div className="letter__description">
        {pageText.description}
      </div>
      <div className="letter__message_text_container">
        <header className="letter_container__header pass_kyc__message_text__header">
          <div
            className="letter_container__header_label message_text__label"
            onClick={() => handleCopyText(message)}
          >
            Copy this text and send us by e-mail
          </div>
          <CopyButton message={message}/>
        </header>
        <div className="message_text__area">
          <textarea
            autoFocus
            className="message_text__header"
            defaultValue={addLineBreak(letterHeading)}
            onChange={(e) => handleLetterTextChange({e, setLetterHeading})}
          >
          </textarea>
          <p className="message_text__important">
            {handleLineBreakTagged(messageData.body.message)}
          </p>  
        </div>
        <div className="letter__moderation_info">
          {pageText.moderationInfo}
        </div>
      </div>
      <a 
        className="button__send_letter"
        href={`mailto:${contactEmail}?subject=${messageData.subject}&body=${message}`}
        target="_blank"
        rel="noreferrer"
      >
        <Button 
          className="button__big"
        >
          Send letter
        </Button>
      </a>
    </>
  )
}

export default LetterPage;
