import { useContext } from 'react';
import Button from '../../../components/Button/Button';
import { ModalContext } from '../../Modal/ModalProvider';
import { erc20Contract } from '../../../network/service/web3';
import { auctionContractAddress, currencyContract } from '../../../network/networkConfig';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { WalletModalData } from '../../WalletModal/config';
import WalletModal from '../../WalletModal/WalletModal';
import { CURRENCY, WEI } from '../../../data/constants';
import { useAppSelector } from '../../../store/hooks';
import { selectUserAddress } from '../../../store/features/userData/userDataSlice';
import { showToastApproveUSDTError } from '../../../data/toasts';
import { constants } from 'ethers';

declare global {
  interface Window {
    BinanceChain:any;
    ethereum: any;
  }
}

interface ButtonApprovePropsInterface {
  setAllowance: React.Dispatch<React.SetStateAction<number>>,
  usdBalance: number,
  processing: boolean,
  className: string,
  setApproveInProcess: React.Dispatch<React.SetStateAction<boolean>>,
  setApproveTransactionHash: React.Dispatch<React.SetStateAction<string>>,
}

function ButtonApprove (props: ButtonApprovePropsInterface) {
  const {
    setAllowance,
    processing,
    className,
    setApproveInProcess,
    setApproveTransactionHash,
  } = props;
  const { active } = useWeb3React<Web3Provider>();
  const account = useAppSelector(selectUserAddress);
  const { handleModalOpen } = useContext(ModalContext);

  const sendTransaction = (
    connector:any,
    from: string, 
    toContract: string, 
    data: any, 
  ) => {
    connector.request({
      method: 'eth_sendTransaction',
      params: [{
        from: from,
        to: toContract,
        data: data
      }]
    }) 
    .then((result: string) => {
      setApproveTransactionHash(result)
      setApproveInProcess(true)
      erc20Contract.methods.allowance(account, auctionContractAddress).call()
        .then((result: number) => {
          setAllowance(result/WEI);
        })
    })
    .catch((error: Error) => {
      showToastApproveUSDTError();
      console.log(error)
    })
  }

  const handleApprove = () => {
    if (!active) {
      handleModalOpen(<WalletModal />, WalletModalData)
    }

    if (active && account && window.BinanceChain && localStorage.getItem('connectorId') === 'BSC') {
      const BinanceChain = window.BinanceChain;

      sendTransaction(
        BinanceChain, 
        account, 
        currencyContract,
        erc20Contract.methods
          .approve(auctionContractAddress, constants.MaxUint256.toString())
          .encodeABI()
      );
    } else if (active && account && window.ethereum) {
      const ethereum = window.ethereum;

      sendTransaction(
        ethereum, 
        account, 
        currencyContract,
        erc20Contract.methods
          .approve(auctionContractAddress, constants.MaxUint256.toString())
          .encodeABI()
      );
    } else {
      console.log('Unexpected error occurred')
    }
  }

  return (
    <Button className={`button__big inactive ${className}`}
      onClick={() => handleApprove()}
    >
      {processing ? 'Processing, wait please': `First: Approve ${CURRENCY} contract`}
    </Button>
  )
}

export default ButtonApprove;
