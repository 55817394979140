import AuctionItem from './AuctionItem/AuctionItem';
import { EventData } from "web3-eth-contract";

interface SingleArtworkAuctionInterface {
  tokenId: string | number,
  auctionsHistory: EventData[],
  beneficiary: string,
  endTime: number,
  timeIsUp: boolean,
  artworkTitle: string,
  isProcessing: boolean,
}

function SingleArtworkAuction (props: SingleArtworkAuctionInterface) {
  const {
    auctionsHistory,
    beneficiary,
    endTime,
    timeIsUp,
    artworkTitle,
    isProcessing,
  } = props;

  const auctionsFiltered = auctionsHistory?.filter((auction: EventData) => 
    auction.event !== 'AuctionStart' && 
    auction.event !=='AuctionEndTimeChanged'
  )

  return (
    <>
      { isProcessing && 
        <AuctionItem
          beneficiary={beneficiary}
          isProcessing={isProcessing}
        />
      }
      { timeIsUp &&
        auctionsFiltered[0]?.event === 'AuctionScheduled' ? 
          <AuctionItem
            beneficiary={beneficiary}
            isAuctionFailed={true}
            endTime={endTime}
          />
        : timeIsUp &&
          auctionsFiltered[0]?.event === 'Bid' &&
          <AuctionItem
            beneficiary={beneficiary}
            isAuctionSuccessed={true}
            endTime={endTime}
            artworkTitle={artworkTitle}
            data={auctionsFiltered[0]}
          />
      }
      { auctionsFiltered?.map((auctionEvent: EventData, index: number) => {
        return  <AuctionItem
          auctionEvent={auctionEvent}
          beneficiary={beneficiary}
          artworkTitle={artworkTitle}
          key={`${auctionEvent.event}_${index}`}
        />
      })}
    </>
  )
}

export default SingleArtworkAuction;