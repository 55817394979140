import Button from "../../../components/Button/Button";
import ButtonPutOnSale from "../ButtonPutOnSale/ButtonPutOnSale";
import TextInProgress from "../TextInProgress/TextInProgress";

interface Props {
  auctionCanNotBeStarted: boolean;
  auctionNeedsApproval: boolean;
  auctionStartPriceNotSetted: boolean;
  auctionCanBeStarted: boolean;
  isApproveInProgress: boolean;
  isSchedulingInProgress: boolean;
  tokenId: string | number;
  startPrice: number;
  handleIsApproved: () => void;
  handleStartPrice: () => void;
  setIsSchedulingInProgress: React.Dispatch<React.SetStateAction<boolean>>;
}

function ButtonPutOnSaleContiner({
  auctionCanNotBeStarted,
  auctionNeedsApproval,
  auctionStartPriceNotSetted,
  auctionCanBeStarted,
  isApproveInProgress,
  isSchedulingInProgress,
  tokenId,
  startPrice,
  handleIsApproved,
  handleStartPrice,
  setIsSchedulingInProgress
}: Props) {
  return (
    <>
      {
        auctionCanNotBeStarted || auctionNeedsApproval || isApproveInProgress ?
          <Button 
            className={`button__big not_active`}
            onClick={handleIsApproved}
          >
            Put on sale
          </Button>
        : auctionStartPriceNotSetted ?
          <Button 
            className={`button__big not_active modal_with_artwork__active_button`}
            onClick={handleStartPrice}
          >
            Put on sale
          </Button>
        : isSchedulingInProgress ?
          <TextInProgress/>
        : auctionCanBeStarted && 
          !isApproveInProgress &&
          <ButtonPutOnSale
            tokenId={tokenId}
            startPrice={startPrice}
            setIsSchedulingInProgress={setIsSchedulingInProgress}
          />
      }
    </>
  )
}

export default ButtonPutOnSaleContiner;