import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import './modalWithArtwork.sass';
import './putOnSaleModal.sass';
import Tooltip from '../../components/Tooltip/Tooltip';
import {
  selectUserAddress,
} from '../../store/features/userData/userDataSlice';
import { useAppSelector } from '../../store/hooks';
import { ModalContext } from '../Modal/ModalProvider';
import RefInput from '../../components/RefInput/RefInput';
import SingleArtCard from '../../components/SingleArtCard/SingleArtCard';
import { Link } from 'react-router-dom';
import { putOnSaleLinkHow, putOnSaleTooltipInput } from '../modalTexts';
import { EventData } from "web3-eth-contract";
import { TransactionReceipt } from 'web3-core';
import { auctionContract, contract, web3 } from '../../network/service/web3';
import { CURRENCY } from '../../data/constants';
import { auctionContractAddress } from '../../network/networkConfig';
import ButtonPutOnSaleContiner from './ButtonPutOnSaleContiner/ButtonPutOnSaleContiner';
import ButtonApproveNftContaner from './ButtonApproveNftContaner/ButtonApproveNftContaner';
import {
  showToastApproveAuction,
  showToastApproveAuctionError,
  showToastAuctionScheduled,
  showToastCustomTextError,
  showToastEnterNumber,
  showToastStartPrice
} from '../../data/toasts';

interface Props {
  tokenId: string | number;
  owner: string;
  setIsApproveProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  setIsScheduled: React.Dispatch<React.SetStateAction<boolean>>;
  setIsNftApproved: React.Dispatch<React.SetStateAction<boolean>>;
  isNftApproved: boolean;
}

function PutOnSaleModal({
  tokenId,
  owner,
  setIsApproveProcessing,
  setIsScheduled,
  setIsNftApproved,
  isNftApproved,
}: Props) {
  const [isInputsFilled, setInputsFilled] = useState(false);
  const [isApproveInProgress, setIsApproveInProgress] = useState(false);
  const [isApproved, setIsApproved] = useState(false);
  const [startPrice, setStartPrice] = useState(0);
  const [isSchedulingInProgress, setIsSchedulingInProgress] = useState(false);
  const account = useAppSelector(selectUserAddress);
  const modalRef = useRef<HTMLDivElement>(null);
  const priceInput = useRef<HTMLInputElement>(null);

  const { handleModalClose } = useContext(ModalContext);

  const isAuctionStartApproved = isNftApproved || isApproved;
  const auctionCanNotBeStarted = !isAuctionStartApproved && startPrice === 0;
  const auctionNeedsApproval = !isAuctionStartApproved && startPrice !== 0;
  const auctionStartPriceNotSetted = isAuctionStartApproved && startPrice === 0;
  const auctionCanBeStarted = isAuctionStartApproved && startPrice !== 0;

  const handleInputValue = (
    e: ChangeEvent<HTMLInputElement>,
    ref?: React.RefObject<HTMLInputElement>,
  ) => {
    const value = e.target.value;
    if (isNaN(+value)) {
      e.target.style.borderColor = "#EB4141";
      showToastEnterNumber();
    } else {
      e.target.style.borderColor = '#706FF6';
      e.target.style.backgroundColor = '#fff';
      setStartPrice(+value)
    }
  }

  const handleStartPrice = () => {
    showToastStartPrice();
  }

  const handleIsApproved = () => {
    showToastApproveAuctionError();
  }

  useEffect(() => {
    if (startPrice !== 0) {
      setInputsFilled(true);
    } else {
      setInputsFilled(false);
    }
  }, [startPrice])

  useEffect(() => {
    function handleClickOutside(event: any) {
      const modal = event.target.classList.contains('ReactModal__Content');
      if (modal) {
        handleModalClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef, handleModalClose])

  useEffect(() => {
    if (priceInput.current && isApproveInProgress) {
        priceInput.current.disabled = true;
        priceInput.current.style.borderColor = '#E7E8FF';
        priceInput.current.style.backgroundColor = '#F6F6F6';
      } else if (priceInput.current && !isApproveInProgress) {
        priceInput.current.disabled = false;
        priceInput.current.style.borderColor = '';
        priceInput.current.style.backgroundColor = '';
    }

  }, [isApproveInProgress])

  useEffect(() => {
    if (isApproveInProgress) {
      contract.events.Approval({
        filter: {tokenId: tokenId},
      }, (error: Error, result: EventData) => {
        if (!error) {
          web3.eth.getTransactionReceipt(result.transactionHash)
            .then((receipt: TransactionReceipt) => {
              if (!!receipt && result.returnValues.owner === account && !!receipt.status) {
                showToastApproveAuction();
                setIsNftApproved(true);
              } else if (!!receipt && result.returnValues.owner === account && !!!receipt.status) {
                showToastCustomTextError('An error occurred while approving')
              }
            })
            .catch(console.log)
            setIsApproveInProgress(false);
        }
      })
    }
  }, [account, isApproveInProgress, setIsNftApproved, tokenId])

  useEffect(() => {
    if (isNftApproved) {
      setIsApproved(true);
    }
  }, [isNftApproved])

  useEffect(() => {
    if (owner !== undefined) {
      contract.methods.getApproved(tokenId).call()
        .then((result: string) => {
          if (result === auctionContractAddress && owner === account) {
            setIsNftApproved(true)
          }
        })
    }
  }, [isNftApproved, account, owner, tokenId, setIsNftApproved])

  const [inProgress, setInProgress] = useState(false)

  useEffect(() => {
    setInProgress(true);
    if (isSchedulingInProgress && inProgress) {
      auctionContract.events.AuctionScheduled({
        filter: {artId: tokenId},
      }, (error: Error, result: EventData) => {
        if (!error) {
          web3.eth.getTransactionReceipt(result.transactionHash)
            .then((receipt: TransactionReceipt) => {
              if (!!receipt && result.returnValues.beneficiary === account && !!receipt.status) {
                showToastAuctionScheduled();
                setIsScheduled(true);
              } else if (!!receipt && result.returnValues.beneficiary === account && !!!receipt.status) {
                showToastCustomTextError('An error occurred while auction scheduling')
              }
            })
            .catch(console.log)
            setIsApproveInProgress(false);
            setIsSchedulingInProgress(false);
        }
        handleModalClose();
      })
    }

    return () => setInProgress(false);
  }, [account, handleModalClose, inProgress, isSchedulingInProgress, setIsScheduled, tokenId])

  return (
    <div
      ref={modalRef}
      className="modal_with_artwork__container"
    > 
      <div className="modal_with_artwork__artwork_card">
        {<SingleArtCard tokenId={tokenId} isLoaded={true} isBidModal={true}/>}
      </div>
      <div className="modal_with_artwork__data_container">
        <header className="modal__header">
          <div className="modal__title">
            <h3 className="modal__heading">
              Put on sale
            </h3> 
          </div>
        </header>
        <div className="modal_with_artwork__input_container modal_with_artwork__item_with_tooltip">
          <RefInput
            ref={priceInput}
            type="number" 
            id="start_price"
            placeholder={`0 ${CURRENCY}`}
            isRequired={true}
            className="start_price__input"
            onChange={handleInputValue}
            label="Enter auction start price here"
          />
          <Tooltip
            innerText={putOnSaleTooltipInput}
            className="modal_with_artwork__tooltip"
          />
        </div>
        <div className="modal_with_artwork__buttons_container">
          <ButtonApproveNftContaner
            isApproved={isApproved}
            isApproveInProgress={isApproveInProgress}
            isInputsFilled={isInputsFilled}
            tokenId={tokenId}
            setIsApproveInProgress={setIsApproveInProgress}
            setIsApproveProcessing={setIsApproveProcessing}
            setIsApproved={setIsApproved}
            handleStartPrice={handleStartPrice}
          />
          <ButtonPutOnSaleContiner
              auctionCanNotBeStarted={auctionCanNotBeStarted}
              auctionNeedsApproval={auctionNeedsApproval}
              auctionStartPriceNotSetted={auctionStartPriceNotSetted}
              auctionCanBeStarted={auctionCanBeStarted}
              isApproveInProgress={isApproveInProgress}
              tokenId={tokenId}
              startPrice={startPrice}
              handleIsApproved={handleIsApproved}
              handleStartPrice={handleStartPrice}
              setIsSchedulingInProgress={setIsSchedulingInProgress}
              isSchedulingInProgress={isSchedulingInProgress}
          />
        </div>
        <div className="modal_with_artwork___text_with_link">
          Didn't sell before?
          <Link to={putOnSaleLinkHow}> Read how it works</Link>
        </div>
      </div>
    </div>
  )
};

export default PutOnSaleModal;
