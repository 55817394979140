import { TooltipIcon } from "../Svg";
import './tooltip.sass'

interface Props {
  innerText: string;
  className?: string;
}

function Tooltip ({
  innerText,
  className
}: Props) {
  return (
    <div className={`tooltip ${className}`}>
      <TooltipIcon width="20px"/>
      <p className={`tooltip_text ${className}`}>
        {innerText}
      </p>
    </div>
  )
}

export default Tooltip;