import { useCallback } from 'react'
import { useWeb3React, UnsupportedChainIdError } from '@web3-react/core'
import { NoBscProviderError, 
  UserRejectedRequestError as UserRejectedRequestErrorBsc } from '@binance-chain/bsc-connector';
import {
  NoEthereumProviderError,
  UserRejectedRequestError as UserRejectedRequestErrorInjected,
} from '@web3-react/injected-connector';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ConnectorNames } from '../widgets/WalletModal/types';
import { connectorLocalStorageKey } from '../widgets/WalletModal/config'; 
import { connectorsByName } from '../utils/web3React';
import { clearProfile } from '../store/features/userData/userDataSlice';
import { useAppDispatch } from '../store/hooks';
import {
  showToastCheckConnection,
  showToastNoAuthorisation,
  showToastNoBrowserExtentionBSC,
  showToastNoBrowserExtentionMetaMask,
  showToastWrongNetwork
} from '../data/toasts';

export const getErrorMessage = (error: Error) => {
  if (error instanceof NoEthereumProviderError || error instanceof NoBscProviderError) {
    toast.error("No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.");
    return 'No Ethereum browser extension detected, install MetaMask on desktop or visit from a dApp browser on mobile.'
  } else if (error instanceof UnsupportedChainIdError) {
    toast.error("You're connected to an unsupported network.")
    return "You're connected to an unsupported network."
  } else if (
    error instanceof UserRejectedRequestErrorInjected
  ) {
    toast.error("Please authorize this website to access your Ethereum account.")
    return 'Please authorize this website to access your Ethereum account.'
  } else {
    console.error(error)
    toast.error('An unknown error occurred. Check the console for more details.')
    toast.error(error.message)
    return 'An unknown error occurred. Check the console for more details.'
  }
}

const useAuth = () => {
  const { activate, deactivate } = useWeb3React();
  const dispatch = useAppDispatch();
  const login = useCallback((connectorID: ConnectorNames) => {
    const connector = connectorsByName[connectorID];
    const connectorId = window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames;
    if (connector) {
      if (connectorID === connectorId) {
        activate(connector)
      } else {
        activate(connector, async (error: Error) => {
          if (error instanceof UnsupportedChainIdError) {
            showToastWrongNetwork();
            window.localStorage.removeItem(connectorLocalStorageKey);
          } else {
            window.localStorage.removeItem(connectorLocalStorageKey)
            if (error instanceof NoEthereumProviderError) {
              showToastNoBrowserExtentionMetaMask();
            } else if (error instanceof NoBscProviderError){
              showToastNoBrowserExtentionBSC();
            } else if (
              error instanceof UserRejectedRequestErrorInjected || error instanceof UserRejectedRequestErrorBsc
            ) {
              showToastNoAuthorisation();
            } else {
              showToastCheckConnection(error);
            }
          }
        })
        .then(() => {
          const isWindowsOS = window.navigator.userAgent.indexOf('Windows');
          if (isWindowsOS !== -1) {
            const timeout = setTimeout(() => window.location.reload(), 2000)
            return () => clearTimeout(timeout);
          }
        })
      }
    } else {
      toast.error('An unexpected error has occurred.', {
        position: "bottom-right",
        autoClose: 3000,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const logout = useCallback(() => {
    deactivate();
    dispatch(clearProfile());
    window.localStorage.removeItem(connectorLocalStorageKey);
  }, [deactivate, dispatch])

  return { login, logout }
}

export default useAuth;
