import ScrollToTopOnMount from '../../components/ScrollToTopOnMount/ScrollToTopOnMount';
import Faq from '../../components/FAQ/Faq';

function FaqContainer() {

  return (
    <>
      <ScrollToTopOnMount/>
      <section className="container faq__container">
        <Faq />
      </section>
    </>
  )
}

export default FaqContainer;