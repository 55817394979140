import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import './infoItem.sass';
import { getTimeFromBlock } from "../../../../utils/getTimeFromBlock";
import { auctionContractAddress } from '../../../../network/networkConfig';
import { EventData } from "web3-eth-contract";
import LinkToTransaction from '../../LinkToTransaction/LinkToTransaction';

interface InfoItemInterface {
  info: EventData | undefined,
  status: string,
  previousOwner?: EventData,
}

function InfoItem (props: InfoItemInterface) {
  const {
    info,
    status,
    previousOwner,
  } = props;
  const [time, setTime] = useState<Date>();
  const [address, setAddress] = useState('');
  const [transactionHash, setTransactionHash] = useState('');

  useEffect(() => {
    if (info !== undefined) {
      if (status === 'mintedBy') {
        getTimeFromBlock(info.blockNumber, setTime);
        setTransactionHash(info.transactionHash);
        setAddress(info.returnValues.beneficiary);
      } else if (info.returnValues.to === undefined) {
        getTimeFromBlock(info.blockNumber, setTime);
        setAddress(info.returnValues.beneficiary);
        setTransactionHash(info.transactionHash);
      } else if (info.returnValues.to !== auctionContractAddress) {
        getTimeFromBlock(info.blockNumber, setTime);
        setAddress(info.returnValues.to);
        setTransactionHash(info.transactionHash);
      } else if (previousOwner) {
        getTimeFromBlock(previousOwner.blockNumber, setTime);
        setAddress(previousOwner.returnValues.to);
        setTransactionHash(previousOwner.transactionHash);
      } else {
        return
      }
    }
  }, [info, previousOwner, status])

  return (
    <div className="single_artwork__info_tab_item">
      { info && 
        <>
          <p className="single_artwork__info_tab_time">
            { time ?
              <LinkToTransaction
                time={time}
                transactionHash={transactionHash}
              />
            : 
              <span className="skeleton"></span>
            }
          </p>
          <p className="single_artwork__info_tab_address">
            { status === 'mintedBy' ? 
                <>
                  Minted by <Link
                    className="info_tab_item__link"
                    to={`/users/${address}`}>
                    {address.substring(0,5)}...{address.substring(address.length - 3)}
                  </Link>
                </>
              :
                <>
                  <Link 
                    className="info_tab_item__link"
                    to={`/users/${address}`}
                  >
                    {address.substring(0,5)}...{address.substring(address.length - 3)}
                  </Link> now owns the artwork
                </>
            }
          </p>
        </>
      }
    </div>
  )
}

export default InfoItem;