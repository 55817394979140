import { Link } from "react-router-dom";

const contactEmail = 'support@greatmasters.com';

export const termsText = [{
  id: `preamble`,
  section: `Preamble`,
  description: [
    <>Your use of the products, software, services, Website, the technology and the platform integrated therein and any applications associated therewith (referred to collectively as the “GreatMasters Platform”, <b>“we”</b>, <b>“us”</b>, or <b>“Services”</b>), under this document (<b>“Terms”</b>) and excluding any services provided to you by the Company under a separate written agreement) is subject to the terms of a legal agreement between you and the Company. The <b>“Company”</b> means <b>Starbit Ventures Limited</b>,2053650 whose principal place of business is at <b>Craigmuir Chambers, PO Box 71, Road Town, Tortola VG1110, British Virgin Islands</b>. This document explains how the agreement is made up and sets out some of the terms of that agreement.</>,
    <><b>“User”</b>, <b>“you”</b> is a natural person and/or a legal entity that has completed a registration process and created an Account.
    </>,
    <><b>“Website”</b> is <Link to='/' className="legal__link">greatmasters.com</Link></>,
    <><b>“Partner Services”</b>, <b>“Wallet”</b> is a supported extension such as electronic/digital wallets, which allows you to purchase, store, and engage in transactions using Ethereum (ETH) cryptocurrency.</>,
    <>The GreatMasters Platform is a website, functioning as a marketplace for users to create, buy, transfer and trade unique digital assets in the form of non-fungible tokens (<b>“NFT”</b>) with the help of Partner Services. NFT is a blockchain-based commodity with unique identification data regarding the right to artwork, artwork itself, its author, title and edition series information if applicable.</>,
    <>You can use the GreatMasters Platform to create, buy, and sell NFTs.</>,
    <>When you create an asset, you get the status of a creator of such items (<b>“Creator”</b>). Once an NFT is created, it is owned by the user that created it, namely by its Creator. Creators may sell and resell NFTs, including their own.
    </>,
    <>If you register your Account to buy or sell assets, you get the status of a trader (<b>“Trader”</b>). A Trader may apply to become a Creator. 
    </>,
    <>If you do not register an Account on the Website, you can review its content with the status of an unregistered user to the extent available to unregistered users (<>“Unregistered user”</>). </>,
    <>THE GREATMASTERS PLATFORM IS AN INTERMEDIARY BETWEEN CREATORS AND TRADERS, WHOSE RELATIONSHIPS ARE FULLY GOVERNED BY SEPARATE AGREEMENTS OF WHICH THE COMPANY IS NOT A PARTY OF ANY TERMS OF NFT SALE TRANSACTIONS ARE DETERMINED BY THESE SEPARATE AGREEMENTS, INCLUDING, BUT NOT LIMITED TO, NFT’S PRICE, TERMS OF PAYMENT, TRANSFER PROCESS. THE GREAT MASTERS PLATFORM ONLY PROVIDES TECHNICAL NFT ISSUANCE AND AUCTION SET-UP SERVICES. ALSO, THE CREATOR AND THE TRADER ARE SOLELY RESPONSIBLE FOR PAYMENT OF ANY TAXES THAT COULD BE INCURRED BY THEM DUE TO AN NFT AUCTION SALE.</>,
    <>These Terms describe legal conditions, concerning your use of the Website, GreatMasters Platform, and Services. All policies posted on the Website are incorporated into these Terms.</>,
    <>You must agree to all of the conditions of this Agreement. If you do not agree to or do not accept any of the conditions of this Agreement, please immediately discontinue access to and use of the Website, the GreatMasters Platform and the Services.</>,
    <>If you are under the age of eighteen (18) or the legal age for entering into legally binding contracts under applicable laws, you are not permitted to use this Website, the GreatMasters Platform and other Services at all.</>,
  ],
},{
  id: `generalProvisions`,
  section: `General provisions`,
  subSections: [{
    id: 'legalEfficacy',
    cl: 1,
    section: 'Legal efficacy of this aggreement',
    description: [
      <>THIS AGREEMENT IS A LEGAL CONTRACT BETWEEN YOU AND THE COMPANY. YOU SHOULD TREAT IT AS ANY OTHER LEGAL CONTRACT BY READING ITS PROVISIONS CAREFULLY, AS THEY WILL AFFECT YOUR LEGAL RIGHTS. BY ACCESSING THE WEBSITE OR USING THE SERVICES IN ANY MANNER, YOU ARE DEEMED TO HAVE READ, UNDERSTOOD AND AGREED TO BE BOUND BY ALL OF THE TERMS CONTAINED IN THIS AGREEMENT. YOU MAY NOT CHOOSE WHICH TERMS APPLY TO YOU. IF YOU DO NOT AGREE WITH ANY OF THE TERMS IN THIS AGREEMENT, YOU MUST CEASE ALL ACCESS AND USE OF THE WEBSITE AND ANY OTHER SERVICES PROVIDED BY THE COMPANY. NOTHING IN THIS AGREEMENT IS INTENDED TO CREATE ANY ENFORCEMENT RIGHTS BY THIRD PARTIES.</>,
    ]
  },{
    id: 'rightToAdvice',
    cl: 2,
    section: 'Right to professional advice',
    description: [
      <>Given that the blockchain-based commodity market is highly volatile and unpredictable, accepting these Terms you represent and warrant that you understand that there are risks associated with the creating such commodities, sale, purchase and any other transactions. If you do not understand all of the terms in this Agreement, you should seek advice from an independent financial advisor, legal counsel, or a similar professional before using the Website, GreatMasters Platform or the Services. We encourage you to review the Terms frequently to ensure that you understand the terms that apply when you access or use the Service. If you do not agree to the revised Terms, you may not access or use the Service.</>
    ]
  },{
    id: 'acceptanceOfTheTerms',
    cl: 3,
    section: 'Acceptance of the Terms',
    description: [
      <>No one is authorized or allowed to access the Website or use the GreatMasters Platform unless he or she has accepted this Agreement. You manifest your agreement to this Agreement by taking any act demonstrating you enter into this Agreement.</>,
      <>Most likely, you have clicked or will click a button containing the words “I agree” or some similar syntax. If you click any link, button or other device provided to you in any part of our Website’s interface, then you have legally agreed to all of these Terms. Additionally, by using any part of our Website or Services in any manner you understand and agree that such use constitutes your affirmation of your complete and unconditional acceptance to all of the terms in this Agreement.
      </>,
      <>You acknowledge and accept that if you are not of legal age, do not possess the authority to act on behalf of a natural or legal person or otherwise cannot enter into a binding legal agreement under your applicable law, you may not access or use or purchase our Services.</>,
      <>We reserve the right to terminate your access to our Services at any time and without a warning if we have a reason to believe that you are not of required legal age, do not have the authority to act on behalf of another natural or legal person or are otherwise ineligible to enter into a binding agreement with us by accepting these Terms, and you have failed to provide us with adequate confirmation upon our request in reasonable time.
      </>,
    ]
  }]
},{
  id: 'registrationProcessAndRestricionOfUse',
  section: 'Registration process and restricion of use',
  subSections: [{
    id: 'registrationOfAnAccountWithCompany',
    cl: 4,
    section: 'Registration of an account with company and creation of assets ',
    description: [
      <>In order to use the GreatMasters Platform, you must create an account with us (your <b>“Account”</b>). Account can be created on the Website by going through the registration process, which is described in the respective section of the Website. <b>“Account”</b> refers herein to User account accessible after the registration process and via the Services where assets (as defined below) may be operated by Company on behalf of a User via auction (<b>“Auction”</b>), enabled by smart contract on the Ethereum blockchain that provides an immutable ledger of all transactions that occur on the GreatMaster Platform (<b>“Smart Contracts”</b>). This means that all NFT and other digital assets such as cryptocurrency  is outside of the control of any one party, including the GreatMasters Platform, and are subject to many risks and uncertainties.  We neither own nor control MetaMask, the Ethereum network, your browser, or any other third party site, product, or service that you might access, visit, or use for the purpose of enabling you to use the various features of the Platform. We will not be liable for the acts or omissions of any such third parties, nor will we be liable for any damage that you may suffer as a result of your transactions or any other interaction with any such third parties. You understand and acknowledge that your Ethereum public address will be made publicly visible whenever you engage in a transaction on the Platform. </>,
      <>While creating or using your Account, you may be asked to provide certain registration details and information. In order to verify your identity and complete the AML/KYC procedure, some of this information may be personal, private or detailed.
      </>,
      <>While creating your Account, you may be asked to download a supported extension (<b>Partner Services</b>), connect and unlock your Wallet with that extension. You may only participate in the <b>Auction</b> by linking your digital wallets on supported extensions.</>,
      <><b>“Wallet”</b> refers herein to User electronic wallet accessible after the registration process and linking extension and via the Partner Services where assets may be stored and operated by Partner Services on behalf of the User.</>,
      <><b>“Assets”</b> shall mean such type of assets which can only and exclusively be transmitted by means of blockchain technology.</>,
      <>Company reserves the right, in its sole discretion, to limit the number of Accounts that you may hold, maintain or create. Assigning account to any third party may be realized only via confirmed agreement with the Company and after submitting all the necessary legitimate documents, determined by the Company.</>,
      <>When you create an Account, you undertake to:
        <ul className="terms_paragraf__list">
          <li>provide accurate and truthful information. Please check our <Link to="/policy" className="legal__link">Privacy Policy</Link> for the information on how we collect, use and share your personal information. While we protect all personal information of others from inadvertent release or misappropriation, we are not responsible for the intentional or criminal acts of third parties such as hackers or “phishers”;</li>
          <li>agree to pass through AML/KYC procedures, which may be applied to you;</li>
          <li>maintain and promptly update your Account information;</li>
          <li>maintain the security of your Account choosing strong password, storing in securely and restricting access to your Account;</li>
          <li>promptly notify us if you discover or otherwise suspect any security breaches related to your Account;</li>
          <li>take responsibility for all activities that occur under your Account and accept all risks of any authorized or unauthorized access to your Account, to the maximum extent permitted by law.</li>
        </ul>
      </>,
      <>To create an Asset, you need to connect your Wallet to your Account registered on the GreatMasters Platform, get the status of a Creator, upload the artwork to your Account, indicate the title, name of the author, minimum price, confirm your ownership to the artwork, enter the description of the artwork if you want and sign the Asset.</>,
    ],
  }, {
    id: 'warrantiesRepresentationsCovenants',
    cl: 5,
    section: 'Warranties, representations and covenants',
    description: [
      <>
        You further represent and warrant that:
        <ul className="terms_paragraf__list">
          <li>you are at least eighteen (18) years old or of other legal age, according to your relevant jurisdiction;</li>
          <li>you have not been previously suspended or removed from our Services;</li>
          <li>you have full power and authority to enter into this legal relationship according to the relevant jurisdiction and by doing so will not violate any other legal relationships;</li>
          <li>you use GreatMasters Platform with your own e-mail and for your own benefit and do not act on behalf and/or to the interest of any other person;</li>
          <li>you are not a Politically Exposed Person (PEP) or their family member or close associate of the PEP. PEPs (as well as family members or close associates of the PEPs) are not allowed to use the Company's GreatMasters Platform. In case the Company will establish, that the User of the GreatMasters Platform is PEP (as well as family member or close associate of the PEP), Company reserves the right to terminate the Account of such User. For the purpose of this Terms, the definitions “Politically Exposed Person”, “family member” and “close associate” have the meaning, as is defined in (i) the FATF Recommendations (International Standards on Combating Money Laundering and the Financing of Terrorism and Proliferation) – available via <a className="legal__link" href="http://www.fatf-gafi.org/media/fatf/documents/recommendations/pdfs/FATF%20Recommendations%202012.pdf" target="_blank" rel="noreferrer">FATF Recommendations 2012</a> and (ii) FATF Guidance on Politically Exposed Persons (Recommendations 12 and 22) – available via <a className="legal__link" href="http://www.fatf-gafi.org/media/fatf/documents/recommendations/Guidance-PEP-Rec12-22.pdf" target="_blank" rel="noreferrer">Guidance-PEP-Rec12-22</a>;</li>
          <li>you guarantee that you have full and eligible rights to your Assets and that those are not sold, encumbered, not in contention, or under seizure, and that neither exist any rights of third parties to your Assets;</li>
          <li>you will not use our Services or will immediately cease using those if any applicable law in your country prohibits or will prohibit you at any time from doing so;</li>
          <li>you will not use our Services or will immediately cease using those if you are a resident or become a resident at any time of the state or region (in accordance with such state or region definition of residency), where the transactions you are going to execute are prohibited or require special approval, permit and/or authorization of any kind, which Company has not obtained in this state or region. Notwithstanding the above, you are not in any case allowed to access and use our Services if you are located, incorporated or otherwise established, or are citizen or resident of:
            <ul className="terms_paragraf__list">
              <li>Crimea and Sevastopol</li>
              <li>Yemen</li>
              <li>South Sudan</li>
              <li>North Korea</li>
              <li>Iran</li>
              <li>any such territory where the local Applicable Law prohibits or will prohibit you at any time (by reason of your nationality, domicile, citizenship, residence or otherwise) to access or use the Services or the GreatMasters Platform (hereinafter, together <b>“The Restricted Jurisdictions”</b>)</li>
            </ul>
          </li>
        </ul>
      </>,
      <>Company reserves the right to terminate any of your Accounts, and to suspend you from any Auction activity in case if: (i) Company determines that you are accessing the Services or the GreatMasters Platform from any Restricted Jurisdiction, or (ii) you have given false representations as to your location of incorporation, establishment, citizenship, or place of residence. For the purpose of this clause “Applicable Law” refers to all applicable laws of any governmental authority, including, without limitation, federal, state and foreign securities laws, tax laws, tariff and trade laws, ordinances, judgments, decrees, injunctions, writs and orders or like actions of any governmental authority and rules, regulations, orders, interpretations, licenses, and permits of any federal, regional, state, county, municipal or other governmental authority</>,
    ],
  },{
    id: 'restrictedUses',
    cl: 6,
    section: 'Restricted uses',
    description: [
      <>When accessing or using the GreatMasters Platform, you further represent, agree and warrant, that you will not violate any law, contract, intellectual property or other third-party right or commit a tort by using GreatMasters Platform. Without prejudice to the generality of the foregoing, you represent, agree and warrant, that you will not:
        <ul className="terms_paragraf__list">
          <li>Use the GreatMasters Platform in any manner that could interfere with, disrupt, negatively affect, damage or inhibit other users from using our GreatMasters Platform with full functionality, or that could negatively affect the functioning of GreatMasters Platform in any manner;</li>
          <li>Use the GreatMasters Platform to pay for, support or otherwise engage in any illegal activities;</li>
          <li>Use any robot, spider, crawler, scraper or other automated means or interface not provided by us to access the GreatMasters Platform or to extract data;</li>
          <li>Use or attempt to use another User account without authorization or any service or area of our GreatMasters Platform that you are not authorized to access;</li>
          <li>Use any meta-tags, pay-per-click advertising, or any other “hidden text” using our Website’s name or marks, and you hereby stipulate that any use of the Website’s name or marks, or any other marks owned by us is an infringement upon our trademark rights, in which case you agree to pay any and all fees incurred in the recovery of our intellectual property, including attorney's fees and all associated costs;</li>
          <li>Upload or attempt to upload files that contain viruses, Trojan horses, worms, time bombs, cancelbots, corrupted files, or any other similar software or programs that may affect the operation of the GreatMasters Platform, the GreatMasters Platform's property or property of others;</li>
          <li>Develop any third-party applications that interact with the GreatMasters Platform in any manner without our prior written consent;</li>
          <li>Encourage or induce any third party to engage in any of the activities prohibited under this Section.</li>
        </ul>
      </>,
      <>YOU INDEMNIFY AND HOLD COMPANY HARMLESS AGAINST ANY CLAIMS, DEMANDS AND DAMAGES, WHETHER DIRECT, INDIRECT, CONSEQUENTIAL OR SPECIAL, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, AND COSTS REASONABLY AND NECESSARILY INCURRED BY THE GREATMASTERS PLATFORM ORIGINATED FROM OR IN ANY WAY CONNECTED WITH INVALIDITY OR BREACH OF ANY OF THE WARRANTIES, REPRESENTATIONS AND COVENANTS OF THIS SECTION AND THE ENTIRE TERMS OR BREACH OF ANY OF YOUR OBLIGATIONS HEREUNDER.</>,
    ],
  }],
},{
  id: 'possibilitiesAndServicesOfTheGreatmastersPlatform',
  section: 'Possibilities and services of the Greatmasters platform',
  subSections: [{
    id: 'assetsDepositsAndWithdrawals',
    cl: 7,
    section: 'Assets deposits and withdrawals',
    description: [
      <>The GreatMasters Platform allows users to remit various Assets via external third-party services (<b>“Partner Service”</b>) except to certain limitations, which may be updated from time to time. YOU INDEMNIFY AND HOLD COMPANY HARMLESS AGAINST ANY CLAIMS, DEMANDS AND DAMAGES, WHETHER DIRECT, INDIRECT, CONSEQUENTIAL OR SPECIAL, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, ORIGINATED FROM OR IN ANY WAY CONNECTED WITH YOUR DEPOSIT/WITHDRAWAL TRANSFER REQUESTED IN VIOLATION OF SETTLED LIMITATIONS.</>,
      <>You understand and acknowledge, that an addresses for remittance the Assets would be linked to your account and associated with you as soon as you connect the extension (<b>Partner Service</b>) and any Assets can be remitted to and from your Wallet and you fully and irrevocably authorize any Transfers via the GreatMasters Platform and Partner Services, which are executed by Partner Services and the Auction to make it operational.</>,
      <>You understand and acknowledge that the GreatMasters Platform does not interfere with your Assets directly and indemnify and hold the Company harmless against any claims, demands and damages regarding the remittance of your Assets.</>,
      <>You understand and acknowledge that the GreatMasters Platform offers a marketplace for NFT assets (digital files), it does not buy, sell, or ever take custody or possession of any NFTs. The Platform facilitates user collection of  NFTs, but the GreatMasters are custodians of any  NFTs. You understand and acknowledge that theAuction technology is realised via smart contracts and does not give the GreatMasters custody, possession, or control of any NFTs. or other digital assets such as cryptocurrency at any time for the purpose of facilitating transactions on the Platform.  You understand and acknowledge that the GreatMasters is a non-custodial service provider and has designed this Platform to be directly accessible by the Users without any involvement or actions taken by the GreatMasters.  As a marketplace, the GreatMasters cannot make any representation or guarantee that Creators will achieve any particular outcome as the result of listing their NFT on the GreatMasters platform.</>,
      <><b>“Transfer”</b> for the purposes herein shall mean a record of Auction Bid (including final Auction Bid) transaction of asset into, out from or at User’s connected Wallet, which is technically executed by Partner Services and the Auction in accordance with User’s Auction Bid order.</>,
      <><b>“Auction Bid”</b> shall be understood as a proposition of a purchase of the commodity asset (digital NFT file), owned by one Account User by the digital asset of another type, owned by another Account User, at the terms and conditions set forth by such transaction parties, and which is executed solely and exclusively within the GreatMasters Platform via Auction with Assets deposited to those users’ linked Wallets.</>,
      <>You are solely responsible for your use of the Partner Services, that you use to remit your Assets to or from the GreatMasters Platform, including for any fees that may be attributed thereto. Partner Services is any platform or network in which Assets belong to you or where you are the beneficial owner of Assets; and the GreatMasters Platform is maintained by a third party outside of the Services; including, but not limited to third-party accounts. If we place a hyperlink to a Partner Services website or application on our Platform, we will not warn you that you have left our Website and are subject to the Terms and conditions (including privacy policies) of another website or destination when you click on a link to a Partner Services website or application. The Partner Services website or application is not under the control of the Company, and the Company is not responsible for the actions and the content of any Partner Services website or application. You use all links to Partner Services website or application and any other third-party websites applications at your own risk.</>,
      <>YOU AGREE THAT YOU WILL NOT HOLD COMPANY LIABLE FOR ANY CLAIMS, DEMANDS AND DAMAGES, WHETHER DIRECT, INDIRECT, CONSEQUENTIAL OR SPECIAL, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, ORIGINATED FROM OR IN ANY WAY CONNECTED WITH YOUR USE OF SUCH THIRD-PARTY SERVICES.</>,
      <>Subject to the terms and conditions of these Terms, we will use commercially reasonable efforts to record all Transfers on a spot basis as soon as practicable. However the timing associated with remittance of the Assets depends inter alia upon the performance of Partner Services, and We make no guarantee that the Assets will be Deposited/Withdrawn in any specific timeframe.</>,
      <>YOU HEREBY UNDERSTAND AND ACKNOWLEDGE, THAT ANY DELAY DURING THE DEPOSIT, WITHDRAWAL, TRANSFER AND OTHER OPERATIONS ARE POSSIBLE; YOU INDEMNIFY AND HOLD COMPANY HARMLESS AGAINST ANY CLAIMS, DEMANDS AND DAMAGES, WHETHER DIRECT, INDIRECT, CONSEQUENTIAL OR SPECIAL, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE TRANSFER DELAY, WHETHER ORIGINATED FROM OUR FAULT OR NOT.</>,
    ],
  },{
    id: 'assetsAuction',
    cl: 8,
    section: 'Assets auction',
    description: [
      <>At the GreatMasters Platform Traders and Creators may place Auction Bids for selling or purchasing of certain digital assets via the Auction Technology. Auction (as a smart-contract) is created at the pre arranged with the Creator moment. Nevertheless, please be advised that the Auction starts when a First Auction Bid is made, in correspondence with a starting price, after that other Auction Bids may be placed until time limit. Each next Auction Bid should possess greater value than the previous one, but not less than 10%. Each Auction is limited at time and finishes with a final Auction Bid once the time runs out. In case of cancellation of the Auction, a Creator may redeem issued NFT by compensation of costs, determined by the Company and agreed with a Creator. The GreatMasters Platform will notify respective users that the Auction has been executed. YOU SHOULD ONLY PLACE AN AUCTION BID OR OFFER TO BUY OR SELL IF YOU FULLY INTEND TO COMPLETE THE TRANSACTION AND UNDERSTAND THE TERMS AND CONSEQUENCES OF THE TRANSACTION. You agree that you will not have the possibility to stop payment of a preauthorized Auction Bid in case your Auction Bid will be the last one. We are not responsible for any disputes among or between users regarding any transaction.</>,
      <>When you submit a new Auction bid via the GreatMasters Platform, you authorize the Company to:
        <ul className="terms_paragraf__list">
          <li>record a transfer of your Assets from/into/on your Wallet via Partner Services and/or</li>
          <li>where applicable, reserve your Assets on your Wallet in accordance with such Auction Bid</li>
          <li>charge you any applicable fees for such records (as described in these Terms).</li>
        </ul>
      </>,
      <>You acknowledge and agree that, respective to your trading activity, the GreatMasters Platform:
        <ul className="terms_paragraf__list">
          <li>is not acting as your A BROKER, FINANCIAL INSTITUTION, OR CREDITOR. THE SERVICES ARE AN ADMINISTRATIVE PLATFORM ONLY. THE GREATMASTERS PLATFORM FACILITATES TRANSACTIONS BETWEEN THE BUYER AND SELLER IN THE AUCTION BUT IS NOT A PARTY TO ANY AGREEMENT BETWEEN THE BUYER AND SELLER OF ASSETS OR BETWEEN ANY USERS.</li>
          <li>YOU BEAR FULL RESPONSIBILITY FOR VERIFYING THE IDENTITY, LEGITIMACY, AND AUTHENTICITY OF ASSETS YOU PURCHASE ON THE GREATMASTERS PLATFORM. NOTWITHSTANDING INDICATORS AND MESSAGES THAT SUGGEST VERIFICATION, THE GREATMASTERS PLATFORM MAKES NO CLAIMS ABOUT THE IDENTITY, LEGITIMACY, OR AUTHENTICITY OF ASSETS ON THE PLATFORM.</li>
          <li>is not acting as a party of transferring of a particular Asset.</li>
        </ul>
      </>,
      <>You understand and agree that, due to technical and other restrictions, the Auction Bids displayed on our Website may be delayed and therefore may not reflect the current, live Auction Bids of particular Assets. Particularly during periods of high volume, fast movement or volatility in the marketplace for any Assets, the actual last Auction Bid at which an Auction is executed may be different from the prevailing Auction Bid indicated via the GreatMasters Platform at the time of your Auction bid. You agree that we are not responsible for any adverse consequences that you may experience, or costs that you may incur, arising from any lapse, failure, outage, or error in receiving live Auction Bids data from the Company.</>,
      <>The Auction Bids made available via the GreatMasters Platform shall not be considered as an investment or financial pieces of advice or referred to as such and cannot be used as a basis of investment strategy, legal position in the court and nothing in the rates information can be ensured to contain no errors, mistakes, misrepresentations or failures etc. THEREFORE, NEITHER COMPANY, NOR, WHERE APPLICABLE, ANY OF THEIR RESPECTIVE DIRECTORS, OFFICERS, EMPLOYEES, AGENTS OR OUTSOURCED ADVISORS MAKES ANY REPRESENTATION, WARRANTY OR GUARANTEE WHATSOEVER WITH RESPECT TO THE BIDS INFORMATION AND, IN PARTICULAR, AS TO THE ACCURACY OR COMPLETENESS OF THE BIDS INFORMATION. YOU AGREE TO INDEMNIFY AND HOLD COMPANY HARMLESS AGAINST ANY CLAIMS, DEMANDS AND DAMAGES, WHETHER DIRECT, INDIRECT, CONSEQUENTIAL OR SPECIAL, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER IN AN ACTION, IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, ORIGINATED FROM OR IN ANY WAY CONNECTED WITH YOUR USE OF BIDS INFORMATION AND/OR YOUR TRADING ACTIVITY VIA THE GREATMASTERS PLATFORM.</>,
      <>You hereby agree that you will not redistribute, retransmit, duplicate, publish or otherwise make bids available in any way, either through automated, manual, or any other means, for the purpose of generating revenue, either directly or indirectly. Any distribution, publication, or transmission of our live bids without Company's consent is a material breach of this Agreement and a violation of our rights for which we may seek appropriate legal recourse.</>,
      <><b>“Transaction”</b> refers herein to Transfer, Auction Bid, Deposit and Withdrawal of the Assets as described in these Terms of Service.</>,
    ],
  },{
    id: 'referralPrograms',
    cl: 9,
    section: 'Referral programs',
    description: [
      <>The GreatMasters Platform reserves the right to launch or suspend different Referral Programs or adjust the rules thereof at its sole discretion at any time without giving additional notifications to you. The GreatMasters Platform reserves the right to reject you in participation in any Referral Program without further explanation.</>,
      <>We also may at any time suspend or terminate your participation in any Referral Program and cancel all your referrals and referral commissions in case of your non-compliance or violation of this Terms or the terms of the Referral Program, including without limitation performance of the following activities:
        <ul className="terms_paragraf__list">
          <li>Self-invitations, including invitations sent to your other emails;</li>
          <li>Redirection from any third-party websites or social media accounts that imitate GreatMasters Platform or illegally pretend to be associated with Us;</li>
          <li>Redirection from any unofficial third-party websites or social media accounts;</li>
          <li>Posting referral link as advertisement on search engines.</li>
        </ul>
      </>,
    ],
  },{
    id: 'companysFees',
    cl: 10,
    section: 'Company\'s fees',
    description: [
      <>In order to provide the Website, GreatMasters Platform, and other Services to you, we charge a fee on each Auction initiated by users of the Website. The fee may be found in an agreement with the Auction initiator. You acknowledge that the Company’s fees are collected automatically immediately after the completion of each Auction. We reserve the right to change, modify or increase the Fee at any time and from time to time. Any such changes, modifications or increments will be effective upon conclusion of a contract regarding such changes, modifications or increments with the Auction initiator. If you do not agree to the posted changes, modifications, or increments, you may delete your Account. </>,
    ],
  },{
    id: 'copyrightsAndOtherRights',
    cl: 11,
    section: 'Copyrights and other intellectual property rights',
    description: [
      <>Unless otherwise indicated by us, the Company's intellectual property is the proprietary property of the Company or our licensors or suppliers and is protected by international copyright laws and other intellectual property rights laws. <b>“Company's intellectual property”</b> shall mean all and any copyright and other intellectual property rights in all content and other materials contained on the GreatMasters Platform or provided in connection with the Services, including, without limitation, the Company's name, trademark, Company's logo and all designs, text, graphics, pictures, information, data, software, technologies, know-hows, sound and video files, other files and the selection and arrangement thereof.</>,
      <>We hereby grant you a limited, nonexclusive and non-sublicensable license to access and use the Company's intellectual property for your personal or business use solely for the purposes of regular use of the GreatMasters Platform.</>,
      <>Such license is subject to these Terms and does not permit:
        <ul className="terms_paragraf__list">
          <li>any resale of the Company's intellectual property;</li>
          <li>the distribution, public performance or public display of any Company's intellectual property;</li>
          <li>modifying, adapting or otherwise making any derivative uses of the Company's intellectual property, or any portion thereof; or</li>
          <li>any use of the Company's intellectual property other than for the intended purposes.</li>
        </ul>
      </>,
      <>The license granted under this section will be automatically terminated in case your access to the Services is suspended or terminated.</>,
      <>As the Creator, you own all legal rights, title, and interest in all intellectual property rights to your artwork, including but not limited to copyrights, and enjoy several exclusive rights to the artwork, including but not limited to the right to reproduce, the right to prepare derivative works, the right to distribute, and the right to display or perform the artwork. When you transfer an Asset to another user, you transfer ownership of the specific digital copy of the artwork, but not the intellectual rights to the artwork. 
      </>,
      <>You understand and acknowledge that you reserve the right for the usage of various emission models on the GreatMasters Platform (starting with one NFT per piece of art). However, you agree that emission size is indicated by the Platform while the Auction.</>,
      <>You confirm that once you agree for NFT emission on the GreatMasters platform, you will not attempt to make NFT emission of the same piece of art at any other blockchain platform. You also acknowledge that further the GreatMastes platform will not create any NFT emission for that particular piece of art.</>,
      <>You understand and acknowledge that if a particular piece of art was realized as NFT at any other blockchain platform you cannot perform NFT emission on the GreatMasters platform for the same piece of art.</>,
      <>Violation of those conditions leads to possible liability for fraud.</>,
      <>To enjoy the full benefits of owning an Asset, you should make every effort to verify the original artist of the artwork. While we take all reasonable efforts to ensure that all Assets are issued by the original artist, we shall not be held liable if someone, in breach of these Terms, creates Assets which includes artworks of which they are not the original artist.</>,
    ],
  },{
    id: 'thirdPartyContent',
    cl: 12,
    section: 'Third-party content',
    description: [
      <>While using the GreatMasters Platform, you may view Third-Party Content. We do not control, endorse or adopt (unless otherwise expressly stated by Us) any Third-Party Content and shall have no responsibility for Third-Party Content, including without limitation material that may be misleading, incomplete, erroneous, offensive, indecent or otherwise objectionable. In addition, your business dealings or correspondence with such third parties are solely between you and the third parties. We are not responsible or liable for any loss or damage of any sort incurred as the result of any such dealings, and you understand that your use of Third-Party Content, and your interactions with third parties, is at your own risk. <b>“Third-Party Content”</b> is the content provided by third parties, including without limitation links to web pages of such parties, which may be represented on the GreatMasters Platform.</>,
    ],
  },{
    id: 'suspensionAndTermination',
    cl: 13,
    section: 'Suspension and termination of your account',
    description: [
      <>In case of your breach of the Terms, or any other event as we may deem necessary, including without limitation market disruption and/or Force Majeure event we may, in our sole discretion and without liability to you, with or without prior notice:
        <ul className="terms_paragraf__list">
          <li>suspend your access to all or a portion of our Services; or</li>
          <li>prevent you from executing any actions via the GreatMasters Platform, including completing any open Auction Bids;</li>
          <li>terminate your access to the Services, delete or deactivate your Account and all related information and files in such account.</li>
        </ul>
      </>,
      <>Notwithstanding to the abovementioned Company reserves the right to apply to User any of the measures indicated in cl. 13 of the Agreement, if:
        <ul className="terms_paragraf__list">
          <li>we believe, in our sole and absolute discretion, that you have breached any material term of this Agreement or the document(s) it incorporates by reference;</li>
          <li>you add an Asset to your Account which does not belong to you or received via any kind of illegal activity;</li>
          <li>we are unable to verify or authenticate any information you provide to us;</li>
          <li>we have reasonable suspicion that you are directly or indirectly using our Website, the GreatMasters Platform or other Services in violation of applicable law or regulation;</li>
          <li>we believe, in our sole and absolute discretion, that your actions may cause legal liability for you, our users or us;</li>
          <li>we are directed as such by a regulatory authority, law enforcement, or a court of competent jurisdiction;</li>
          <li>we are otherwise required to do so by applicable law or regulation; or</li>
          <li>we decide to cease operations or to otherwise discontinue any Services or options provided by the Website, the GreatMasters Platform, or parts thereof.</li>
        </ul>
      </>,
      <>In the event of termination, the Company will release any Assets to which it obtained an access and which are not owed to Company, unless Company believes you have committed fraud, negligence or other misconduct.</>,
      <><b>“Force Majeure Event”</b> refers herein to any event beyond Company's reasonable control, including but not limited to the flood, extraordinary weather conditions, earthquake, or other act of God, fire, war, insurrection, riot, labor dispute, accident, action of government, suspension of bank accounts of any kind, extraordinary leaps of the course of Asset, communications, network or power failure, or equipment or software malfunction or any other cause beyond Company's reasonable control.</>,
    ],
  }],
},{
  id: 'riskDisclosureAndRestrictionOfLiability',
  section: 'Risk disclosure and restriction of liability',
  subSections: [{
    id: 'riskDisclosure',
    cl: 14,
    section: 'Risk disclosure',
    description: [
      <>Due to our internal policies, we only provide the Services to users with sufficient experience, knowledge and understanding of the work principles of the GreatMasters Platform, and those who fully understand the associated risks. You acknowledge and agree that you shall access and use the GreatMasters Platform at your own risk. We are not responsible for any loss or damage incurred by you as a result of your use of our Services or for your failure to understand the nature of blockchain-based assets or the market of such assets. We make no representations or warranties concerning the value, stability, or legality of any such assets.</>,
      <>You acknowledge that there are risks associated with utilizing an Internet-based auction system including, but not limited to, the failure of hardware, software, and Internet connections. You acknowledge that Company shall not be responsible for any communication failures, disruptions, errors, distortions or delays you may experience when using the GreatMasters Platform, howsoever caused.</>,
      <>You acknowledge and accept that the Company has no control over any blockchain-based assets network and you understand all risks associated with utilizing any assets network, including, but not limited to the risk of unknown vulnerabilities in or unanticipated changes to any network protocol. We will not be responsible for any harm occurring as a result of such risks.</>,
      <>YOU AGREE TO INDEMNIFY AND HOLD COMPANY HARMLESS AGAINST ANY CLAIMS, DEMANDS AND DAMAGES, WHETHER DIRECT, INDIRECT, CONSEQUENTIAL OR SPECIAL, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER IN AN ACTION, IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, ORIGINATED FROM OR IN ANY WAY CONNECTED WITH YOUR USE OF THE GREATMASTERS PLATFORM, INCLUDING, BUT NOT LIMITED TO THOSE ARISING FROM YOUR PERSONAL ERROR AND MISBEHAVIOR SUCH AS FORGOTTEN PASSWORDS, INCORRECTLY CONSTRUCTED TRANSACTIONS, LOSS OF YOUR ACCESSES ETC.</>,
    ],
  },{
    id: 'disclaimerOfWarrantiesLimitationOfLiabilities',
    cl: 15,
    section: 'Disclaimer of warranties. Limitation of liabilities',
    description: [
      <>Except as expressly provided to the contrary in a writing by Us, our services are provided on an “As is” and “As available” basis. WE EXPRESSLY DISCLAIM, AND YOU WAIVE, ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND NON-INFRINGEMENT AS TO OUR SERVICES, INCLUDING THE INFORMATION, CONTENT AND MATERIALS CONTAINED THEREIN.</>,
      <>You acknowledge that information you store or transfer through our services may become irretrievably lost or corrupted or temporarily unavailable due to a variety of causes, including but not limited to software failures, protocol changes by third-party providers, internet outages, Force Majeure event or other disasters including third party DDOS attacks, scheduled or unscheduled maintenance, or other causes either within or outside our control. You are solely responsible for backing up and maintaining duplicate copies of any information you store or transfer through our services.</>,
      <>Except as otherwise required by law, IN NO EVENT SHALL COMPANY, OUR DIRECTORS, OFFICERS, MEMBERS, EMPLOYEES OR AGENTS BE LIABLE FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL OR SPECIAL DAMAGES, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, ARISING OUT OF OR IN ANY WAY CONNECTED WITH THE USE OF OR INABILITY TO USE OUR SERVICES OR THE COMPANY'S IP, INCLUDING WITHOUT LIMITATION ANY DAMAGES CAUSED BY OR RESULTING FROM RELIANCE BY ANY USER ON ANY INFORMATION OBTAINED FROM COMPANY, OR THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES OR EMAIL, ERRORS, DEFECTS, VIRUSES, DELAYS IN OPERATION OR TRANSMISSION OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM A FORCE MAJEURE EVENT, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO COMPANY'S RECORDS, PROGRAMS OR SERVICES.</>,
      <>We resume the right, in our sole discretion, to control any action or proceeding (at our expense) to which we are a participant and determine whether we wish to settle it.</>,
      <>To the extent allowable by law, we reserve the right to set-off any damages or amounts owed to us by you for your breach of this Agreement or other obligations under this Agreement against the Assets in your Account.</>,
      <>To the maximum extent permitted by applicable law, IN NO EVENT SHALL THE AGGREGATE LIABILITY OF COMPANY (INCLUDING OUR DIRECTORS, OFFICERS, MEMBERS, EMPLOYEES AND AGENTS), WHETHER IN CONTRACT, WARRANTY, TORT (INCLUDING NEGLIGENCE, WHETHER ACTIVE, PASSIVE OR IMPUTED), PRODUCT LIABILITY, STRICT LIABILITY OR OTHER THEORY, ARISING OUT OF OR RELATING TO THE USE OF, OR INABILITY TO USE, COMPANY OR TO THESE TERMS EXCEED THE FEES PAID BY YOU TO COMPANY WITHIN 3 MONTHS IMMEDIATELY PRECEDING THE DATE OF ANY CLAIM GIVING RISE TO SUCH LIABILITY.</>,
      <>Company shall not be liable for:
        <ul className="terms_paragraf__list">
          <li>any inaccuracy, error, delay in, or omission of (i) any information, or (ii) the transmission or delivery of information;</li>
          <li>any loss or damage arising from a Force Majeure Event.</li>
        </ul>
      </>,
      <>We strive to protect our users from fraudulent and scam activities in the sphere of blockchain-based Assets. It is possible that some Assets are purposed for unlawful seizure of the property, or are construed as a fraud, scam or any other activity, recognized by the laws as illegal and/or non-compliant with legal requirements. We reserve the right to prohibit and discontinue any transactions on our GreatMasters Platform with such Asset at our sole discretion, without any prior notice to you and without publication of the reason for such decision, whenever this comes to our knowledge. YOU INDEMNIFY AND HOLD COMPANY HARMLESS AGAINST ANY CLAIMS, DEMANDS AND DAMAGES, WHETHER DIRECT, INDIRECT, CONSEQUENTIAL OR SPECIAL, OR ANY OTHER DAMAGES OF ANY KIND, INCLUDING, BUT NOT LIMITED TO, LOSS OF USE, LOSS OF PROFITS OR LOSS OF DATA, WHETHER IN AN ACTION IN CONTRACT, TORT (INCLUDING BUT NOT LIMITED TO NEGLIGENCE) OR OTHERWISE, ORIGINATED FROM OR IN ANY WAY CONNECTED WITH PROHIBITION AND DISCONTINUATION OF TRANSACTIONS IN THE GREATMASTER PLATFORM WITH ANY ASSET.</>,
      <>As some jurisdictions do not allow the exclusion or limitation of liability and/or warranty, some or all of the above limitations may not apply to you. To the extent that we may not, as a matter of applicable law, disclaim any implied warranty and/or liability, the scope and duration of such warranty will be the minimum permitted under such applicable law.</>,
    ],
  },{
    id: 'indemnificationAndRelease',
    cl: 16,
    section: 'Indemnification and release',
    description: [
      <>If we determine that any User has provided or intends to engage in any activity or provide any services or material in violation of any law, your ability to use the Website, the GreatMasters Platform and Services will be terminated immediately without any reimbursement of any payment or fees you may have made to Us. We reserve the right, in our sole and absolute discretion, to cooperate with law enforcement upon legal request and/or advisement of an attorney. We hereby disclaim any liability for damages that may arise from any User providing any material or services for any purpose that violates any law.</>,
      <>To the maximum extent permitted by applicable law, you agree to defend, indemnify, and hold harmless the Company, its parent company, affiliates and subsidiaries and each of their respective officers, directors, shareholders, members, partners, attorneys, employees, independent contractors, telecommunication providers, and agents (collectively, the <b>“Indemnified Parties”</b>), from and against any and all claims (including third-party claims), actions, loss, liabilities, expenses, costs, or demands, including, without limitation, legal and accounting fees (collectively, <b>“Losses”</b>), directly or indirectly, resulting from or by reason of (i) your (or you under another person’s authority, including, without limitation, to governmental agencies) use, misuse, or inability to use the Website, the GreatMasters Platform, Services, or any of the Materials contained therein; or (ii) your breach of this Agreement.</>,
      <>To the maximum extent permitted by applicable law, you hereby discharge, acquit, and otherwise release the Indemnified Parties, from any and all allegations, counts, charges, debts, causes of action, claims and Losses, relating in any way to the use of, or activities relating to the use of the Website and Services including, but not limited to, claims relating to the following: negligence, gross negligence, intentional interference with contract or advantageous business relationship, defamation, privacy, publicity, misrepresentation, any financial loss not due to the fault of the Website, false identities, fraudulent acts by others, invasion of privacy, release of personal information, failed transactions, purchases or functionality of the Website, unavailability of the Website, its functions and/or Services and any other technical failure that may result in inaccessibility to the Website, the GreatMasters Platform or Services, or any claim based on vicarious liability for torts committed by users encountered or transacted with through the Website and Services, including, but not limited to, fraud, computer hacking, theft or misuse of personal information, assault, battery, stalking, rape, cheating, perjury, manslaughter, or murder.</>,
      <>The above list is intended to be illustrative only, and not exhaustive of the types or categories of claims released by you. This release is intended by the parties to be interpreted broadly in favour of the Company, and thus any ambiguity shall be interpreted in a manner providing release of the broadest claims. This release is intended to be a full release of claims, and the parties acknowledge the legally binding nature of this provision, and the nature of the rights given up in connection therewith.</>,
    ],
  }],
},{
  id: 'specialProvisions',
  section: 'Special provisions',
  description: [
    <>17. We may, in our sole discretion and without liability to you, with or without prior notice and at any time, modify or discontinue, temporarily or permanently, any portion of our Services.</>,
    <>18. If you have an insufficient amount of the Assets in your linked Wallet to complete a transaction via the GreatMasters Platform, we may cancel the entire transaction.</>,
    <>19. It is your responsibility to determine what, if any, taxes apply to the Transaction you complete via the GreatMasters Platform, and it is your responsibility to report and remit the correct tax to the appropriate tax authority. You agree that the Company is not responsible for determining whether taxes apply to your transfers or for collecting, reporting, withholding or remitting any taxes arising from any Transactions and does not act as your tax agent.</>,
  ],
  subSections: [{
    id: 'electronicNotices',
    cl: 20,
    section: 'Electronic notices',
    description: [
      <>You agree and consent to receive electronically all Communications, that the Company may be willing to communicate to you in connection with your Account and/or use of the Services. You agree that the Company may provide these Communications to you by posting them on the GreatMasters Platform. <b>“Communications”</b> shall mean all and any communication, agreement, document, receipt, notice and disclosure, which may be from time to time addressed to User by Company.</>,
      <>You may withdraw your consent to receive electronic Communications by sending a withdrawal notice to support. If this is a case you waive your right to plead ignorance. If you decline or withdraw consent to receive electronic Communications, the Company may suspend or terminate your use of the GreatMasters Platform.</>,
      <>By registration on the GreatMasters Platform you agree and consent to receive any Newsletters that the Company may be willing to communicate to you. <b>“Newsletter”</b> shall mean advertising, marketing, informational and any other type of e-mailing or other distribution, which may be from time to time addressed to User by the Company.</>,
      <>Without prejudice to cl. 2, you may withdraw your consent on receiving electronic Newsletters by sending a withdrawal notice to support or by pressing a special button (“an unsubscribe button”).</>,
      <>It is your responsibility to keep your email address on file with the Company up to date so that the Company can communicate with you electronically. You understand and agree that if the Company sends you an electronic Communication but you do not receive it because your email address on file is incorrect, out of date, blocked by your service provider, or you are otherwise unable to receive electronic Communications, the Company will be deemed to have provided the Communication to you. You waive your right to plead ignorance. You can update your email address at any time by logging into your Account or by sending such information to support. If your email address becomes invalid in such a way that electronic Communications sent to you by the Company are returned, the Company may deem your account being inactive, and you may be not able to complete any transaction via the GreatMasters Platform until we receive a valid, working email address from you.</>,
    ],
  },{
    id: 'noOfferOfSecurities',
    cl: 21,
    section: 'No offer of securities',
    description: [
      <>Company endeavors all possible measures to be sure that the Assets available via the GreatMasters Platform cannot be classified as “security” by any competent authority. Moreover, the Company represents that it never intended or desired to make tokens and/or coins that can be classified as security available via the GreatMasters Platform.</>,
      <>The responsibility for the fact that the instrument cannot be treated as “security” lies with the owner of the token and/or coin. If there is any risk or speculation that token and/or coin can be treated as “security”, the GreatMasters Platform reserves the right to prohibit and discontinue any transactions on the GreatMasters Platform with such tokens and/or coins at its sole discretion.</>,
    ],
  },{
    id: 'applicableLawArbitration',
    cl: 22,
    section: 'Applicable law, arbitration',
    description: [
      <>You and we shall strive to settle all disputes and disagreements between us arising from or in connection with these Terms by the complaint procedure.</>,
      <>In the event of a complaint, please set out the cause of your complaint, how you would like us to resolve the complaint and any other information you believe to be relevant. We will receive your complaint if you contact us via support@greatmasters.com.The notice shall be written in English. Within (thirty) 30 calendar days of our receipt of your complaint we will send you a response. Failing to reach an agreement, all disputes arising out of connection or in connection with these Terms, including disputes on its conclusion, binding effect, amendment and termination, shall be resolved by the competent court.</>,
      <>Any dispute between the parties will be governed by these Terms.</>,
      <>Whether the dispute is heard in arbitration or in court, you will not commence (as well as will not participate) against Company a class action, class arbitration or representative action or proceeding.</>,
    ],
  },{
    id: 'changesToTheTerms',
    cl: 23,
    section: 'Changes to the Terms',
    description: [
      <>We reserve the right to make changes or modifications to these Terms from time to time, in our sole discretion. If we make changes to these Terms, we will provide you with notice of such changes, such as by sending an e-mail, providing notice on the homepage of the Website and/or by posting the amended Terms via the applicable Company websites and mobile applications and updating the “Last Updated” date. The amended Terms will be deemed effective immediately upon posting for any new users of the Services. In all other cases, the amended Terms will become effective for pre existing users upon the earlier of either:
        <ul className="terms_paragraf__list">
          <li>the date User click or press a button to accept such changes or;</li>
          <li>the date User continues use of our Services after Company provides notice of such changes or publishes new versions of the Terms on the Website.</li>
        </ul>
      </>,
      <>Any amended Terms will apply prospectively to use of the Services after such changes become effective. If you do not agree to any amended Terms, you must discontinue using our Services and contact us to terminate your account.</>,
    ],
  },{
    id: 'rulesOfInterpretation',
    cl: 24,
    section: 'Rules of interpretation',
    description: [
      <>
        <ul className="terms_paragraf__list">
          <li>“hereof,” “herein,” “hereunder,” “hereby” and words of similar import will, unless otherwise stated, be construed to refer to these Terms as a whole and not to any particular provision of these Terms;</li>
          <li>“include(s)” and “including” shall be construed to be followed by the words “without limitation”; </li>
          <li>“or” shall be construed to be the “inclusive or” rather than “exclusive or” unless the context requires otherwise;</li>
          <li>any rule of construction to the effect that ambiguities are to be resolved against the drafting party shall not be applied in the construction or interpretation of these Terms;</li>
          <li>section titles, captions and headings are for convenience of reference only and have no legal or contractual effect;</li>
          <li>whenever the context requires: the singular number shall include the plural, and vice versa; the masculine gender shall include the feminine and neuter genders; the feminine gender shall include the masculine and neuter genders; and the neuter gender shall include the masculine and feminine genders.</li>
        </ul>
      </>,
    ],
  }],
},{
  id: `miscellaneous`,
  section: `Miscellaneous`,
  description: [
    <>25. These Terms contain the entire agreement and supersede all prior and contemporaneous understandings between the parties regarding the Services.</>,
    <>26. In the event of any conflict between these Terms and any other agreement you may have with Company, the terms of that other agreement will prevail only if these Terms are specifically identified and declared to be overridden by such other agreement.</>,
    <>27. Our failure or delay in exercising any right, power or privilege under these Terms shall not operate as a waiver thereof. The invalidity or unenforceability of any of these Terms shall not affect the validity or enforceability of any other of these Terms, all of which shall remain in full force and effect.</>,
    <>28. You may not assign or transfer any of your rights or obligations under these Terms without prior written consent from the Company, including by operation of law or in connection with any change of control. Company may assign or transfer any or all of its rights under these Terms, in whole or in part, without obtaining your consent or approval.</>,
  ],
},{
  id: `contactUs`,
  section: `Contacting us`,
  description: [
    <>If you have any questions or concerns about the Platform, your registration, or anything else, please send an email to <a className="legal__link" href={`mailto:${contactEmail}`} target="_blank" rel="noreferrer">{contactEmail}</a></>,
  ],
},]
