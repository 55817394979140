import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../store';
import { fetchNftEvents } from '../nftEvents/fetchNftEvents';
import { fetchTokenURI } from './fetchTokenURI';
import { getArtHash } from '../../../utils/getArtHash';
import { fetchMetaData } from './fetchMetaData';
import { Tokens, StateStatusInterface } from '../types';
import { Artwork } from '../../../network/types';
import { auctionContractAddress } from '../../../network/networkConfig';
import { EventData } from "web3-eth-contract";
import { ZERO_ADDRESS } from '../../../data/constants';

const tokens: Tokens = new Map();

export interface AllArtworksInterface extends StateStatusInterface {
  allArtworks: Tokens;
}

const initialState: AllArtworksInterface = {
  allArtworks: [],
  status: 'loaded',
};

export const getAllArtworks = createAsyncThunk(
  'allArtworks/getAllArtworks',
  async () => {
    const response = await fetchNftEvents('Transfer');

    for (let event of response) {
      let tokenId = Number(event.returnValues.tokenId);
      if (!tokens.has(tokenId)) {
        tokens.set(tokenId, {
          tokenId: tokenId,
          history: [],
          metaData: null,
          data: null,
        });
        tokens.get(tokenId).history.push(event);

        const res = await fetchTokenURI(tokenId);
        const hashLink = getArtHash(res);
        tokens.get(tokenId).metaData = hashLink;
        const data = await fetchMetaData(
          hashLink,
          { timeout: 1500 }
        );
        tokens.get(tokenId).data = data;
        
        tokens.get(tokenId).owner = tokens.get(tokenId).history[0].returnValues.to
      } else {
        tokens.get(tokenId).history.push(event);
      }
    }
    return Array.from(tokens.values());
  }
)

export const getNewMintEventToArtworkById = (
  event: EventData,
  tokenId: string | number,
) : AppThunk => (
  dispatch,
) => {
  dispatch(setNewEventToArtworkById({tokenId, event}))
}

export const getNewArtwork = (
  tokenId: number,
  event: any,
) : AppThunk => async (
  dispatch,
  getState,
) => {
  const allArtworks = selectAllArtworks(getState());
  const hasTokenId = allArtworks.some((artwork: any) => 
    artwork.tokenId === +event.returnValues.tokenId);

  if (!hasTokenId) {
    let newArtwork: Artwork = {
      tokenId: +tokenId,
      history: [],
      metaData: '',
      data: {
        author: '',
        description: '', 
        document: '',
        image: '',
        title: ''
      },
      owner: event.returnValues.to
    }
    
    newArtwork.history.push(event)
    const res = await fetchTokenURI(tokenId)
    const hashLink = getArtHash(res)
  
    newArtwork.metaData = hashLink;
    const data = await fetchMetaData(hashLink,
      { timeout: 1500 }
    );
    newArtwork.data = data;
  
    dispatch(setNewArtwork(newArtwork));
  }
}

export const getNewArtworkTransfer = (
  event: EventData,
) : AppThunk => async (
  dispatch,
) => {
  dispatch(setNewArtworkTransfer(event))
}

export const allArtworksSlice = createSlice({
  name: 'allArtworks',
  initialState,
  reducers: {
    setNewEventToArtworkById: (
      state, 
      action: 
        PayloadAction<{
          tokenId: string | number, 
          event: EventData
        }>
    ) => {
      state.allArtworks[action.payload.tokenId].history.unshift(action.payload.event)
    },
    setNewArtwork: (state, 
      action: 
        PayloadAction<Artwork>
    ) => {
      state.allArtworks.unshift(action.payload)
    },
    setNewArtworkTransfer: (
      state,
      action: PayloadAction<EventData>
    ) => {
      const currentArtwork = state.allArtworks.find((artwork: Tokens) => 
        +artwork.tokenId === +action.payload.returnValues.tokenId
      )
      currentArtwork.history.unshift(action.payload);
      currentArtwork.owner = action.payload.returnValues.to;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllArtworks.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllArtworks.fulfilled, (state, action) => {
        state.status = 'loaded';
        state.allArtworks = action.payload;
      })
      .addCase(getAllArtworks.rejected, (state) => {
        state.status = 'failed';
      })
  },
});

export const {
  setNewEventToArtworkById,
  setNewArtwork,
  setNewArtworkTransfer,
} = allArtworksSlice.actions;

export const selectAllArtworks = (state: RootState) => {
  const allArtworks = state.allArtworks.allArtworks.filter((artwork: Artwork) => artwork.data !== undefined)
  return allArtworks
};
export const selectArtworksLoadingStatus = (state: RootState) => state.allArtworks.status;

export const selectArtworkById = (state: RootState, tokenId: string | number | null) => {
  const allArtworks = state.allArtworks.allArtworks.filter((artwork: Artwork) => artwork.data !== undefined)
  const artworkById = allArtworks.filter((artwork: Artwork) => artwork.tokenId === tokenId)
  return artworkById[0]
};

export const getFilteredArtworks = (
  state: RootState,
  account: string,
  filter?: string
) => {

  const currentUser = state.allAccounts.allAccounts.filter((userAccount: Tokens) => {
    return userAccount.account === account});

  const artworks = state.allArtworks.allArtworks
    .filter((artwork: Artwork) => artwork.data !== undefined);

  const arts = currentUser[0]?.artworks

  const userArtworks = artworks.filter((artwork: Artwork) => 
    arts?.some((art: string | number | null) => 
      artwork.tokenId === Number(art))
    );

  switch (filter) {
    case 'notOnSale': 
      return userArtworks
        .filter((artwork: Artwork) => artwork.owner === account);
    case 'onSale':
      return userArtworks
        .filter((artwork: Artwork) => {
          const isFirstSale = artwork.history[0].returnValues.to === auctionContractAddress &&
            artwork.history[0].returnValues.from === ZERO_ADDRESS
          const isSecondarySale = artwork.history[0].returnValues.to === auctionContractAddress &&
          artwork.history[0].returnValues.from === account
          
          return isFirstSale || isSecondarySale
        });
    case 'sold': 
      return userArtworks
        .filter((artwork: Artwork) => {
          const wasOwner = artwork.history.find((item: any) => item.returnValues.from === account);
          const isWasOwner = artwork.history[0].returnValues.to === auctionContractAddress &&
            artwork.history[0].returnValues.from !== account && wasOwner !== undefined;

          return artwork.owner !== account && (artwork.owner !== auctionContractAddress || 
            artwork.history[0].returnValues.from === auctionContractAddress || 
            isWasOwner)
      });
    case 'notLoggedIn':
      return userArtworks
        .filter((artwork: Artwork) => 
          artwork.owner !== account &&
          artwork.history[0].returnValues.to === auctionContractAddress 
        );
    default: 
      return userArtworks
  }
}

export default allArtworksSlice.reducer;