import snsWebSdk from '@sumsub/websdk';
import { useCallback, useState } from 'react';
import './passKYC.sass';
import { useAppSelector } from '../../store/hooks';
import { selectUserAddress } from '../../store/features/userData/userDataSlice';
import { kycFlowName, kycLink } from '../../data/constants';
import { useWeb3React } from '@web3-react/core';
import Button from '../Button/Button';
import { passKycTexts } from '../../data/lettersPagesTexts';

export type SignMessage = (
  account: string,
  message: string,
  provider?: any,
) => Promise<string>;

function PassKYC () {
  const account = useAppSelector(selectUserAddress);  
  const { library } = useWeb3React();
  const [isKycShown, setIsKycShown] = useState(false);

  const launchWebSdk = (
    apiUrl: string,
    flowName: string,
    accessToken: string,
    applicantEmail?: string,
    applicantPhone?: string
  ) => {
    
    const snsWebSdkInstance = snsWebSdk.Builder(apiUrl, flowName)
      .withAccessToken(
        accessToken,
        (newAccessTokenCallback: (arg: string) => void) => {
          newAccessTokenCallback(accessToken)
        }
      )
      .withConf({
        lang: 'en',
        email: applicantEmail,
        phone: applicantPhone,
        i18n: {"document":{"subTitles":{"IDENTITY": "Upload a document that proves your identity"}}},
        onMessage: (type: any, payload: any) => {
            console.log('WebSDK onMessage', type, payload)
        },
        onError: (error: Error) => {
            console.error('WebSDK onError', error)
        },
      })
      .build();
    snsWebSdkInstance.launch('#sumsub-websdk-container')
    setIsKycShown(true);
  }

    const metaMaskSignMessage: SignMessage = useCallback((
      account: string,
      message: string,
    ) => {
      return library
        .getSigner(account)
        .signMessage(message)
        .then((signature: string) => {
          window.scrollTo({
            top: 0,
            behavior: "smooth",
          });
          fetch(`/api/account/${account}/signature/${signature}/message/${message}`)
            .then((res) =>
              res.json()
            )
            .then((data) => {
              return data
            })
            .then((res) => launchWebSdk(kycLink, kycFlowName,  res.token))
            .catch((error: Error) =>  console.log(error.message));
        })
        .catch((error: Error) => {
          console.log(error.message);
        })
    },[library])

  const binanceSignMessage: SignMessage = useCallback((
    account: string,
    message: string,
    BinanceChain: any,
  ) => {
    return BinanceChain.bnbSign(account, message)
    .then((result: { signature: string; }) => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
      fetch(`/api/account/${account}/signature/${result.signature}/message/${message}`)
        .then((res) =>
          res.json()
        )
        .then((data) => {
          return data
        })
        .then((res) => launchWebSdk(kycLink, kycFlowName,  res.token))
        .catch((error: Error) =>  console.log(error.message));
    })
    .catch((error: Error) => {
      console.log(error.message);
    })
  },[])

  const signMessage = useCallback(() => {
    if (window.BinanceChain && localStorage.getItem('connectorId') === 'BSC') {
      const BinanceChain = window.BinanceChain;
      binanceSignMessage(account, 'I+want+to+pass+KYC', BinanceChain)
    } else {
      metaMaskSignMessage(account, 'I+want+to+pass+KYC');
    }
  }, [account, binanceSignMessage, metaMaskSignMessage])

  return (
    <>
      <div id="sumsub-websdk-container"></div>
      { !isKycShown &&
        <>
          <h3 className="h3_title pass_kyc__title">{passKycTexts.header}</h3>
          <div className="letter__description">
            {passKycTexts.description}
          </div>
          <Button
            className="button__big"
            onClick={account ? signMessage : undefined}
          >
            Pass the KYC
          </Button>
        </>
      }
    </>
  )

};

export default PassKYC;
