import { createContext } from "react";
import { useUpload } from './useUpload';
import { UploadContextInterface } from './types';

type Props = {
  children: React.ReactNode,
}

export const UploadContext = createContext<UploadContextInterface>({
  changeHandler: () => null,
  handleDelete: () => null,
  handleDeleteAll: () => null,
  isArtSelected: false,
  setSelectedArt: () => null,
  setArtPreviewUrl: () => null,
  setIsArtSetted: () => null,
  selectedArt: '',
  artUrl: '',
  artHashSetted: false,
  artHash: '',
  setArtHash: () => null,
  setArtHashIsSetted: () => null,
  isDocSelected: false,
  setSelectedDoc: () => null,
  setDocPreviewUrl: () => null,
  setIsDocSetted: () => null,
  selectedDoc: '',
  docUrl: '',
  docHashSetted: false,
  setDocHash: () => null,
  docHash: '',
  setDocHashIsSetted: () => null,
  cloudLink: '',
  setCloudLink: () => null,
  isCloudLinkAdded: false,
  setIsCloudLinkAdded: () => null,
  signedMessage: '',
  setSignedMessage: () => null,
  detailsHash: '',
  setDetailsHash: () => null,
  artworkPrice: '',
  setArtworkPrice: () => null,
});

const { Provider } = UploadContext;

let ModalProvider = ({ children } : Props) => {
  let { 
    changeHandler,
    handleDelete,
    handleDeleteAll,
    isArtSelected, 
    setSelectedArt,
    setArtPreviewUrl,
    setIsArtSetted, 
    selectedArt,
    artUrl,
    artHashSetted,
    artHash,
    setArtHash,
    setArtHashIsSetted,
    isDocSelected,
    setSelectedDoc,
    setDocPreviewUrl,
    setIsDocSetted,
    selectedDoc,
    docUrl,
    docHashSetted,
    setDocHash,
    docHash,
    setDocHashIsSetted,
    cloudLink,
    setCloudLink,
    isCloudLinkAdded,
    setIsCloudLinkAdded,
    signedMessage,
    setSignedMessage,
    detailsHash,
    setDetailsHash,
    artworkPrice, 
    setArtworkPrice,
  } = useUpload();

  return (
    <Provider value={{ 
      changeHandler,
      handleDelete,
      handleDeleteAll,
      isArtSelected, 
      setSelectedArt,
      setArtPreviewUrl,
      setIsArtSetted, 
      selectedArt,
      artUrl,
      artHashSetted,
      artHash,
      setArtHash,
      setArtHashIsSetted,
      isDocSelected,
      setSelectedDoc,
      setDocPreviewUrl,
      setIsDocSetted,
      selectedDoc,
      docUrl,
      docHashSetted,
      setDocHash,
      docHash,
      setDocHashIsSetted,
      cloudLink,
      setCloudLink,
      isCloudLinkAdded,
      setIsCloudLinkAdded,
      signedMessage,
      setSignedMessage,
      detailsHash,
      setDetailsHash,
      artworkPrice, 
      setArtworkPrice,
    }}>
      {children}
    </Provider>
  );
};

export default ModalProvider;