import { Link } from "react-router-dom";
import { CURRENCY } from "../../../data/constants";
import { Artwork } from "../../../network/types";
import { UserBidDataInterface } from "../../../routes/Bids/UsersBids/SingleUserBid/SingleUserBid";
import { getRoundedValue } from "../../../utils/getRoundedValue";
import Button from "../../Button/Button";

interface Props {
  userBidData: UserBidDataInterface,
  isAuctionActive: boolean,
  data: Artwork,
}

function SingleArtCardPartDataUserBid (props: Props) {
  const {
    userBidData,
    isAuctionActive,
    data,
  } = props;
  
  return (
    <>
      <p className="single_art_card__price__container">
        <span className="single_art_card__price price__crypto">
          {getRoundedValue(userBidData.highestBid)}
        </span>
        <span className="single_art_card__price price__crypto">
          {CURRENCY} 
        </span>
        <span className="single_art_card__current_bid">
          { !isAuctionActive ? 
              'Won bet' 
            : 
              'Current bid'
          }
        </span>
      </p>
      { userBidData.outBidded &&
        isAuctionActive ?
          <Link to={`/artworks/${data.tokenId}`}>
            <Button className="place_a_bid__button">Place a bid</Button>
          </Link> 
        : userBidData.outBidded &&
          !isAuctionActive ? 
            <p className="single_art_card__price__container">
              <span className="single_art_card__price price__crypto">
                {getRoundedValue(userBidData.userBid)} 
              </span>
              <span className="single_art_card__price price__crypto">
                {CURRENCY} 
              </span>
              <span className="single_art_card__current_bid">
                Your bid
              </span>
            </p>
        : !userBidData.outBidded &&
            <p className="single_art_card__price__container">
              <span className="single_art_card__price price__crypto">
                {getRoundedValue(userBidData.userBid)} 
              </span>
              <span className="single_art_card__price price__crypto">
                {CURRENCY} 
              </span>
              <span className="single_art_card__current_bid">
                Your bid
              </span>
            </p>
      }
    </>
  )
}

export default SingleArtCardPartDataUserBid;