import { useContext } from 'react';
import './imageModal.sass';
import { ModalContext } from '../Modal/ModalProvider';
import IpfsImage from '../../components/IpfsImage/IpfsImage';
import ResizeImageButton from './ResizeImageButton';
import { ArtworkData } from '../../network/types';

interface ImageModalInterface {
  data: ArtworkData
}

function ImageModal (props: ImageModalInterface) {
  const { handleModalClose } = useContext(ModalContext);
  const { data } = props;

  return (
    <div className="single_artwork__big_image_container">
      <IpfsImage metaData={data.image} className="single_artwork__big_image"/>
      <ResizeImageButton
        onClick={handleModalClose}
        isOpen={true}
      />
    </div>
  )
}

export default ImageModal;
