import { useCallback, useEffect, useState } from "react";
import { WEI } from "../../data/constants";
import { auctionContract } from "../../network/service/web3";

export const useGetAuctionDataByTokenId = () => {
  const [isScheduled, setIsScheduled] = useState(false);
  const [isAuctionActive, setIsAuctionActive] = useState(false);
  const [minimumBid, setMinimumBid] = useState(0);
  const [beneficiary, setBeneficiary] = useState('');
  const [startPrice, setStartPrice] = useState(0);
  const [endTime, setEndTime] = useState(0);
  const [startTime, setStartTime] = useState(0);
  const [highestBid, setHighestBid] = useState(0);
  const [highestBidder, setHighestBidder] = useState('');
  const [timeIsEnded, setTimeIsEnded] = useState(false);
  const [isBidPlaced, setIsBidPlaced] = useState(false);
  const [gettingData, setGettingData] = useState(false);

  const [tokenId, setTokenId] = useState(0);

  const getIsAuctionScheduled = useCallback((tokenId: string | number) => 
    auctionContract.methods.isScheduled(tokenId).call()
    .then((result: boolean) => {
      setIsScheduled(result)
    }), [])

  const getAuctionData = useCallback((tokenId: string | number) => 
    auctionContract.methods.auctions(tokenId).call()
      .then((result: any) => {
        setBeneficiary(result.beneficiary);
        setStartPrice(result.startPrice/WEI);
        setEndTime(Number(result.endTime));
        setStartTime(Number(result.startTime))
        setHighestBid(result.highestBid/WEI);
        setHighestBidder(result.highestBidder);
        setGettingData(false);
      })
      .catch((error: Error) => {
        console.log(error);
      }), [])

  const getIsAuctionActive = useCallback((tokenId: string | number) =>
    auctionContract.methods.isActive(tokenId).call()
      .then((result: boolean) => {
        setIsAuctionActive(result);
      })
      .catch((error: Error) => {
        console.log(error);
      }), [])

  const getMinimumBid = useCallback((tokenId: string | number) =>
    auctionContract.methods.minimumBid(tokenId).call()
      .then((result: number) => {
        setMinimumBid(result/WEI);;
      }), [])

  useEffect(() => {
    if (tokenId !== undefined || gettingData) {
      getIsAuctionScheduled(tokenId);
      getAuctionData(tokenId);
    }
  }, [getAuctionData, getIsAuctionScheduled, gettingData, tokenId])

  useEffect(() => {
    if (isScheduled) {
      getAuctionData(tokenId);
      getIsAuctionActive(tokenId);
    }
  }, [getAuctionData, getIsAuctionActive, isScheduled, tokenId])

  useEffect(() => {
    if (isAuctionActive) {
      getMinimumBid(tokenId);
    }
  }, [getMinimumBid, isAuctionActive, tokenId])

  useEffect(() => {
    if (timeIsEnded) {
      getIsAuctionActive(tokenId);
    }
  }, [getIsAuctionActive, timeIsEnded, tokenId])

  useEffect(() => {
    if (isBidPlaced) {
      getAuctionData(tokenId);
    }
  }, [getAuctionData, isBidPlaced, tokenId])

  useEffect(() => {
    if (gettingData) {
      getAuctionData(tokenId);
    }
  },[getAuctionData, gettingData, tokenId])

  return {
    isScheduled, setIsScheduled,
    isAuctionActive, setIsAuctionActive,
    minimumBid, setMinimumBid,
    beneficiary, setBeneficiary,
    startPrice, setStartPrice,
    endTime, setEndTime,
    startTime, setStartTime,
    timeIsEnded, setTimeIsEnded,
    highestBid, setHighestBid,
    highestBidder, setHighestBidder,
    tokenId, setTokenId,
    isBidPlaced, setIsBidPlaced,
    setGettingData,
  }
}