import { toast } from "react-toastify";
import { CURRENCY } from "./constants";

export const showToastAuctionComplete = () => toast
  .success('Auction is successfully completed.', {
    position: "bottom-right",
    autoClose: 3000,
    toastId: 'auction_completed',
  });

export const showToastBid = () => toast
  .success('You successfully bidded.', {
    position: "bottom-right",
    autoClose: 3000,
    toastId: 'user_bid',
  });

export const showToastCustomTextSuccess = (message: string) => toast
  .success(message, {
    position: "bottom-right",
    autoClose: 3000,
    toastId: 'success__message',
  });

  export const showToastCustomTextError = (message: string) => toast
  .error(message, {
    position: "bottom-right",
    autoClose: 3000,
    toastId: 'success__message',
  });

export const showToastSmthWrong = (message: string = 'Something went wrong.') => toast
  .error(message, {
    position: "bottom-right",
    autoClose: 3000,
    toastId: 'something_wrong',
  });

export const showToastUploadedIntoIPFS = () => toast
  .success('Your files succsessfully uploaded into IPFS.', {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastNotUploaded = () => toast
  .error(<>Please, browse your files first</>, {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastEnterNumber = () => toast
  .error('Please, enter number here.', {
    position: "bottom-right",
    autoClose: 3000,
    toastId: 'toast_enter_number'
  });

export const showToastErrorSignMessage = () => toast
  .error(<>Failure! You need to sign message.</>, {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastEnterAllData = () => toast
  .error('Please, enter Artwork title, minimum price and Artist name.', {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastEnterTitlePrice = () => toast
  .error('Please, enter Artwork title and minimum price.', {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastEnterTitleName = () => toast
  .error('Please, enter Artwork title and Artist name.', {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastEnterPriceName = () => toast
  .error('Please, enter minimum price and Artist name.', {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastEnterTitle = () => toast
  .error('Please, enter Artwork title.', {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastEnterName = () => toast
  .error('Please, enter Artist name.', {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastEnterPrice = () => toast
  .error('Please, enter minimum price.', {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastSignature = (signature: string) => toast
  .success(<>Success!<br/>${signature}</>, {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastImageSize = (
  MIN_IMG_SIDE_SIZE: number,
  width: number,
  height: number
) => toast
  .error(<>Your Item must be 
    <b> min {MIN_IMG_SIDE_SIZE} pixels</b> on the larger side
    <br/>Your Item is {width} x {height} pixels</>, {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastFileFormat = (docType: string) => 
  toast.error(<>Wrong file format. Please upload <b>{docType}</b> here</>, {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastFileSize = (MEGABYTES: string) => toast
  .error(<>Please, upload file <b>less than {MEGABYTES}</b></>, {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastCopyText = (toastMessage: string) => toast
  .success(toastMessage,{
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastNotEnoughFunds = () => toast
  .error('Your try to bet more than your funds available on the account.', {
    position: "bottom-right",
    autoClose: 3000,
    toastId: 'bet_more_than_available'
  });

export const showToastApproveUSDT = () => toast
  .success(`${CURRENCY} usage approved`, {
    position: "bottom-right",
    autoClose: 3000,
    toastId: 'usdt_approved',
  });

export const showToastApproveUSDTError = () => toast
  .error(`Please, approve ${CURRENCY} usage.`, {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastStartPrice = () => toast
  .error('Please enter start price.', {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastApproveAuctionError = () => toast
  .error('Please approve the auction first.', {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastApproveAuctionSignTransactionError = () => toast
  .error('You need to approve auction contract to start auction.', {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastApproveAuction = () => toast
  .success('Auction is successfully approved.', {
    position: "bottom-right",
    autoClose: 3000,
    toastId: 'auction_approved',
  });

export const showToastAuctionScheduled = () => toast
  .success('Auction is successfully scheduled.', {
    position: "bottom-right",
    autoClose: 3000,
    toastId: 'auction_scheduled',
  });

export const showToastWrongNetwork = () => toast
  .error(
    <>
      You're connected to an unsupported network.<br/>
      Please, change network in your wallet to Ethereum network
    </>, {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastNoBrowserExtentionMetaMask = () => toast
  .info(
    <>
      No Ethereum browser extension detected.
      <br/>
      Install MetaMask on desktop or visit from a dApp browser on mobile.
    </>, {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastNoBrowserExtentionBSC = () => toast
  .info(
    <>
      No Ethereum browser extension detected.
      <br/>
      Install Binance Chain Wallet on desktop or visit from a dApp browser on mobile.
    </>, {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastNoAuthorisation = () => toast
  .error('Please authorize this website to access your Ethereum account.', {
    position: "bottom-right",
    autoClose: 3000,
  });

export const showToastCheckConnection = (error: Error) => toast
  .error(
    <>
      {error.message}. Please check your connection.
    </>, {
    position: "bottom-right",
    autoClose: 3000,
  });
