import { ChangeEvent } from 'react'; 

export const handleInputBlur = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  if (e.target.value !== '') {
    e.target.style.borderColor = '#E7E8FF';
    e.target.style.backgroundColor = '#fff';
  } else {
    e.target.style.borderColor = '';
    e.target.style.backgroundColor = '';
  }
}