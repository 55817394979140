import ButtonKYC from "../Button/ButtonKYC";

export type SignMessage = (
  account: string,
  message: string,
  provider?: any,
) => Promise<string>;

function ButtonPassKYC() {
  return (
    <>
      <p className="no_arts__plain__description">Pass the KYC to buy artworks</p>
      <ButtonKYC
        title="Pass the KYC"
        className="button__big"
      />
    </>
  )
}

export default ButtonPassKYC;


