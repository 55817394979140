import InfoItem from './InfoItem/InfoItem';
import { EventData } from "web3-eth-contract";
import { useEffect, useState } from 'react';
import { auctionContractAddress } from '../../../network/networkConfig';

interface SingleArtworkInfoInterface {
  history: EventData[],
  artworkAuctions: EventData[],
}

function SingleArtworkInfo (props: SingleArtworkInfoInterface) {
  const [currentOwner, setCurrentOwner] = useState<EventData>();
  const [previousOwner, setPreviousOwner] = useState<EventData>();
  const [minted, setMinted] = useState<EventData>();

  const { history, artworkAuctions } = props;

  useEffect(() => {
    if (history && artworkAuctions) {
      if (history.length === 1 && history[0].returnValues.to === auctionContractAddress) {
        setCurrentOwner(artworkAuctions[artworkAuctions.length - 1]);
      } else {
        setCurrentOwner(history[0]);
        setPreviousOwner(history[1]);
      }
      setMinted(artworkAuctions[artworkAuctions.length - 1]);
    }
  }, [history, artworkAuctions])
  
  return (
    <div className="single_artwork__info_tab_container">
      <InfoItem
        status="curent"
        info={currentOwner}
        previousOwner={previousOwner}
      />
      <InfoItem 
        status="mintedBy"
        info={minted}
      />
    </div>
  )
}

export default SingleArtworkInfo;