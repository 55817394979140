import { LegalListItemI } from "./types";

export function LegalListItem({
  title,
  list_items,
}: LegalListItemI) {
  return (
    <li>
      {!!title && 
        <div
          className="legal__text_item"
        >
          {title}
        </div>
      }
      { list_items && list_items.map((list_item, key) => (
        <div
          key={`${list_item}_${key}`}
          className="legal__text_item">
          {list_item}
        </div>
        )
      )}
    </li>
  )
}
