import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <path d="M7.6 1C7.6 0.447715 7.15229 -2.119e-07 6.6 -2.36041e-07C6.04772 -2.60183e-07 5.6 0.447715 5.6 1L7.6 1ZM6.6 15L5.89289 15.7071L6.6 16.4142L7.30711 15.7071L6.6 15ZM12.7071 10.3071C13.0976 9.91658 13.0976 9.28342 12.7071 8.89289C12.3166 8.50237 11.6834 8.50237 11.2929 8.89289L12.7071 10.3071ZM1.90711 8.89289C1.51658 8.50237 0.883418 8.50237 0.492894 8.89289C0.102369 9.28342 0.102369 9.91658 0.492894 10.3071L1.90711 8.89289ZM5.6 1L5.6 15L7.6 15L7.6 1L5.6 1ZM11.2929 8.89289L5.89289 14.2929L7.30711 15.7071L12.7071 10.3071L11.2929 8.89289ZM7.30711 14.2929L1.90711 8.89289L0.492894 10.3071L5.89289 15.7071L7.30711 14.2929Z"/>
    </Svg>
  );
};

export default Icon;
