import { useState } from "react";
import { CSSTransition } from "react-transition-group";
import { getTaggedText } from "../../utils/getTaggedText";
import { ExpandIcon } from "../Svg";
import { FaqParagrafInterface } from "./Faq";

function FaqQuestion(paragraf: FaqParagrafInterface) {
  const [isHidden, setIsHidden] = useState(true);

  return (
    <div className="faq__paragraf">
      <div
        className={`faq__paragraf_title_container ${!isHidden && 'active'}`}
        onClick={() => setIsHidden(!isHidden)}
      >
        <h3
          id={paragraf.paragrafId}
          className="faq__paragraf_title"
        >{paragraf.title}</h3>
        <ExpandIcon/>
      </div>
      <CSSTransition in={isHidden} timeout={0} classNames="faq__paragraf_description">
        <div 
          className="faq__paragraf_description-enter"
          onClick={() => setIsHidden(!isHidden)}
        >
          { typeof paragraf.description === 'string' ?
              getTaggedText(paragraf.description) 
            : paragraf.description
          }
        </div>
      </CSSTransition>
    </div>
  )
}

export default FaqQuestion;