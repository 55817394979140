import { LinkInterface, SocilaLinkInterface, RouteInterface, WalletInfo } from './types';
import { injected, bscConnector } from '../connectors/connectors';
import AccountContainer from '../routes/Account/AccountContainer';
import UploadContainer from '../routes/Account/UploadContainer';
import UploadArt from '../components/UploadArt/UploadArt';
import UploadArtDone from '../components/UploadArt/UploadArtDone';
import UploadArtDetails from '../components/UploadArt/UploadArtDetails';
import { Facebook, Instagram, MediumIcon, Twitter } from '../components/Svg';
import BidsPage from '../routes/Bids/BidsPage';
import Home from '../routes/Home/HomeContainer';
import UsersContainer from '../routes/Users/UsersContainer';
import ArtworkContainer from '../routes/Artwork/ArtworkContainer';
import PassKycContainer from '../routes/PassKyc/PassKycContainer';
import GetInviteContainer from '../routes/GetInvite/GetInviteContainer';
import FaqContainer from '../routes/FAQ/FaqContainer';
import TermsContainer from '../routes/Terms/TermsContainer';
import CookiesPolicyContainer from '../routes/CookiesPolicy/CookiesPolicyContainer';
import PrivacyPolicyContainer from '../routes/PrivacyPolicy/PrivacyPolicyContainer';
import { AboutContainer } from '../routes/About';

export const WEI = 10**6;
export const CURRENCY = 'USDT';
export const etherscanLink = `${process.env.REACT_APP_NETWORK_LINK!}address/`;
export const etherscanTransactionLink = `${process.env.REACT_APP_NETWORK_LINK}tx/`;

export const faqContactEmail = 'support@greatmasters.com';

export const roleCreator = process.env.REACT_APP_STATUS_ROLE_CREATOR!;
export const roleTrader = process.env.REACT_APP_STATUS_ROLE_TRADER!;

export const kycLink = process.env.REACT_APP_KYC_LINK!;
export const kycFlowName = process.env.REACT_APP_KYC_FLOW_NAME!;

export const routerLinks: RouteInterface[] = [{
	name: 'Items',
	path: '/items',
	component: Home
},{
	name: 'FAQ',
	path: '/faq',
	component: FaqContainer,
},{
	name: 'Bids',
	path: '/bids',
	component: BidsPage,
},{
	name: 'About',
	path: '/about',
	component: AboutContainer,
},{
	name: 'Terms',
	path: '/terms',
	component: TermsContainer,
},{
	name: 'Account',
	path: '/account',
	component: AccountContainer,
},{
	name: 'Upload',
	path: '/upload',
	component: UploadContainer,
	routes: [{
		name: 'Upload - Step 1',
		path: '/upload/step_1',
		component: UploadArt,
	},{ 
		name: 'Upload - Step 2',
		path: '/upload/step_2',
		component: UploadArtDetails,
	},{ 
		name: 'Upload - Done',
		path: '/upload/done',
		component: UploadArtDone,
	}]},{
		name: 'User',
		path: '/users/:id',
		component: UsersContainer,
	},{
		name: 'Artwork',
		path: '/artworks/:id',
		component: ArtworkContainer,
	},{
		name: 'Pass KYC',
		path: '/kyc',
		component: PassKycContainer,
	},{
		name: 'Get invite',
		path: '/invite',
		component: GetInviteContainer,
	},{
		name: 'Privacy policy',
		path: '/policy',
		component: PrivacyPolicyContainer,
	},{
		name: 'Cookies policy',
		path: '/cookies',
		component: CookiesPolicyContainer,
	},
];

export const header: LinkInterface[] = [{
	name: 'Items',
	path: '/'
},{
	name: 'FAQ',
	path: '/faq',
}];

export const headerLoggedIn: LinkInterface[] = [{
	name: 'Bids',
	path: '/bids'
},{
	name: 'Items',
	path: '/'
},{
	name: 'FAQ',
	path: '/faq',
}];

export const email: string = 'example@greatmasters.com';

export const footer: LinkInterface[] = [{
		name: 'FAQ',
		path: '/faq'
	},{
		name: 'About',
		path: '/about'
	},{
		name: 'Terms of use',
		path: '/terms'
	},{
		name: 'Privacy policy',
		path: '/policy',
	},{
		name: 'Cookies policy',
		path: '/cookies',
	},
];

export const social: SocilaLinkInterface[] = [{
	name: 'facebook',
	path: 'https://www.facebook.com/greatmasterscom/',
	img: Facebook,
},{
	name: 'instagram',
	path: 'https://www.instagram.com/greatmasterscom/',
	img: Instagram,
},{
	name: 'medium',
	path: 'https://medium.com/@greatmasterscom',
	img: MediumIcon,
},{
	name: 'twitter',
	path: 'https://twitter.com/greatmasterscom',
	img: Twitter,
}];

export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000';

export const ConnectorNames = {
	Injected: "injected",
	MetaMask: "MetaMask",
	BSC: "BSC",
};

export const SUPPORTED_WALLETS: { [key: string]: WalletInfo } = {
  INJECTED: {
    connector: injected,
    name: 'Injected',
    iconName: 'arrow-right.svg',
    description: 'Injected web3 provider.',
    href: null,
    color: '#010101',
    primary: true
  },
  METAMASK: {
    connector: injected,
    name: 'MetaMask',
    iconName: 'metamask.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#E8831D'
  },
	BSC: {
    connector: bscConnector,
    name: 'Binance Chain Wallet',
    iconName: 'bsc.png',
    description: 'Easy-to-use browser extension.',
    href: null,
    color: '#ffff61'
  },
}

export const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
