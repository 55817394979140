import { useWeb3React } from '@web3-react/core';
import Button from "../../../components/Button/Button";
import { WEI } from '../../../data/constants';
import { showToastCustomTextError } from '../../../data/toasts';
import { auctionContractAddress } from "../../../network/networkConfig";
import { auctionContract } from "../../../network/service/web3";
import { selectUserAddress } from "../../../store/features/userData/userDataSlice";
import { useAppSelector } from "../../../store/hooks";

interface ButtonPlaceBidInterface {
  tokenId: string | number,
  bidValue: number,
  setIsBidPlaced: React.Dispatch<React.SetStateAction<boolean>>,
  handleModalClose: () => void,
  setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>,
  setBidTransactionHash: React.Dispatch<React.SetStateAction<string>>,
}

interface ProviderRpcError extends Error {
  message: string;
  code: number;
  data?: unknown;
}

function ButtonPlaceBid (props: ButtonPlaceBidInterface) {
  const { active } = useWeb3React();
  const account = useAppSelector(selectUserAddress);

  const {
    tokenId,
    bidValue,
    setIsBidPlaced,
    handleModalClose,
    setIsProcessing,
    setBidTransactionHash,
  } = props;

  const sendBid = (
    connector: any,
    from: string, 
    toContract: string, 
    data: any, 
  ) => {
    connector.request({
      method: 'eth_sendTransaction',
      params: [{
        from: from,
        to: toContract,
        data: data
      }]
    }) 
    .then((result: string) => {
      handleModalClose()
      setBidTransactionHash(result)
      setIsBidPlaced(true);
      setIsProcessing(true);
    })
    .catch((error: ProviderRpcError) => {
      if (error.code === 4001) {
        showToastCustomTextError("The request was rejected by the user");
      } else {
        showToastCustomTextError(error.message);
      }
    })
  }

  const handlePlaceABid = async () => {
    if (active && account && window.BinanceChain && localStorage.getItem('connectorId') === 'BSC') {
      const BinanceChain = window.BinanceChain;
      const bid = Math.round(bidValue*WEI);
        sendBid(
          BinanceChain, 
          account, 
          auctionContractAddress,
          auctionContract.methods
            .bid(tokenId, bid)
            .encodeABI()
        );
    } else if (active && account && window.ethereum) {
      const ethereum = window.ethereum;
      const bid = Math.round(bidValue*WEI);
        sendBid(
          ethereum, 
          account, 
          auctionContractAddress,
          auctionContract.methods
            .bid(tokenId, `${bid}`)
            .encodeABI()
        );
    } else {
      console.log('Unexpected error occurred')
    }
  }
  
  return (
    <Button className="button__big"
      onClick={() => handlePlaceABid()}
    >
      Place a bid
    </Button>
  )
}

export default ButtonPlaceBid;
