import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M11.5996 1.7998C9.6218 1.7998 7.6884 2.38629 6.04391 3.48511C4.39942 4.58392 3.11769 6.14571 2.36082 7.97297C1.60394 9.80023 1.40591 11.8109 1.79176 13.7507C2.17761 15.6905 3.13002 17.4723 4.52855 18.8709C5.92707 20.2694 7.7089 21.2218 9.64871 21.6077C11.5885 21.9935 13.5992 21.7955 15.4264 21.0386C17.2537 20.2817 18.8155 19 19.9143 17.3555C21.0131 15.711 21.5996 13.7776 21.5996 11.7998C21.5996 9.14764 20.546 6.6041 18.6707 4.72874C16.7953 2.85337 14.2518 1.7998 11.5996 1.7998Z"  strokeWidth="1.5" strokeMiterlimit="10"/>
      <path d="M8.59961 9.12016C8.59961 9.12016 8.64461 8.20349 9.648 7.41411C10.2432 6.9453 10.9568 6.80963 11.5996 6.8002C12.1851 6.79287 12.708 6.88768 13.0209 7.0333C13.5566 7.28263 14.5996 7.8913 14.5996 9.18564C14.5996 10.5475 13.6889 11.1662 12.6512 11.8466C11.6135 12.527 11.3318 13.2656 11.3318 14.0288"  strokeWidth="1.5" strokeMiterlimit="10" strokeLinecap="round"/>
      <path d="M11.2785 17.7998C11.8702 17.7998 12.3499 17.3308 12.3499 16.7522C12.3499 16.1736 11.8702 15.7046 11.2785 15.7046C10.6867 15.7046 10.207 16.1736 10.207 16.7522C10.207 17.3308 10.6867 17.7998 11.2785 17.7998Z" />
    </Svg>
  );
};

export default Icon;
