import './home.sass';
import AllArtsMainPage from '../../components/AllArts/AllArtsMainPage';
import MainSlider from '../../components/MainSlider/MainSlider';

function Home () {
  return (
    <>
      <MainSlider/>
      <AllArtsMainPage/>
    </>
  )
}

export default Home;