import { useEffect, useState } from "react";
import { EventData } from "web3-eth-contract";
import SingleArtCard from "../../../../components/SingleArtCard/SingleArtCard";
import { WEI } from "../../../../data/constants";
import { auctionContract } from "../../../../network/service/web3";
import { selectUserAddress } from "../../../../store/features/userData/userDataSlice";
import { useAppSelector } from "../../../../store/hooks";

interface SingleUserBidInterface {
  data: EventData;
}

export interface UserBidDataInterface {
  isActiveUserBid: boolean,
  outBidded: boolean,
  userBid: number,
  highestBid: number,
}

function SingleUserBid(props: SingleUserBidInterface) {
  const { data } = props;
  const account = useAppSelector(selectUserAddress);

  const [highestBid, setHighestBid] = useState(0);
  const [highestBidder, setHighestBidder] = useState('');

  const userBid = data.returnValues.price/WEI;
  const tokenId = data.returnValues.artId

  useEffect(() => {
    auctionContract.methods.auctions(tokenId).call()
      .then((result: any) => {
        setHighestBid(result.highestBid/WEI);
        setHighestBidder(result.highestBidder);
      })
      .catch((error: Error) => {
        console.log(error);
      });
  }, [tokenId])

  const userBidData: UserBidDataInterface = {
    isActiveUserBid: true,
    outBidded: highestBidder !== account,
    userBid: userBid,
    highestBid: highestBid,
  }

  return (
    <SingleArtCard
      tokenId={data?.returnValues.artId}
      userBidData={userBidData}
    />
  )
}

export default SingleUserBid;