import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 48 48" width="30px" height="30px" {...props}>
      <circle cx="14" cy="24" r="12"/>
      <ellipse cx="34" cy="24" rx="6" ry="11"/>
      <ellipse cx="44" cy="24" rx="2" ry="10"/>
    </Svg>
  );
};

export default Icon;
