export const statusContactEmail = 'status@greatmasters.com';
export const moderatorContactEmail = 'moderation@greatmasters.com';

export const passKycTexts = {
  header: 'Pass the KYC',
  description: <>To get access to buy or upload artworks you need to pass the KYC verification.<br/><br/>First, you need to sign message via your crypto wallet and then the KYC process will start. <br/><br/> To start please press the button "Pass the KYC"<br/><br/>If you have further questions, please contact us via email: <a href={`mailto:${statusContactEmail}`} target="_blank" rel="noreferrer">{statusContactEmail}</a></>,
}

export const getInviteTexts = {
  header: 'Get invite',
  description: <>To get access to upload artworks you need to get special status. You will find an example of a letter below. If after clicking the "send" button nothing happened, copy the letter and send it to <a href={`mailto:${statusContactEmail}`} target="_blank" rel="noreferrer" >{statusContactEmail}</a></>,
  letter: 'Hello there!%0D%0A%0D%0AI want to get access to upload Artworks.',
  moderationInfo: 'When we receive your email we will send you back an answer about yous status changes. After that you could upload your awesome Artworks.'
}

export const uploadArtDoneTexts = {
  header: 'Good Job! The last step left',
  description: <>For moderation, you need to send a letter. You will find an example of a letter below. If after clicking the "send" button nothing happened, copy the letter and send it to <a href={`mailto:${moderatorContactEmail}`} target="_blank" rel="noreferrer">{moderatorContactEmail}</a></>,
  letter: 'Hello there!%0D%0A%0D%0ASending you my artwork on moderation',
  moderationInfo: 'When the painting is approved, we will send you an email and it will also appear in your personal account.'
}
