import { NavLink } from 'react-router-dom';
import { footer, social } from '../../data/constants';
import './footer.sass';

function Footer() {
	return (
		<footer className="container footer">
			<div className="footer__container">
				<ul className="footer__main_nav">
					{footer.map((item, key) => <li className="footer_nav__item" key={`footer_nav__item_${key}`}>
							<NavLink 
								to={item.path} 
								className="footer_nav__link"
								data-text={item.name}
							>
								{item.name}
							</NavLink>
						</li>
					)}
				</ul>
				<ul className="footer__social">
					{social.map((item, key) => {
						const { img: Icon } = item;
						return (
							<li className="social_nav__item" key={`social_nav__item_${key}`}>
								<a 
									href={item.path} 
									target="_blank" 
									rel="noreferrer" 
									className="social_nav__link">
										<Icon />
								</a>
							</li>
						)}
					)}
				</ul>
			</div>
		</footer>
	)
};

export default Footer;
