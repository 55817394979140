import { CURRENCY } from "../../../data/constants";
import { getRoundedValue } from "../../../utils/getRoundedValue";

interface Props {
  account: string,
  highestBidder: string,
  lastUserBid: number,
  isScheduled: boolean,
  isAuctionActive: boolean,
  isAuctionCompleted: boolean,
  isProcessing: boolean,
  timeIsEnded: boolean,
  timeIsUp: boolean,
  timeEnded: boolean,
  isAuctionFailed: boolean,
  startPrice: number,
  minBid: number,
  highestBid: number,
  winningPrice: number,
}

function CurrentBid (props: Props) {
  const {
    account,
    highestBidder,
    isScheduled,
    isAuctionActive,
    isAuctionCompleted,
    isProcessing,
    timeIsEnded,
    timeIsUp,
    timeEnded,
    isAuctionFailed,
    startPrice,
    minBid,
    highestBid,
    winningPrice,
  } = props;

  const auctionInProgress = isScheduled && !isAuctionCompleted && 
    !isProcessing && !timeIsUp && highestBidder !== account;
  const currentUserBidHighest = isScheduled && !isAuctionCompleted && 
    !isProcessing && !timeIsUp && highestBidder === account;
  const auctionEnded = isScheduled && !isAuctionCompleted && 
    !isAuctionActive && !isAuctionFailed &&
    !isProcessing && timeIsUp && minBid > 0;
  const auctionCompleted = isAuctionCompleted && !isAuctionActive &&
    !isProcessing && !isAuctionFailed

  return (
    <>
      { timeEnded || 
        isAuctionFailed ?
          <div className="single_artwork__minimum_bid_container">
            <div className="single_artwork__minimum_bid">
              <span className="minimum_bid">Auction failed</span>
            </div>
          </div>
        : auctionInProgress ? 
          <div className="single_artwork__minimum_bid_container">
            <p className="single_artwork__present_bidding_title">
              Minimum bid
            </p>
            <div className="single_artwork__minimum_bid">
              <span className="minimum_bid">{getRoundedValue(minBid)}</span> {CURRENCY}
            </div>
          </div>
        : currentUserBidHighest  ?
          <div className="single_artwork__minimum_bid_container">
            <p className="single_artwork__present_bidding_title">
              Your bid is the highest
            </p>
            <div className="single_artwork__minimum_bid">
              <span className="minimum_bid">{getRoundedValue(highestBid)}</span> {CURRENCY}
            </div>
          </div>
        : auctionEnded ?
          <div className="single_artwork__minimum_bid_container">
            <p className="single_artwork__present_bidding_title">
              Sold for
            </p>
            <div className="single_artwork__minimum_bid">
              <span className="minimum_bid">{getRoundedValue(highestBid)}</span> {CURRENCY}
            </div>
          </div>
        : auctionCompleted ?
          <div className="single_artwork__minimum_bid_container">
            <p className="single_artwork__present_bidding_title">
              Sold for
            </p>
            <div className="single_artwork__minimum_bid">
              <span className="minimum_bid">{winningPrice ? winningPrice : getRoundedValue(highestBid)}</span> {CURRENCY}
            </div>
          </div>
        : timeIsEnded &&
          !isProcessing ? 
          <div className="single_artwork__minimum_bid_container">
            <p className="single_artwork__present_bidding_title">
              {startPrice > highestBid ? 'No bids' : 'Last bid'}
            </p>
            <div className="single_artwork__minimum_bid">
              <span className="minimum_bid">{winningPrice ? winningPrice : getRoundedValue(highestBid)}</span> {CURRENCY}
            </div>
          </div>
        : isProcessing &&
          <div className="single_artwork__minimum_bid_container">
            <p className="single_artwork__present_bidding_title">
              Minimum bid
            </p>
            <div className="single_artwork__minimum_bid">
              <span className="minimum_bid skeleton">processing</span>
            </div>
          </div>
      }
    </>
  )
}


export default CurrentBid;