interface SoonStartingMessageInterface {
  isScheduled: boolean,
  isNftApproved: boolean,
  isAuctionCompleted: boolean,
}

function SoonStartingMessage (props: SoonStartingMessageInterface) {
  const { 
    isScheduled,
    isNftApproved,
    isAuctionCompleted,
  } = props;

  return (
    <div className="single_artwork__approve_nft_container">
      { !isScheduled && !isNftApproved && isAuctionCompleted ? 
          <p className="single_artwork__approve_nft_message">
            No auction scheduled
          </p>
        : isNftApproved &&
          <p className="single_artwork__approve_nft_message">
            Auction will start soon
          </p>
      }
    </div>
  )
}

export default SoonStartingMessage;