import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="14" height="14" viewBox="0 0 14 14" {...props}>
      <path d="M13.6996 0.909931C13.6071 0.817227 13.4972 0.74368 13.3763 0.693498C13.2553 0.643317 13.1256 0.617486 12.9946 0.617486C12.8637 0.617486 12.734 0.643317 12.613 0.693498C12.492 0.74368 12.3822 0.817227 12.2896 0.909931L7.39964 5.78993L2.50964 0.899931C2.41706 0.807349 2.30715 0.733909 2.18618 0.683804C2.06522 0.633699 1.93557 0.60791 1.80464 0.60791C1.67371 0.60791 1.54406 0.633699 1.42309 0.683804C1.30213 0.733909 1.19222 0.807349 1.09964 0.899931C1.00706 0.992512 0.933616 1.10242 0.883511 1.22339C0.833406 1.34435 0.807617 1.474 0.807617 1.60493C0.807617 1.73586 0.833406 1.86551 0.883511 1.98647C0.933616 2.10744 1.00706 2.21735 1.09964 2.30993L5.98964 7.19993L1.09964 12.0899C1.00706 12.1825 0.933616 12.2924 0.883511 12.4134C0.833406 12.5344 0.807617 12.664 0.807617 12.7949C0.807617 12.9259 0.833406 13.0555 0.883511 13.1765C0.933616 13.2974 1.00706 13.4073 1.09964 13.4999C1.19222 13.5925 1.30213 13.666 1.42309 13.7161C1.54406 13.7662 1.67371 13.792 1.80464 13.792C1.93557 13.792 2.06522 13.7662 2.18618 13.7161C2.30715 13.666 2.41706 13.5925 2.50964 13.4999L7.39964 8.60993L12.2896 13.4999C12.3822 13.5925 12.4921 13.666 12.6131 13.7161C12.7341 13.7662 12.8637 13.792 12.9946 13.792C13.1256 13.792 13.2552 13.7662 13.3762 13.7161C13.4971 13.666 13.6071 13.5925 13.6996 13.4999C13.7922 13.4073 13.8657 13.2974 13.9158 13.1765C13.9659 13.0555 13.9917 12.9259 13.9917 12.7949C13.9917 12.664 13.9659 12.5344 13.9158 12.4134C13.8657 12.2924 13.7922 12.1825 13.6996 12.0899L8.80964 7.19993L13.6996 2.30993C14.0796 1.92993 14.0796 1.28993 13.6996 0.909931Z"/>
    </Svg>
  );
};

export default Icon;
