import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { faqContactEmail } from '../../data/constants';
import { faqText } from '../../data/faq';
import './faq.sass';
import FaqQuestion from './FaqQuestion';

export interface FaqParagrafInterface {
  paragrafId: string;
  title: string;
  description: string | JSX.Element;
}

interface FaqSectionInterface {
  sectionId: string;
  section: string;
  paragrafs: FaqParagrafInterface[]
}

function Faq() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      document.querySelector(location.hash)?.scrollIntoView({
        behavior: 'smooth'
      });
    }
  }, [location.hash])

  return (
    <div className="faq__section_container">
      <h1 className="faq__heading">FAQ</h1>
      <ul>{ faqText.map((section: FaqSectionInterface, index: number) => {
        return <li
            className="faq__section"
            key={`faq_section_${index}`}
            id={section.sectionId}>
            <h3 className="faq__section_title">{section.section}</h3>
            { section.paragrafs.map((paragraf: FaqParagrafInterface, i: number) => {
              return <FaqQuestion {...paragraf} key={`faq_question_${i}`}/>
            })}
          </li>
        })}
      </ul>
      <div className="faq__contacts_section">
        <h3 className="faq__section_title">Want to find out more?</h3>
        <p className="faq__paragraf_title text">Please contact us at</p>
        <p className="faq__paragraf_title">
          <a href={`mailto:${faqContactEmail}`} target="_blank" rel="noreferrer">{faqContactEmail}</a>
        </p>
      </div>
    </div>
  )
}

export default Faq;
