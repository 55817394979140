import { ModalData } from "../Modal/types";
import { Link } from 'react-router-dom';
import { getInviteModalLinkHow } from "../modalTexts";

export const GetInviteData: ModalData = {
  title: 'Apply for invite',
  subTitle: <>Not invited yet?<br/><Link to={getInviteModalLinkHow}
    className="invite__link">
      Learn more about how to get invited
    </Link>
  </>,
  className: "modal__get_invite",
}