import { useEffect, useState } from "react";
import { Switch, Route } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import './App.sass';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import { routerLinks, ZERO_ADDRESS } from './data/constants';
import Home from './routes/Home/HomeContainer';
import RouteWithSubRoutes from './routes/RouteWithSubRoutes';
import ErrorPage from './components/ErrorPage/ErrorPage';
import {
  getArtworksAuctions,
  getNewArtworkAuction,
  getNewAuctionEventToArtworkById,
  selectArtworksAuctions,
} from "./store/features/auctionEvents/auctionEventsSlice";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { getNftEvents } from "./store/features/nftEvents/nftEventsSlice";
import { auctionContract, contract } from "./network/service/web3";
import { getAllArtworks, getNewArtwork, getNewArtworkTransfer } from "./store/features/allArtworks/allArtworksSlice";
import { getAllAccounts } from "./store/features/allAccounts/allAccountsSlice";
import { EventData } from "web3-eth-contract";
import { getUserBid, getUserBidDeletion, selectAllUserBids, selectUserAddress } from "./store/features/userData/userDataSlice";
import { useHasEventChanged, usePreviousEventData } from "./hooks/usePrevious";

function App() {
  const [isMinted, setIsMinted] = useState(false);
  const [isEvent, setIsEvent] = useState(false);
  const [bidData, setBidData] = useState<EventData>();
  const [transferEvent, setTransferEvent] = useState<EventData>();
  const [auctionEvent, setAuctionEvent] = useState<EventData>();

  const dispatch = useAppDispatch();

  const allAuctions = useAppSelector(selectArtworksAuctions);
  const userAccount = useAppSelector(selectUserAddress);
  const userBids = useAppSelector(selectAllUserBids);

  const hasEventChanged = useHasEventChanged(bidData);
  const prevEvent = usePreviousEventData(bidData);

  const hasArtworkTransfered = useHasEventChanged(transferEvent);
  const prevArtworkTransfered = usePreviousEventData(transferEvent);

  const hasAuctionEvent = useHasEventChanged(auctionEvent);
  const prevAuctionEvent = usePreviousEventData(auctionEvent);

  useEffect(() => {
    dispatch(getArtworksAuctions());
    dispatch(getNftEvents());
    dispatch(getAllArtworks());
    dispatch(getAllAccounts());
  }, [dispatch])

  useEffect(() => {
    setIsMinted(true);
    if (isMinted) {
      contract.events.Transfer({
        filter: {from: ZERO_ADDRESS},
      }, 
        (error: Error, result: EventData) => {
          if (!error) {
            dispatch(getNewArtwork(Number(result.returnValues.tokenId), result));
          } else {
            console.log(error)
          }
      })
    }

    return () => {
      setIsMinted(false)
    }
  }, [dispatch, isMinted])

  useEffect(() => {
    setIsEvent(true)
    if (isEvent) {
      auctionContract.events.allEvents({
        fromBlock: 'latest',
      },(error: Error, result: EventData) => {
          if (!error) {
            if (result.event === 'AuctionStart') {
              setAuctionEvent(result);
            } else if (result.event === 'Bid') {
              setAuctionEvent(result);
              if (result.returnValues.bidder === userAccount) {
                setBidData(result);
              }
            } else if (result.event === 'AuctionComplete') {
              setAuctionEvent(result);
              const hasBid = userBids.find((bid: EventData) => {
                return +bid.returnValues.artId === +result.returnValues.artId
              });
              if (result.returnValues.winner === userAccount || hasBid !== undefined) {
                setBidData(result);
              }
            } else if (result.event === 'AuctionScheduled') {
              setAuctionEvent(result);
            }
          } else {
            console.log(error);
          }
      });
    }
    return () => {
      setIsEvent(false)
    }
  }, [allAuctions, dispatch, isEvent, userAccount, userBids])

  useEffect(() => {
    const isNewEvent = prevEvent === undefined || prevEvent.returnValues.artId !== bidData?.returnValues.artId;
    if (hasEventChanged && bidData !== undefined && isNewEvent) {
      if (bidData.event === 'AuctionComplete') {
        dispatch(getUserBidDeletion(Number(bidData.returnValues.artId), userAccount));
      } else {
        dispatch(getUserBid(bidData, userAccount));
      }
    }
  }, [userAccount, bidData, dispatch, hasEventChanged, prevEvent])

  useEffect(() => {
    contract.events.Transfer({
      fromBlock: 'latest',
    },(error: Error, result: EventData) => {
      if (!error) {
        setTransferEvent(result);
      } else {
        console.log(error)
      }
    })
  }, [dispatch])

  useEffect(() => {
    const isNewEvent = prevArtworkTransfered === undefined || 
      prevArtworkTransfered.returnValues.tokenId !== transferEvent?.returnValues.tokenId;

    if (hasArtworkTransfered && transferEvent !== undefined && isNewEvent) {
      dispatch(getNewArtworkTransfer(transferEvent));
    }
  }, [dispatch, hasArtworkTransfered, prevArtworkTransfered, transferEvent])


  useEffect(() => {
    const isUniqueEvent = prevAuctionEvent?.signature !== auctionEvent?.signature ||
      prevAuctionEvent?.event !== auctionEvent?.event ||
      prevAuctionEvent?.transactionHash !== auctionEvent?.transactionHash;
    const isNewEvent = prevAuctionEvent === undefined || isUniqueEvent;

    if (hasAuctionEvent && auctionEvent !== undefined && isNewEvent) {
      if (auctionEvent.event !== 'AuctionScheduled') {
        dispatch(getNewAuctionEventToArtworkById(Number(auctionEvent.returnValues.artId), auctionEvent));
      } else {
        const hasTokenId = allAuctions.some((artwork: any) => 
          artwork.tokenId === +auctionEvent.returnValues.artId);
        if (hasTokenId) {
          dispatch(getNewAuctionEventToArtworkById(Number(auctionEvent.returnValues.artId), auctionEvent));
        } else {
          dispatch(getNewArtworkAuction(Number(auctionEvent.returnValues.artId), auctionEvent));
        }
      }
      
    } 
  }, [allAuctions, auctionEvent, dispatch, hasAuctionEvent, prevAuctionEvent])

  return (
    <>
      <ToastContainer />
      <Header />
      <main className="main">
        <Switch>
          { routerLinks.map((route:any, i) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
          <Route path="/" exact render={Home}/>
          <Route path='*' component={ErrorPage} />
        </Switch>
      </main>
      <Footer/>
    </>
  );
}

export default App;
