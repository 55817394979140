import { useCallback } from "react";
import { TermsSectionInterface } from "./types";

interface Props {
  term: TermsSectionInterface
  activeLink: string,
  className?: string,
}

function SingleTerm({
  term,
  activeLink,
  className,
}: Props) {

  const elRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      if (activeLink === node.id) {
        node.scrollIntoView({behavior: 'smooth'})
      }
    }
  }, [activeLink])

  return (
    <div
      ref={elRef}
      id={term.id}
      className={`terms__single_term ${className}`}
    >
      <h3 className="terms__term_title">{term?.cl && `${term?.cl}.`} {term.section}</h3>
      {!!term.description &&  <div className="terms__term_description">
        {term.description.map((item, i) => {
          return <div className={`paragraf__part`} key={i}>{item}</div>
        })}
      </div>}
      {!!term.subSections && 
        term.subSections.map((subSection, i) => {
          return (
            <SingleTerm
              key={`term_${term.id}_subsection_${i}`}
              term={subSection}
              activeLink={activeLink}
              className="term__subsection"
            />)
        })
      }
    </div>
  )
}

export default SingleTerm;
