import { handleCopyText } from '../../utils/handleCopyText';
import Button from './Button';

interface Props {
  message: string;
}

function CopyButton({ message }: Props) {
  return (
    <Button
      onClick={() => handleCopyText(message)}
      className="button__copy"
    >
      Copy
    </Button>
  )
} 

export default CopyButton;