import { Redirect } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import AccountHeader from '../../components/AccountHeader/AccountHeader';
import './accountContainer.sass';
import AccountUserArts from '../../components/AccountUserArts/AccountUserArts'; 
import { selectUserIsCreator, selectUserIsTrader } from '../../store/features/userData/userDataSlice';
import { useAppSelector } from '../../store/hooks';

function AccountContainer() {
  const { active } = useWeb3React<Web3Provider>();
  const isCreator = useAppSelector(selectUserIsCreator);
  const isTrader = useAppSelector(selectUserIsTrader);

  return (
    <section className="container">
      {!active && <Redirect from='/account' to='/' />}
      <AccountHeader
        isUserTrader={isTrader}
        isUserCreator={isCreator}
      />
      <AccountUserArts
        isUserTrader={isTrader}
        isUserCreator={isCreator}
      />
    </section>
  )
}

export default AccountContainer;
