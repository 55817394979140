import { auctionContract } from "../../../network/service/web3";
import { EventData } from "web3-eth-contract";

export const fetchAuctionEvents = async (name: string): Promise<EventData[]> => {
  const response = await auctionContract.getPastEvents(name, {
    fromBlock: 0
  });

  const filtered = response.filter((event: EventData ) => {
    return event.event !== "AuctionAcquiredToken" && 
      event.event !== "OwnershipTransferred"
  });

  return filtered.sort((a: EventData, b: EventData) => a.blockNumber > b.blockNumber ? -1 : 1);
}