import makeBlockie from "ethereum-blockies-base64";
import { Link } from "react-router-dom";
import { Artwork } from "../../../network/types";

interface Props {
  currentOwner: string,
  data: Artwork,
  completeInProgress: boolean,
}

function OwnershipInfo (props: Props) {
  const {
    currentOwner,
    data,
    completeInProgress,
  } = props;

  return (
    <div className="single_artwork__ownership_info_container">
      <div className="single_artwork__ownership creator">
        <div className="ownership_info">
          <p className="ownership_info__status">Creator</p>
          <div className="ownership_info__name">
            {data?.data.author}
          </div>
        </div>
      </div>
      <div className="single_artwork__ownership owner">
        { currentOwner && !completeInProgress ?
          <img
            className="ownership__account_img" 
            src={makeBlockie(`${currentOwner}`)} 
            alt="user account"
          />
        : 
          <div className="skeleton"></div>
        }
        <div className="ownership_info">
          <p className="ownership_info__status">Owner</p>
          <div className="ownership_info__name">
            {currentOwner && !completeInProgress ?
              <Link to={`/users/${currentOwner}`}>
                {currentOwner.substring(0,5)}...{currentOwner.substring(currentOwner.length-3)}
              </Link>
            : <span className="skeleton">processing</span>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default OwnershipInfo;