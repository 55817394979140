import PrivacyPolicy from '../../components/PrivacyPolicy/PrivacyPolicy';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount/ScrollToTopOnMount';

function PrivacyPolicyContainer() {

  return (
    <>
      <ScrollToTopOnMount/>
      <section className="container legal_page__container cookies_policy__container">
        <PrivacyPolicy />
      </section>
    </>
  )
}

export default PrivacyPolicyContainer;
