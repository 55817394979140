import { useHistory } from 'react-router-dom';
import './errorPage.sass';
import Button from '../Button/Button'

function UnderConstruction () {
  const history = useHistory<unknown>();

  return (
    <section className="container error_page__container">
      <div className="error_page__404">404</div>
      <h2 className="h2_title error__title">Something went wrong :{'('}</h2>
      <div className="error_page__description">
        but don't worry, we'll take you back
      </div>
      <Button 
        className="button__big"
        onClick={() => history.push("/")}
      >
        Go back home
      </Button>
    </section>
    
  )
}

export default UnderConstruction;