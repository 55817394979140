function SkeletonArtTitles() {
  return (
    <>
      <h3 className="h3_title single_art_card__title skeleton">
      </h3>
      <p className="single_art_card__author skeleton">
      </p>
    </>
  )
}

export default SkeletonArtTitles;