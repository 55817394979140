import { useState, useEffect, useCallback } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { FileIcon } from '../Svg';
import Button from '../Button/Button';
import Close from '../Svg/icons/Close';
import Spinner from '../Spinner/Spinner';
import { showToastFileFormat, showToastFileSize, showToastImageSize } from '../../data/toasts';

interface UploadItemInterface {
  isSelected: boolean, 
  setSelected: React.Dispatch<React.SetStateAction<string>>, 
  setPreviewUrl: React.Dispatch<React.SetStateAction<string>>, 
  setIsSetted: React.Dispatch<React.SetStateAction<boolean>>, 
  selected: File, 
  setHash: React.Dispatch<React.SetStateAction<string>>,
  setIsHashSetted: React.Dispatch<React.SetStateAction<boolean>>,
  url: string, 
  changeHandler: (...args: any) => void, 
  handleDelete: (...args: any) => void,
  docType: string,
  uploading: boolean,
  hashSetted?: boolean, 
}

function UploadItem(props: UploadItemInterface) {
  const [isImage, setIsImage] = useState(false);
  const [size, setSize] = useState(0);
  const [isFileCorrect, setIsFileCorrect] = useState(false);

  const MAX_FILE_SIZE = 52428800; 
  const MEGABYTES = '50MB';
  const MIN_IMG_SIDE_SIZE = 1080;

  const { 
    isSelected, 
    setSelected, 
    setPreviewUrl, 
    setIsSetted, 
    selected, 
    setHash,
    setIsHashSetted,
    url, 
    changeHandler, 
    handleDelete,
    docType,
    uploading,
  } = props;

  const fileFormat = (fileName: string) => {
    const format = fileName.split('.')
    return format[format.length-1]
  }

  const deleteFile = useCallback(() => {
    setSelected('');
    setPreviewUrl('');
		setIsSetted(false);
  },[setSelected, setPreviewUrl, setIsSetted])

  const checkImageDimensions = useCallback((url: string) => {   
    var img = new Image();
    img.src = url;
    let width;
    let height;
    img.addEventListener('load', () => {
      width  = img.naturalWidth  || img.width;
      height = img.naturalHeight || img.height; 
      
      if (width < MIN_IMG_SIDE_SIZE && height < MIN_IMG_SIDE_SIZE ) {
        setIsFileCorrect(false);
        deleteFile();
        showToastImageSize(MIN_IMG_SIDE_SIZE, width, height);
      } else {
        setIsFileCorrect(true);
        setIsImage(true);
      }
    })
  },[deleteFile])

  useEffect(() => {
    if (selected) {
      const formatImage = fileFormat(selected.name) === 'png' ||
          fileFormat(selected.name) === 'jpeg' ||
          fileFormat(selected.name) === 'jpg';
      const image = 'image';
      const formatDoc = fileFormat(selected.name) === 'pdf' ||
          fileFormat(selected.name) === 'doc' ||
          fileFormat(selected.name) === 'docx';
      const document = 'document';
      setSize(selected.size);

      if (formatImage && docType === image) {
        checkImageDimensions(url);
      } else if (formatDoc && docType === document) {
        setIsFileCorrect(true);
        setIsImage(false)
      } else {
        setIsFileCorrect(false);
        showToastFileFormat(docType);
        deleteFile();
      }
    }

  }, [selected, checkImageDimensions, deleteFile, docType, url])

  useEffect(() => {
    if (size > MAX_FILE_SIZE) {
      showToastFileSize(MEGABYTES);
      deleteFile();
    } 
  }, [size, deleteFile]);

  return (
    <>
      <section className="container artwork_upload__container">
        { !isSelected &&
          <>
            <label htmlFor={`file_${docType}`} className={`artwork_upload__loader ${docType}`}>
              <input 
                type="file" 
                id={`file_${docType}`} 
                name={`file_${docType}`} 
                onChange = {(e) => changeHandler(
                  e, 
                  setSelected, 
                  setPreviewUrl, 
                  setIsSetted
                )}
              />
              { docType === 'image' ? 
                  <p className="artwork_upload__title">
                    Browse your Artwork preview here
                  </p>
                :
                  <p className="artwork_upload__title">
                    Browse your PDF or Doc file (less than {MEGABYTES}) confirming ownership here
                  </p>
              }
              <div className="button__big button__browze">Browse</div>
              { docType === 'image' && 
                <p className="artwork_upload__description">Jpeg, PNG, min {MIN_IMG_SIDE_SIZE} pixels on the larger side, less than {MEGABYTES}</p>
              }
            </label>
          </>
        }
        { isSelected &&
          isFileCorrect &&
          <div className="artwork_upload__artwork_data">
            { isImage ?
              <>
                { uploading && 
                  <div className="spinner__holder image__spinner">
                    <Spinner color="#706FF6"/>
                  </div>
                }
                <img src={url} alt="artwork_preview" className="artwork_upload__preview"/>
              </>
            : 
              <>
                <div className="artwork_upload__artwork_document">
                  <FileIcon width="39"/>
                  <p className="artwork_document">{selected.name}</p>
                </div>
                { uploading && 
                  <div className="spinner__holder doc__spinner">
                    <Spinner color="#706FF6"/>
                  </div>
                }
              </>
            }
            { !uploading &&  
              <Button
                className={`button_delete ${isImage && 'button_delete__image'}`}
                onClick={(e: Event) => handleDelete(
                  e, 
                  setSelected, 
                  setPreviewUrl, 
                  setIsSetted,
                  setHash,
                  setIsHashSetted,
                )}
              >
                Delete
                <Close width="11px"/>
              </Button>
            }
          </div>
        }
      </section>
    </>
  );
};

export default UploadItem;