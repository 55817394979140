import makeBlockie from 'ethereum-blockies-base64';
import Button from '../Button/Button';
import './accountHeader.sass';
import { useAppSelector } from '../../store/hooks';
import { selectUserAddress } from '../../store/features/userData/userDataSlice';
import { useHistory } from 'react-router-dom';
import { AccountPropsInterface } from '../../routes/Account/types';
import useAuth from '../../hooks/useAuth';
import AccountLink from './AccountLink';
import { getName } from '../../utils/getUserName';
import { useEffect, useState } from 'react';

function AccountHeader (props: AccountPropsInterface) {
  const { logout } = useAuth();
  const account = useAppSelector(selectUserAddress);
  const {
    userAddress,
    isUserTrader,
    isUserCreator
  } = props;
  const [userName, setUserName] = useState('');

  const history = useHistory<unknown>();

  const handleLogOut = () => {
    logout();
    history.push("/");
  }

  const isLogoLoaded = userAddress || account;
  const address = userAddress ? userAddress : account;

  useEffect(() => {
    if (address) {
      getName(address, setUserName)
    }
  }, [address])
  
  return (
    <>
      { !userAddress &&
        <header className="container account_header__container">
          <h2 className="h2_title">Account</h2>
          { account &&
            <Button
              className="button__logout"
              onClick={handleLogOut}
            >
              Log out
            </Button>
          }
        </header>
      }
      <div className={`account_info__container ${userAddress && 'user__page'}`}>
        <div className="account_info__background"></div>
        <div className="account_info__data">
          {isLogoLoaded && <img 
            className="account_info__user_img" 
            src={makeBlockie(`${userAddress ? userAddress : account}`)} 
            alt="user account"
          />}
          <div className="account_info__user_info">
            <div className="account_info__user_address">
              <h3 className="h3_title account_info__title">
                { userAddress ?
                    <AccountLink
                      address={userAddress}
                    />
                  : account &&
                    <AccountLink
                      address={account}
                    />
                }
              </h3>
              <div className="account_info__user_badge">
                { isUserCreator ? 
                    'Creator' 
                : isUserTrader ? 
                    'Trader' 
                : 
                  'Undefined status'
                }
              </div>
            </div>
            <p className="account_info__user_name">{userName}</p>
          </div>
        </div>
      
      </div>
      
    </>
  )
}

export default AccountHeader;