import { useContext } from 'react';
import Button from '../../Button/Button';
import { ModalContext } from '../../../widgets/Modal/ModalProvider';
import PutOnSaleModal from '../../../widgets/PutOnSaleModal/PutOnSaleModal';
import { PutOnSaleModalData } from '../../../widgets/PutOnSaleModal/config';

declare global {
  interface Window {
    BinanceChain:any;
    ethereum: any;
  }
}

interface ButtonApproveNftInterface {
  tokenId: string | number,
  owner: string,
  className?: string,
  setIsApproveProcessing: React.Dispatch<React.SetStateAction<boolean>>,
  setIsScheduled: React.Dispatch<React.SetStateAction<boolean>>,
  setIsNftApproved: React.Dispatch<React.SetStateAction<boolean>>,
  isNftApproved: boolean,
}

function ButtonApproveNft (props: ButtonApproveNftInterface) {
  const { 
    tokenId,
    owner,
    className,
    setIsApproveProcessing,
    setIsScheduled,
    setIsNftApproved,
    isNftApproved,
  } = props;
  const { handleModalOpen } = useContext(ModalContext);

  return (
      <Button className={`button__big ${className}`}
        onClick={() => handleModalOpen(
          <PutOnSaleModal
            tokenId={tokenId}
            owner={owner}
            setIsApproveProcessing={setIsApproveProcessing}
            setIsScheduled={setIsScheduled}
            setIsNftApproved={setIsNftApproved}
            isNftApproved={isNftApproved}
          />,
          PutOnSaleModalData
        )}
      >
        Put on sale
      </Button>
  )
}

export default ButtonApproveNft;