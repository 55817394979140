export const getName = async (account: string, setUserName: React.Dispatch<React.SetStateAction<string>>) => {
  try {
    const res = await fetch('/creators.json');

    if (!res.ok) {
      throw new Error(`Request failed with status ${res.status}`)
    }
    const result = await res.json()
    setUserName(result.creators[account].organization);
    
  } catch (err) {
    console.log("User didn't set specific name");
    setUserName('');
  }
}
