import { useCallback, useEffect, useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import './accountUserArts.sass';
import Button from '../Button/Button';
import UserAllArts from './UserAllArts';
import UserFilteredArts from './UserFilteredArts';
import UserNoArts from './UserNoArts';
import TabsNavBar, { TabsInterface } from '../Tabs/TabsNavBar';
import { useAppSelector } from '../../store/hooks';
import { getFilteredArtworks } from '../../store/features/allArtworks/allArtworksSlice';
import { selectUserAddress } from '../../store/features/userData/userDataSlice';
import GetInviteModal from '../../widgets/GetInviteModal/GetInviteModal';
import { GetInviteData } from '../../widgets/GetInviteModal/config';
import { ModalContext } from '../../widgets/Modal/ModalProvider';
import { AccountPropsInterface } from '../../routes/Account/types';
import ButtonKYC from '../Button/ButtonKYC';

function AccountUserArts(props: AccountPropsInterface) {
  const account = useAppSelector(selectUserAddress);

  const [selectedTab, setSelectedTab] = useState('all');
  const [noArtworks, setNoArtworks] = useState(false);

  const { handleModalOpen } = useContext(ModalContext);

  const {
    userAddress,
    isUserTrader,
    isUserCreator,
  } = props;

  let userAccount = userAddress ? userAddress : account;

  const getAllUserArtworks = useCallback((state) => 
    getFilteredArtworks(state, userAccount), [userAccount]
  );
  const getNotOnSale = useCallback((state) => 
    getFilteredArtworks(state, userAccount, 'notOnSale'), [userAccount]
  );
  const getOnSale = useCallback((state) => 
    getFilteredArtworks(state, userAccount, 'onSale'), [userAccount]
  );
  const getSold = useCallback((state) => 
    getFilteredArtworks(state, userAccount, 'sold'), [userAccount]
  );

  const userArtworks = useAppSelector(getAllUserArtworks);
  const artworksNotOnSale = useAppSelector(getNotOnSale);
  const artworksOnSale = useAppSelector(getOnSale);
  const artworksSold = useAppSelector(getSold);

  const isTabBarEnabled = isUserCreator || isUserTrader;

  useEffect(() => {
    if (userArtworks.length === 0) {
      setNoArtworks(true)
    }

    return () => setNoArtworks(false)
  }, [userArtworks.length])

  const artworksTabs: TabsInterface = {
    'all': {
      title: 'All',
      component: userArtworks !== undefined && userArtworks.length !== 0 ? 
          <UserAllArts 
            userAccount={userAccount} 
            usersArtworks={userArtworks}
          /> 
        : <UserNoArts
            userAccount={userAccount}
            isTabBarEnabled={isTabBarEnabled}
          /> 
    },
    'onSale': {
      title: 'On sale',
      component: artworksOnSale !== undefined && artworksOnSale.length !== 0 ? 
          <UserFilteredArts onSale={true} artworks={artworksOnSale}/> 
        : <UserNoArts
            userAccount={userAccount}
            isTabBarEnabled={isTabBarEnabled}
          />
    },
    'sold': {
      title: 'Sold',
      component: artworksSold !== undefined && artworksSold.length !== 0 ? 
          <UserFilteredArts sold={true} artworks={artworksSold}/> 
        : <UserNoArts
            userAccount={userAccount}
            isTabBarEnabled={isTabBarEnabled}
          />
    },
    'notOnSale': {
      title: 'Not on sale',
      component: artworksNotOnSale !== undefined && artworksNotOnSale.length !== 0 ? 
          <UserFilteredArts notOnSale={true} artworks={artworksNotOnSale}/> 
        : <UserNoArts
            userAccount={userAccount}
            isTabBarEnabled={isTabBarEnabled}
          /> 
    },
  }
  
  const showUploadButton = account === userAccount && isUserCreator;
  const showKycButton = account === userAccount && !isUserTrader && !isUserCreator;
  const showGetInvite = account === userAccount && isUserTrader && !isUserCreator;
  const userHasArtworks = !noArtworks && isTabBarEnabled
  
  return (
    <>
      <header className="user_artworks__header">
        <nav className={`user_collection__navigation ${!isTabBarEnabled ? 'not_allowed' : '' }`}>
          <h3 className="h3_title user_collection__title">
            Collection
          </h3>
          <TabsNavBar
            selectedTab = {selectedTab}
            setSelectedTab={setSelectedTab}
            tabs={artworksTabs}
          />
        </nav>
        { showUploadButton ?
            <Link to='/upload/step_1'>
              <Button className="button__upload">
                Upload
              </Button>
            </Link>
        : showKycButton ?
            <ButtonKYC
              title="Upload"
              className="button__upload"
            />
          : showGetInvite &&
              <Button
                className="button__upload"
                onClick={() => handleModalOpen(<GetInviteModal />, GetInviteData)}
              >
                Upload
              </Button>
        }
      </header>

      { userHasArtworks ?
          <>{artworksTabs[selectedTab].component}</>
        :
          <UserNoArts
            userAccount={userAccount}
            isTabBarEnabled={isTabBarEnabled}
          />
      }
    </>
  )
};

export default AccountUserArts;
