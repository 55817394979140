import { InjectedConnector } from '@web3-react/injected-connector'
import { BscConnector } from '@binance-chain/bsc-connector'
import { ConnectorNames } from '../widgets/WalletModal/types'
import Web3 from 'web3'

const chains = process.env.REACT_APP_SUPPORTED_CHAINS!.split(',').map(i => +i)

const injected = new InjectedConnector({ supportedChainIds: chains })

const bscConnector = new BscConnector({ supportedChainIds: [1] })

export const connectorsByName: { [connectorName in ConnectorNames]: any } = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.BSC]: bscConnector,
}

export const getLibrary = (provider : any): Web3 => {
  return provider
}
