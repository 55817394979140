import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" width="20" {...props}>
      <rect width="24" height="24" fill="none"/>
      <path d="M8.6955 6.937V8.314H8.529C8.06425 8.314 7.6875 8.69075 7.6875 9.1555C7.6875 9.62025 8.06425 9.997 8.529 9.997H8.6955V14C8.6955 14.5523 9.14321 15 9.6955 15H9.7675C10.3198 15 10.7675 14.5523 10.7675 14V9.997H11.4054C11.8389 9.997 12.1592 10.0168 12.2171 9.58709C12.2857 9.07864 11.9151 8.307 11.4021 8.307H10.7755V7.157C10.7755 6.984 11.0015 6.753 11.2255 6.753H11.477C11.9611 6.753 12.3535 6.36058 12.3535 5.8765C12.3535 5.39242 11.9611 5 11.477 5H10.8185C8.6445 5 8.6955 6.685 8.6955 6.937Z"/>
      <path d="M4 2C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4V16C2 16.5304 2.21071 17.0391 2.58579 17.4142C2.96086 17.7893 3.46957 18 4 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V4C18 3.46957 17.7893 2.96086 17.4142 2.58579C17.0391 2.21071 16.5304 2 16 2H4ZM4 0H16C17.0609 0 18.0783 0.421427 18.8284 1.17157C19.5786 1.92172 20 2.93913 20 4V16C20 17.0609 19.5786 18.0783 18.8284 18.8284C18.0783 19.5786 17.0609 20 16 20H4C2.93913 20 1.92172 19.5786 1.17157 18.8284C0.421427 18.0783 0 17.0609 0 16V4C0 2.93913 0.421427 1.92172 1.17157 1.17157C1.92172 0.421427 2.93913 0 4 0Z"/>
    </Svg>
  );
};

export default Icon;