import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { header, headerLoggedIn, roleCreator, roleTrader } from '../../data/constants';
import AccountLink from './AccountLink';
import JoinUs from '../Button/JoinButton';
import './header.sass';
import { ModalContext } from '../../widgets/Modal/ModalProvider';
import { connectorLocalStorageKey, WalletModalData } from '../../widgets/WalletModal/config';
import { ConnectorNames } from '../../widgets/WalletModal' 
import WalletModal from '../../widgets/WalletModal/WalletModal';
import  { LogoIcon } from '../Svg';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
	getUserBids,
	getUserIsCreator,
	getUserIsTrader,
	selectAllUserBids,
	selectUserAddress,
	setUserAddress
} from '../../store/features/userData/userDataSlice';
import { statusesContract, web3 } from '../../network/service/web3';
import useAuth from '../../hooks/useAuth';
import { useEagerConnect, useInactiveListener } from '../../hooks/hooks';
import { TransactionReceipt } from 'web3-core';
import { AppThunk } from '../../store/store';
import { selectArtworksAuctions } from '../../store/features/auctionEvents/auctionEventsSlice';
import { showToastCustomTextError, showToastCustomTextSuccess } from '../../data/toasts';

function Header () {
	const { active, account, connector } = useWeb3React();
	const [bidsNumber, setBidsNumber] = useState(0);
	const { ethereum, BinanceChain } = window as any;
	const { logout } = useAuth();

	const dispatch = useAppDispatch();
	const allAuctions = useAppSelector(selectArtworksAuctions);
	const userAccount = useAppSelector(selectUserAddress);
	const userBids = useAppSelector(selectAllUserBids);
	const { handleModalOpen } = useContext(ModalContext);

	const showRoleToast = useCallback((
		transactionHash: string,
		message: string,
		setUserStatus: (value: boolean) => AppThunk,
	) => {
		web3.eth.getTransactionReceipt(transactionHash)
			.then((receipt: TransactionReceipt) => {
				if (!!receipt && !!receipt.status) {
					showToastCustomTextSuccess(message);
					dispatch(setUserStatus(true));
				} else if (!!receipt && !!!receipt.status) {
					showToastCustomTextError('An error occurred while status changing')
				}
			})
			.catch(console.log)
	}, [dispatch])

	useEffect(() => {
		const connectorId = window.localStorage.getItem(connectorLocalStorageKey) as ConnectorNames;
		const handleAccountsChanged = (accounts: string[]) => {
			if (accounts.length === 0) {
				logout();
			}
		}

		if (ethereum && connectorId === 'Injected') {
			ethereum.on('accountsChanged', handleAccountsChanged);
		}	else if (BinanceChain && connectorId === 'BSC') {
			BinanceChain.on('accountsChanged', handleAccountsChanged);
		}

		return () => {
			if (ethereum && ethereum.removeListener) {
				ethereum.removeListener('accountsChanged', handleAccountsChanged);
			} else if (BinanceChain && BinanceChain.removeListener) {
				BinanceChain.removeListener('accountsChanged', handleAccountsChanged);
			}
		} 
	}, [ethereum, BinanceChain, account, logout])

	useEffect(() => {
		if (account) {
			dispatch(setUserAddress(account));
			statusesContract.methods.userPermissions(account).call()
				.then((result: any) => {
					dispatch(getUserIsCreator(result.userIsCreator));
					dispatch(getUserIsTrader(result.userIsTrader));
				})
		}
	}, [account, dispatch])

	useEffect(() => {
		if (account && allAuctions.length) {
			dispatch(getUserBids(account))
		}
	}, [allAuctions.length, dispatch, account]);

	useEffect(() => {
		if (userAccount !== '') {
			setBidsNumber(userBids.length)
		}
	}, [userAccount, userBids?.length])

	const triedEager = useEagerConnect();

  useInactiveListener(!triedEager || !!connector);

	useEffect(() => {
    statusesContract.events.RoleGranted({
			filter: { account: account }
		},(error: Error, result: any) => {
			if (!error) {
				if (result.returnValues.role === roleCreator && result.returnValues.account === account) {
					showRoleToast(result.transactionHash, 'You just received new role: Creator.', getUserIsCreator)
				} else if (result.returnValues.role === roleTrader && result.returnValues.account === account) {
					showRoleToast(result.transactionHash, 'You just received new role: Trader.', getUserIsTrader)
				}
			} else {
				console.log(error)
			}
		})
  }, [account, showRoleToast])

	return (
		<header className="container header">
			<Link to="/"><LogoIcon width="101" height="34"/></Link>
			<ul className="header__list">
				{ !active ?
					header.map((item, key) => 
						<li className="header__list_item" key={`header__list_item_${key}`}>
							<NavLink
								to={item.path}
								exact
								className="list_item__link" 
								activeClassName="list_item__link_selected"
								data-text={item.name}
							>
								{item.name}
							</NavLink>
						</li>
					)
				: 
					headerLoggedIn.map((item, key) => 
						<li className="header__list_item" key={`header__list_item_${key}`}>
							<NavLink 
								to={item.path} 
								exact
								className="list_item__link"
								activeClassName="list_item__link_selected"
								data-text={item.name}
							>
								{item.name}
								{ bidsNumber > 0 && 
									item.name === 'Bids' && 
									<span className="list_item__bids">{bidsNumber}</span>
								}
							</NavLink>
						</li>
					)
				}
				{
					<li>
						{ active ? 
							<AccountLink name={account}/> 
						: 
							<JoinUs onClick={()=>{ if (!active) {
								handleModalOpen(<WalletModal />, WalletModalData)
							}}}/>
						}
					</li>
				}
				
			</ul>
		</header>
	)
};

export default Header;
