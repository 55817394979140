import { LegalPreambleI } from "./types";

export function LegalPreamble({preamble}: LegalPreambleI) {
  return (
    <div className="legal__paragraf legal__preamble">
      { preamble.map((item, i) => (
        <div
          key={`legal__preamble_${i}`}
          className="legal__text_item"
        >
          {item}
        </div>
      ))}
    </div>
  )
}
