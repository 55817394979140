import { Redirect } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import './bidsPage.sass';
import ShowGalleryButton from '../../components/Button/ShowGalleryButton';
import { selectAllUserBids } from '../../store/features/userData/userDataSlice';
import { useAppSelector } from '../../store/hooks';
import UsersBids from './UsersBids/UsersBids';

function BidsPage () {
  const { active } = useWeb3React<Web3Provider>();
  const userBids = useAppSelector(selectAllUserBids);
  
  return (
    <>
      {!active && <Redirect from='/bids' to='/' />}
      { userBids?.length > 0 ? 
        <UsersBids userBids={userBids}/>
      :
        <section className="container bids__info_center">
          <h2 className="h2_title bids__title">You have no bids yet</h2>
          <p className="bids__description">Take part in auctions and check your bids in this section</p>
          <ShowGalleryButton/>
        </section>
      }
    </>  
  )
}

export default BidsPage;