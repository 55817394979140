import Timer from "../../Timer/Timer";

interface Props {
  timeIsUp: boolean,
  endTime: number,
  setTimeIsEnded: React.Dispatch<React.SetStateAction<boolean>>,
}

function SingleArtCardPartTimer (props : Props) {
  const {
    timeIsUp,
    endTime,
    setTimeIsEnded,
  } = props;

  return (
    <>
      <span 
        className={`single_art_card__timer_container ${timeIsUp && 'auction__ended'}`}
      >
        <Timer 
          endTime={endTime !== 0 ? endTime : 0}
          setTimeIsEnded={setTimeIsEnded}
        />
      </span>
      {!timeIsUp && <span> Ending in</span>}
    </>
  )
}

export default SingleArtCardPartTimer;