import { ChangeEvent } from 'react';

interface TextChangeInterface {
  e: ChangeEvent<HTMLTextAreaElement>,
  setLetterHeading: React.Dispatch<React.SetStateAction<string>>
}

export const handleLetterTextChange = ({e, setLetterHeading}: TextChangeInterface) => {
  setLetterHeading(e.target.value.replace(/(?:\r\n|\r|\n)/g, '%0D%0A'));
  e.target.style.width = '100%';
  e.target.style.height = 'inherit';
  e.target.style.height = `${e.target.scrollHeight}px`; 
}