import Button from './Button';

interface Props {
  onClick: () => void;
}

function CloseButton({ onClick }: Props) {
  return (
    <Button className="button__connect" onClick={onClick}>
      Connect
    </Button>
  )
}

export default CloseButton;