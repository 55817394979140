import { About } from "../../components/About";
import ScrollToTopOnMount from "../../components/ScrollToTopOnMount/ScrollToTopOnMount";

export default function AboutContainer() {
  return (
    <>
      <ScrollToTopOnMount/>
      <section className="container about__container">
        <About />
      </section>
    </>
  )
}
