import { Redirect, useLocation } from 'react-router-dom';
import AccountHeader from '../../components/AccountHeader/AccountHeader';
import '../Account/accountContainer.sass';
import AccountUserArts from '../../components/AccountUserArts/AccountUserArts'; 
import { useAppSelector } from '../../store/hooks';
import { selectUserAddress } from '../../store/features/userData/userDataSlice';
import { useEffect, useState } from 'react';
import { statusesContract } from '../../network/service/web3';

function UsersContainer() {
  const account = useAppSelector(selectUserAddress);
  let location = useLocation();
  const userAddress = location.pathname.split('/').pop();

  const [isUserTrader, setIsUserTrader] = useState(false);
  const [isUserCreator, setIsUserCreator] = useState(false);

  useEffect(() => {
    statusesContract.methods.userPermissions(userAddress).call()
      .then((result: any) => {
        setIsUserCreator(result.userIsCreator);
        setIsUserTrader(result.userIsTrader);
      })

    return () => {
      setIsUserTrader(false);
      setIsUserCreator(false);
    }
  }, [userAddress])

  return (
    <section className="container">
      {account === userAddress && <Redirect from={location.pathname} to='/account' />}
      <AccountHeader
        userAddress={userAddress}
        isUserTrader={isUserTrader}
        isUserCreator={isUserCreator}
      />
      <AccountUserArts
        userAddress={userAddress}
        isUserTrader={isUserTrader}
        isUserCreator={isUserCreator}
      />
    </section>
  )
}

export default UsersContainer;
