export const fetchMetaData = async (
  metaData: string | number,
  options: {[key: string]: number}
) => {
  const { timeout } = options;
  const controller = new AbortController();
  const timer = setTimeout(() => controller.abort(), timeout);

  try {
    const res = await fetch(`https://ipfs.infura.io/ipfs/${metaData}`,{
      ...options,
      signal: controller.signal
    })
    clearTimeout(timer);

    if (!res.ok) {
      throw new Error(`Request failed with status ${res.status}`);
    }

    const result = await res.json();

    return result
    
  } catch (err: any) {
    console.log(err.message)
  }
}
