import { useContext } from 'react';
import Button from '../../Button/Button';
import { ModalContext } from '../../../widgets/Modal/ModalProvider';
import { erc20Contract } from '../../../network/service/web3';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import { WalletModalData } from '../../../widgets/WalletModal/config';
import WalletModal from '../../../widgets/WalletModal/WalletModal';
import PlaceBidModal from '../../../widgets/PlaceBidModal/PlaceBidModal';
import { PlaceBidModalData } from '../../../widgets/PlaceBidModal/config';
import { WEI } from '../../../data/constants';
import { useAppSelector } from '../../../store/hooks';
import { selectUserAddress, selectUserIsCreator, selectUserIsTrader } from '../../../store/features/userData/userDataSlice';
import { Artwork } from '../../../network/types';
import ButtonKYC from '../../Button/ButtonKYC';

declare global {
  interface Window {
    BinanceChain:any;
    ethereum: any;
  }
}

interface ButtonPlaceABidInterface {
  metaData: Artwork,
  minimumBid: number,
  startPrice: number,
  highestBid: number,
  setIsBidPlaced: React.Dispatch<React.SetStateAction<boolean>>,
  setIsProcessing: React.Dispatch<React.SetStateAction<boolean>>,
  beneficiary: string,
  setBidTransactionHash: any,
}

function ButtonPlaceABid (props: ButtonPlaceABidInterface) {
  const {
    metaData,
    minimumBid,
    startPrice,
    highestBid,
    setIsBidPlaced,
    setIsProcessing,
    beneficiary,
    setBidTransactionHash,
  } = props;
  const { active } = useWeb3React<Web3Provider>();
  const account = useAppSelector(selectUserAddress);
  const isTrader = useAppSelector(selectUserIsTrader);
  const isCreator = useAppSelector(selectUserIsCreator);
  const { handleModalOpen } = useContext(ModalContext);

  const isUserCanPlaceBid = isTrader || isCreator;

  const handlePlaceABid = () => {
    erc20Contract.methods.balanceOf(account).call()
      .then((result: number) => {
        handleModalOpen( 
          <PlaceBidModal
            tokenId={metaData.tokenId}
            isLoaded={metaData ? true : false}
            usdBalance={result/WEI}
            minimumBid={minimumBid}
            startPrice={startPrice}
            highestBid={highestBid}
            setIsBidPlaced={setIsBidPlaced} 
            setIsProcessing={setIsProcessing}
            beneficiary={beneficiary}
            setBidTransactionHash={setBidTransactionHash}      
          />,
          PlaceBidModalData
        )
      });
  }

  return (
    <>
      { active && isUserCanPlaceBid? 
          <Button className="button__big"
            onClick={handlePlaceABid}
          >
            Place a bid
          </Button>
        : active && !isUserCanPlaceBid? 
          <ButtonKYC
            title="Pass the KYC to Place a bid"
            className="button__big"
          />
        :
          <Button className="button__big not_active"
            onClick={() => handleModalOpen(<WalletModal />, WalletModalData)}
          >
            Connect your wallet to Place a bid
          </Button>
      }
      
    </>
  )

}

export default ButtonPlaceABid;
