import { useLocation } from 'react-router-dom';
import { useCallback } from 'react';
import SingleArtwork from '../../components/SingleArtwork/SingleArtwork';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount/ScrollToTopOnMount';
import { useAppSelector } from '../../store/hooks';
import { selectUserAddress } from '../../store/features/userData/userDataSlice';
import { selectArtworkById } from '../../store/features/allArtworks/allArtworksSlice';


function ArtworkContainer() {
  let location = useLocation();
  const artworkAddress = location.pathname.split('/')[location.pathname.split('/').length-1]

  const getArtworkById = useCallback((state) => selectArtworkById(state, Number(artworkAddress)), [artworkAddress])

  const account = useAppSelector(selectUserAddress);
  const data = useAppSelector(getArtworkById);

  return (
    <>
      <ScrollToTopOnMount />
      <section className="container">
        <SingleArtwork data={data} account={account}/>
      </section>
    </>
  )
}

export default ArtworkContainer;