import { etherscanTransactionLink, months } from "../../../data/constants";
import { OpenNewIcon } from "../../Svg";

interface Props {
  time: Date;
  transactionHash: string;
}

function LinkToTransaction({time, transactionHash}: Props) {
  return (
      <a 
        href={`${etherscanTransactionLink}${transactionHash}`}
        target="_blank"
        rel="noreferrer"
      >
        {months[time.getUTCMonth()]} {time.getUTCDate()}, {time.getUTCFullYear()} at {time.getUTCHours()}:{time.getUTCMinutes()<10 ? '0' : ''}{time.getUTCMinutes()} (UTC)
        <OpenNewIcon/>
      </a> 
  )
}

export default LinkToTransaction;