const CURRENCY = 'USDT';

export const walletModalLinkLearnWallets = '/wallets';
export const walletModalLinkPrivacyPolicy = '/policy';
export const walletModalLinkTerms= '/terms';

export const placeBidModalTooltipAllow = `You need to allow us use your ${CURRENCY}`;
export const placeBidModalLinkHow = '/faq#place_a_bid';

export const getInviteModalTooltipName = 'This name will see everyone';
export const getInviteModalTooltipReferrer = 'You can send it us later, but before first Artwork upload';
export const getInviteModalLinkHow = '/faq#status__creator';

export const putOnSaleTooltipInput = 'This will be start price of auction';
export const putOnSaleTooltipApproveNFT = 'You need to allow our auction contract use your NFT';
export const putOnSaleLinkHow = '/faq#putOnSale';
