import { selectUserAddress } from '../../store/features/userData/userDataSlice';
import { useAppSelector } from '../../store/hooks';
import ShowGalleryButton from '../Button/ShowGalleryButton';
import ButtonPassKYC from './ButtonPassKYC';

interface UserNoArtsInterface {
  userAccount: string,
  isTabBarEnabled: boolean,
}

function UserNoArts (props: UserNoArtsInterface) {
  const account = useAppSelector(selectUserAddress);
  const {
    userAccount,
    isTabBarEnabled,
  } = props;
  return (
    <section className="user_artworks__container no_arts">
      <div className="user_artworks__no_arts">
        { account === userAccount && isTabBarEnabled ?
            <>
              <p className="no_arts__plain__description">You have no items yet</p>
              <ShowGalleryButton/>
            </>
          : account === userAccount && !isTabBarEnabled ?
            <ButtonPassKYC />
          :
            <p className="no_arts__plain__description">No collection yet</p>
        }
      </div>
    </section>
  )
}

export default UserNoArts;