import { configureStore, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import auctionEventsReducer from './features/auctionEvents/auctionEventsSlice';
import userDataReducer from './features/userData/userDataSlice';
import nftEventsReducer from './features/nftEvents/nftEventsSlice';
import allArtworksReducer from './features/allArtworks/allArtworksSlice';
import allAccountsReducer from './features/allAccounts/allAccountsSlice';

export const store = configureStore({
  reducer: {
    userData: userDataReducer,
    auctionEvents: auctionEventsReducer,
    nftEvents: nftEventsReducer,
    allArtworks: allArtworksReducer,
    allAccounts: allAccountsReducer,
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false
  })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;