import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 20 20" width="20" {...props}>
      <rect width="24" height="24" fill="none"/>
      <path d="M15 6.94701C14.632 7.10701 14.237 7.21701 13.822 7.26501C14.246 7.01501 14.57 6.61901 14.724 6.14801C14.32 6.38348 13.8791 6.54913 13.42 6.63801C13.2269 6.43558 12.9945 6.27462 12.7372 6.16493C12.4798 6.05524 12.2028 5.99913 11.923 6.00001C10.79 6.00001 9.872 6.90501 9.872 8.02001C9.872 8.17801 9.89 8.33201 9.925 8.48001C9.11233 8.44142 8.31662 8.23385 7.58865 7.87056C6.86068 7.50726 6.21643 6.99621 5.697 6.37001C5.51456 6.67711 5.41851 7.02781 5.419 7.38501C5.419 8.08501 5.782 8.70501 6.332 9.06601C6.00644 9.0558 5.68786 8.96913 5.402 8.81301V8.83801C5.40509 9.30747 5.5708 9.76135 5.87092 10.1224C6.17103 10.4834 6.587 10.7292 7.048 10.818C6.74536 10.898 6.42869 10.9096 6.121 10.852C6.25513 11.2559 6.51167 11.608 6.85502 11.8594C7.19837 12.1108 7.61148 12.2491 8.037 12.255C7.30696 12.8176 6.41064 13.1215 5.489 13.119C5.324 13.119 5.161 13.109 5 13.091C5.94047 13.6863 7.03096 14.0016 8.144 14C11.918 14 13.981 10.922 13.981 8.25201L13.974 7.99001C14.3763 7.70729 14.7239 7.3539 15 6.94701Z" />
      <path d="M4 2C3.46957 2 2.96086 2.21071 2.58579 2.58579C2.21071 2.96086 2 3.46957 2 4V16C2 16.5304 2.21071 17.0391 2.58579 17.4142C2.96086 17.7893 3.46957 18 4 18H16C16.5304 18 17.0391 17.7893 17.4142 17.4142C17.7893 17.0391 18 16.5304 18 16V4C18 3.46957 17.7893 2.96086 17.4142 2.58579C17.0391 2.21071 16.5304 2 16 2H4ZM4 0H16C17.0609 0 18.0783 0.421427 18.8284 1.17157C19.5786 1.92172 20 2.93913 20 4V16C20 17.0609 19.5786 18.0783 18.8284 18.8284C18.0783 19.5786 17.0609 20 16 20H4C2.93913 20 1.92172 19.5786 1.17157 18.8284C0.421427 18.0783 0 17.0609 0 16V4C0 2.93913 0.421427 1.92172 1.17157 1.17157C1.92172 0.421427 2.93913 0 4 0Z"/>
    </Svg>
  );
};

export default Icon;