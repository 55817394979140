import './terms.sass';
import { termsText } from '../../data/terms';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import SingleTerm from './SingleTerm';
import TermNavItem from './TermNavItem';
import { TermsSectionInterface } from './types';

function Terms() {
  const [activeLink, setActiveLink] = useState('');
  const location = useLocation();

  useEffect(() => {
    if (location) {
      setActiveLink(location.hash.replace('#',''))
    }
  }, [location])

  return (
    <div className="legal_page__section_container terms__section_container">
      <header className="legal_page__header terms__header">
        <h1 className="legal_page__heading terms__heading">Terms of service</h1>
        <p className="legal_page__update terms__update">Last update - November 11, 2021</p>
      </header>
      <div className="legal_page__data terms__data">
        <aside className="terms__navigation">
          <ul className="terms__navigation_list">
            { termsText.map((section: TermsSectionInterface, index: number) => {
                return <TermNavItem
                    key={`terms_section_nav_${index}`}
                    section={section}
                    activeLink={activeLink}
                    setActiveLink={setActiveLink}
                  />
            })}
          </ul>
        </aside>
        <div className="terms__sections">
          { termsText.map((section: TermsSectionInterface, index: number) => {
            return (
              <div
                key={`terms_section_${index}`}
                className="terms__single_term_container"
              >
                <SingleTerm
                  key={`terms_section_${index}`}
                  term={section}
                  activeLink={activeLink}
                />
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

export default Terms;
