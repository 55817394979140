import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { EventData } from "web3-eth-contract";
import { getTimeFromBlock } from '../../../../utils/getTimeFromBlock';
import { getTimeFromTimestamp } from '../../../../utils/getTimeFromTimestamp';
import { CURRENCY, months, WEI } from '../../../../data/constants';
import LinkToTransaction from '../../LinkToTransaction/LinkToTransaction';

interface AuctionItemInterface {
  auctionEvent?: EventData,
  beneficiary: string,
  isAuctionFailed?: boolean,
  isAuctionSuccessed?: boolean,
  endTime?: number,
  artworkTitle?: string,
  data?: EventData,
  isProcessing?: boolean,
}

function AuctionItem (props: AuctionItemInterface) {
  const { 
    auctionEvent,
    beneficiary,
    isAuctionFailed,
    isAuctionSuccessed,
    endTime,
    artworkTitle,
    data,
    isProcessing,
  } = props;
  const [time, setTime] = useState<Date>();
  const [address, setAddress] = useState('');
  const [transactionHash, setTransactionHash] = useState('');
  const [isScheduled, setIsScheduled] = useState(false);
  const [isBid, setIsBid] = useState(false);
  const [bidAmount, setBidAmount] = useState(0);
  const [isSold, setIsSold] = useState(false);
  const [isFailure, setIsFailure] =  useState(false);

  useEffect(() => {
    if (auctionEvent !== undefined) {
      getTimeFromBlock(auctionEvent.blockNumber, setTime);
      setTransactionHash(auctionEvent.transactionHash);

      if (auctionEvent.event === 'AuctionScheduled') {
        setIsScheduled(true)
        setAddress(auctionEvent.returnValues.beneficiary)
      } else if (auctionEvent.event === 'Bid') {
        setIsBid(true);
        setAddress(auctionEvent.returnValues.bidder);
        setBidAmount(auctionEvent.returnValues.price/WEI);
      } else if (auctionEvent.event === 'AuctionFailure') {
        setIsFailure(true)
      } else if (auctionEvent.event === 'AuctionComplete') {
        setIsSold(true);
        setBidAmount(auctionEvent.returnValues.price/WEI);
        setAddress(auctionEvent.returnValues.winner);
      }
    } else if (endTime !== undefined) {
      getTimeFromTimestamp(endTime, setTime);
    }

    return () => {
      setAddress('')
    }
  }, [auctionEvent, endTime])

  return (
    <div className="single_artwork__info_tab_item">
      { auctionEvent && 
        <>
          <p className="single_artwork__info_tab_time">
            { time ? 
              <LinkToTransaction
              time={time}
              transactionHash={transactionHash}
              />
            : 
              <span className="skeleton"></span>
            }
          </p>
          <p className="single_artwork__info_tab_address">
            { isScheduled ? 
                <>
                  Auction is scheduled by <Link
                    className="info_tab_item__link"
                    to={`/users/${address}`}>
                    {address.substring(0,5)}...{address.substring(address.length - 3)}
                  </Link>
                </>
              : isBid ?
                <>
                  Bid {bidAmount} {CURRENCY} was placed by <Link
                    className="info_tab_item__link"
                    to={`/users/${address}`}>
                    {address.substring(0,5)}...{address.substring(address.length - 3)}
                  </Link>
                </>
              : isSold ? 
                <>
                  Artwork "{artworkTitle}" was sold for {bidAmount} {CURRENCY} to <Link
                    className="info_tab_item__link"
                    to={`/users/${address}`}>
                    {address.substring(0,5)}...{address.substring(address.length - 3)}
                  </Link>
                </>
              : isFailure &&
                <>
                  The auction is over. There were no bids, the painting remained with the owner <Link
                    className="info_tab_item__link"
                    to={`/users/${beneficiary}`}>
                    {beneficiary.substring(0,5)}...{beneficiary.substring(beneficiary.length - 3)}
                  </Link>
                </>
            }
          </p>
        </>
      }
      { isAuctionFailed ?
        <>
          <p className="single_artwork__info_tab_time">
            { endTime && 
              time ? 
              <>
                {months[time.getUTCMonth()]} {time.getUTCDate()}, {time.getUTCFullYear()} at {time.getUTCHours()}:{time.getUTCMinutes()<10 ? '0' : ''}{time.getUTCMinutes()} (UTC)
              </>
            : 
              <span className="skeleton"></span>
            }
          </p>
          <p className="single_artwork__info_tab_address">
            The auction is over. There were no bids, the painting remained with the owner <Link
              className="info_tab_item__link"
              to={`/users/${beneficiary}`}>
              {beneficiary.substring(0,5)}...{beneficiary.substring(beneficiary.length - 3)}
            </Link>
          </p>
        </>
      : isAuctionSuccessed ?
        <>
          <p className="single_artwork__info_tab_time">
            { endTime && 
              time ? 
              <>
                {months[time.getUTCMonth()]} {time.getUTCDate()}, {time.getUTCFullYear()} at {time.getUTCHours()}:{time.getUTCMinutes()<10 ? '0' : ''}{time.getUTCMinutes()} (UTC)
              </>
            : 
              <span className="skeleton"></span>
            }
          </p>
          <p className="single_artwork__info_tab_address">
            Artwork "{artworkTitle}" was sold for {data?.returnValues.price/WEI} {CURRENCY} to <Link
              className="info_tab_item__link"
              to={`/users/${data?.returnValues.bidder}`}>
              {data?.returnValues.bidder.substring(0,5)}...
                {data?.returnValues.bidder.substring(data?.returnValues.bidder.length - 3)}
            </Link>
          </p>
        </>
      : isProcessing &&
        <>
          <p className="single_artwork__info_tab_time">
            <span className="skeleton"></span>
          </p>
          <p className="single_artwork__info_tab_address">
            <span className="skeleton"></span>
          </p>
        </>
      }
    </div>
  )
}

export default AuctionItem;
