import { useEffect, useState } from 'react';
import Slider from "react-slick";
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import './mainSlider.sass';
import SingleArtCard from '../SingleArtCard/SingleArtCard';
import { Artwork } from '../../network/types';
import SkeletonArtCard from '../SkeletonArtCard/SkeletonArtCard';
import { ArrowBackIcon, ArrowForwardIcon } from '../Svg'
import { useAppSelector } from '../../store/hooks';
import { selectAllArtworks, selectArtworksLoadingStatus } from '../../store/features/allArtworks/allArtworksSlice';
import { selectActiveAuctions, selectScheduledAuctions } from '../../store/features/auctionEvents/auctionEventsSlice';
import { SingleArtworkAuctionsInterface } from '../../store/features/types';

type ArrowProps = {
  className?: string,
  onClick?: () => void,
}

function NextArrow(props: ArrowProps) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    >
      <ArrowForwardIcon width="14px"/>
    </div>
  );
}

function PrevArrow(props: ArrowProps) {
  const { className, onClick } = props;
  return (
    <div
      className={className}
      onClick={onClick}
    > 
      <ArrowBackIcon width="14px"/>
    </div>
  );
}

function MainSlider() {
  const settings = {
    dots: false,
    infinite: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    speed: 1500,
    cssEase: "linear",
    responsive: [{
      breakpoint: 1240,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      }},{
      breakpoint: 850,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
    }}],
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />
  };

  const activeAuctions = useAppSelector(selectActiveAuctions);
  const scheduledAuctions = useAppSelector(selectScheduledAuctions);

  const [isLoaded, setIsLoaded] = useState(false);
  const [lengthDifference, setLengthDifference] = useState(0);
  const [sliderArtworks, setSliderArtworks] = useState(activeAuctions);

  const loadingStatus = useAppSelector(selectArtworksLoadingStatus);
  const allArtworks = useAppSelector(selectAllArtworks);

  useEffect(() => {
    if (allArtworks.length > 0 && loadingStatus === 'loaded') {
      setIsLoaded(true);
    }
    return () => setIsLoaded(false)
  }, [allArtworks, loadingStatus])

  useEffect(() => {
    if (allArtworks.length || activeAuctions.length || scheduledAuctions.length) {
      setLengthDifference(12 - activeAuctions.length);
    }
  }, [activeAuctions.length, allArtworks.length, scheduledAuctions.length])

  useEffect(() => {
    if (activeAuctions.length && activeAuctions.length < 12) {
      setLengthDifference(12 - activeAuctions.length);
    }
  }, [activeAuctions.length])

  useEffect(() => {
    if (lengthDifference) {
      const summ: SingleArtworkAuctionsInterface[] = activeAuctions.concat(scheduledAuctions.slice(0, lengthDifference))
      setSliderArtworks(summ)
    }
  }, [activeAuctions, lengthDifference, scheduledAuctions])

  return (
    <>
      { sliderArtworks?.length > 0 &&
        <section className="container main__slider_container">
          <h2 className="h2_title">HOT ITEMS</h2>
          <div className="slider__container" >
            { isLoaded && 
              sliderArtworks ?
              <Slider {...settings}>
                { sliderArtworks.slice(0, 12).map((artwork: Artwork, i: number) => {
                  let isloaded = Boolean(artwork.data != null)
                  return (
                    <SingleArtCard 
                      tokenId={artwork.tokenId} 
                      isLoaded={isloaded} 
                      key={`${artwork.owner}_${artwork.tokenId}`} 
                    />
                  )
                })}
              </Slider>
              :
              <Slider {...settings}>
                <SkeletonArtCard/>
                <SkeletonArtCard/>
                <SkeletonArtCard/>
              </Slider>
            }
          </div>
        </section>
      }
    </>
  )
};

export default MainSlider;
