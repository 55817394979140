import CookiesPolicy from '../../components/CookiesPolicy/CookiesPolicy';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount/ScrollToTopOnMount';

function CookiesPolicyContainer() {

  return (
    <>
      <ScrollToTopOnMount/>
      <section className="container legal_page__container cookies_policy__container">
        <CookiesPolicy />
      </section>
    </>
  )
}

export default CookiesPolicyContainer;
