import Tooltip from "../../../components/Tooltip/Tooltip";
import { putOnSaleTooltipApproveNFT } from "../../modalTexts";
import ButtonApproveNFT from "../ButtonApproveNFT/ButtonApproveNFT";
import TextInProgress from "../TextInProgress/TextInProgress";

interface Props {
  isApproved: boolean;
  isApproveInProgress: boolean;
  isInputsFilled: boolean;
  tokenId: string | number;
  setIsApproveInProgress: React.Dispatch<React.SetStateAction<boolean>>;
  setIsApproveProcessing: React.Dispatch<React.SetStateAction<boolean>>;
  setIsApproved: React.Dispatch<React.SetStateAction<boolean>>;
  handleStartPrice: () => void;
}

function ButtonApproveNftContaner({
  isApproved,
  isApproveInProgress,
  isInputsFilled,
  tokenId,
  setIsApproveInProgress,
  setIsApproveProcessing,
  setIsApproved,
  handleStartPrice,
}: Props) {
  return (
    <>
      { isApproved &&
        isApproveInProgress ? 
          <TextInProgress/>
        : !isApproved &&
          !isApproveInProgress &&
          <div className="modal_with_artwork__item_with_tooltip">
            <div className="modal_with_artwork__button">
              <ButtonApproveNFT
                isInputsFilled={isInputsFilled}
                tokenId={tokenId}
                setIsApproveProcessing={setIsApproveProcessing}
                setIsApproveInProgress={setIsApproveInProgress}
                setIsApproved={setIsApproved}
                handleStartPrice={handleStartPrice}
              />
            </div>
            <Tooltip
              innerText={putOnSaleTooltipApproveNFT}
              className="modal_with_artwork__tooltip"
            />
          </div>
      }
    </>
  )
}

export default ButtonApproveNftContaner;