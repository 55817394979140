import Button from '../../components/Button/Button';
import { showToastWrongNetwork } from '../../data/toasts';
import { connectorLocalStorageKey } from "./config";
import { Login, Config } from "./types";

interface Props {
  isChecked: boolean;
  handleShowWarning: () => void;
  handleCloseModal: () => void;
  walletConfig: Config;
  login: Login;
  setActivatingConnector: React.Dispatch<any>;
  connector: any;
}

function SingleWalletButton({ 
  isChecked,
  handleShowWarning,
  handleCloseModal, 
  walletConfig,
  login,
  setActivatingConnector,
  connector
}: Props) {

  const handleWalletOpen = async () => {
    try { 
      const chainId = await window.ethereum.request({ method: 'eth_chainId' });
      const isSupported = connector.supportedChainIds.find((chain: number) => chain === Number(chainId))
      if (!isSupported) {
        showToastWrongNetwork();
        window.localStorage.removeItem(connectorLocalStorageKey);
      } else {
        login(walletConfig.connectorId);
        setActivatingConnector(connector);
        window.localStorage.setItem(connectorLocalStorageKey, walletConfig.connectorId);
        handleCloseModal();
      }
    } catch (err) {
      console.log(err)
    }
  }

  const { title } = walletConfig;
  return (
    <Button 
      className={`wallet__button ${isChecked ? 'active' : ''}`}
      onClick={isChecked ? handleWalletOpen : handleShowWarning}
    >
      <p className="wallet__title">
        {title}
      </p>
    </Button>
  )
};

export default SingleWalletButton;
