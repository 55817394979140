import ScrollToTopOnMount from '../../components/ScrollToTopOnMount/ScrollToTopOnMount';
import Terms from '../../components/Terms/Terms';

function TermsContainer() {

  return (
    <>
      <ScrollToTopOnMount/>
      <section className="container legal_page__container terms__container">
        <Terms />
      </section>
    </>
  )
}

export default TermsContainer;
