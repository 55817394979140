import { cookiesText } from '../../data/cookies';
import {
  LegalHeader,
  LegalOrderedList,
  LegalPreamble,
  LegalUnorderedList,
} from '../LegalGenerics';
import '../LegalGenerics/legalGenerics.sass';
import './cookiesPolicy.sass';

function CookiesPolicy() {
  return (
    <div className="legal_page__section_container">
      <LegalHeader
        title={cookiesText.title}
        date={cookiesText.lastUpdate}
      />
      <div className="legal_page__data cookies_policy__data">
        <LegalPreamble
          preamble={cookiesText.preamble}
        />
        <ol className="legal__sections_list">
          { cookiesText.sections.map((section, i) => {
              return (
                <li
                  className="legal__section_item"
                  key={section.sectionId}
                  id={section.sectionId}
                >
                  <h2
                    className="legal__section_item_header"
                  >{section.title}</h2>
                  <div className="legal__section_item_description">
                    { section.description.map((item: any, key: number) => (
                        <div
                          key={`item_description__${key}_${i}`}
                          className="legal__paragraf legal__text_item"
                        >
                          <div className="legal__text_item">{item.paragraf}</div>
                          { item.ol_list &&
                            <LegalOrderedList
                              list={item.ol_list}
                            />
                          }
                          { item.ul_list &&
                            <LegalUnorderedList
                              list={item.ul_list}
                            />
                          }
                        </div>
                      ))
                    }
                  </div>
                </li>
              )
            })
          }
        </ol>
      </div> 
    </div>
  )
}

export default CookiesPolicy;
