import { Link } from "react-router-dom";

export const cookiesText = {
  title: 'Cookies Policy',
  lastUpdate: 'May 1, 2021',
  preamble: [<>
      The <Link className="legal__link" to="/">https://greatmasters.com</Link> (hereinafter the “Platform”) is operated by Starbit Ventures Limited (“We”, “us”, “our”, “Platform”, “Company”), which is a company duly registered and acting under the laws of the British Virgin Islands with its registered address at Craigmuir Chambers, PO Box 71, Road Town, Tortola VG1110, British Virgin Islands. This is our Cookies Policy, which sets out the way we use Cookies on our Platform. We may update this Cookies Policy from time to time to reflect changes in our business or to comply with applicable law and regulations. You should check this page to ensure you understand any changes made.
    </>
  ],
  sections: [{
    sectionId: 'why_cookies_important',
    title: `WHY COOKIES ARE IMPORTANT`,
    description: [{
      paragraf: `Our Platform uses Cookies to identify your device on the Platform, secure your access to the Platform. This helps us provide you with a good experience when you use our Platform and allows us to improve our Platform and the services we provide.`
    }],
  },{
    sectionId: 'what_is_a_cookie',
    title: `WHAT IS A COOKIE?`,
    description: [{
      paragraf: `Cookies are small files that contain information about browsing activity. Whenever you visit a website, the website sends a cookie to the device you're using to access the website. Your device automatically stores the cookie in a file that's located within your web browser. All Cookies have expiration dates in them that determine how long they stay in your browser.`
    },{
      paragraf: `There are two types of Cookies:`,
      ol_list: [{
        list_items: [
          <><b>Session Cookies</b> help identify a particular visit to our Platform during a single browsing session. These are temporary Cookies that expire (and are automatically erased) whenever you close your browser. For example, session Cookies can be used to grant access to content and enable commenting (things you have to login to do).</>
        ]
      },{
        list_items: [ 
          <><b>Persistent Cookies</b> help us recognize you as an existing user. Persistent Cookies usually have an expiration date and stay in your browser until they expire, or until you manually delete them. For example, persistent Cookies can be used for functionality including the “Stay logged-in” tick box, so it is easier to return to the Platform without signing in. We also can use persistent Cookies to better understand usage patterns so that we can improve the Platform for our users.</>
        ]
      }]
    }, {
      paragraf: `We have included more details below to help you understand what kind of Cookies and other technologies we use.`,
    }],
  },{
    sectionId: 'type_of_cookies',
    title: `WHAT TYPES OF COOKIES DO WE USE AND WHY?`,
    description: [{
      paragraf: `We use the following Cookies:`,
      ul_list: [{
        list_items: [
          <><b>Strictly necessary Cookies.</b> These Cookies are required for the operation of our Platform. They include, for example, Cookies that enable you to log into secure areas of our Platform.</>
        ],
      },{
        list_items: [
          <><b>Analytical/performance Cookies.</b> They allow us to recognise and count the number of visitors and see how visitors move around our Platform when they are using it. This helps us improve the way our Platform works, for example, by ensuring that users are finding what they are looking for easily.</>
        ],
      },{
        list_items: [
          <><b>Functionality Cookies.</b> These are used to recognise you when you return to our Platform. This enables us to personalise our content for you, greet you by name and remember your preferences (for example, your choice of language or region).</>
        ],
      },{
        list_items: [
          <><b>Targeting Cookies.</b> These Cookies record your visit to our Platform, the pages you have visited and the links you have followed. We will use this information to make our Platform and the advertising displayed on it more relevant to your interests. We may also share this information with third parties for this purpose.</>
        ],
      }]
    },{
      paragraf: `You can find more information about the individual Cookies we use and the purposes for which we use them below:`,
      ol_list: [{
        title: <b>Flash Cookies</b>,
        list_items: [
          <>We may use Flash Cookies, also known as Local Stored Objects, and similar technologies to personalise and enhance your online experience. A Flash cookie is a small data file placed on a computer using Adobe Flash technology. The Adobe Flash Player is an application that allows rapid development of dynamic content such as video clips and animation.</>,
          <>We use Flash cookies to personalise and enhance your online experience and deliver content for Flash players. We may also use Flash cookies for security purposes, to gather certain website metrics and help remember settings and preferences. Flash cookies are managed through a different interface than the one provided by your web browser. To manage Flash cookies, please visit <a className="legal__link" href="http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager.html" target="_blank" rel="noreferrer">Adobe’s website</a>.</>,
          <>If you disable Flash cookies or other similar technologies, please note that you may not have access to certain content and features of our Platform.</>
        ],
      },{
        title: <b>Pixel Tags and Web Beacons</b>,
        list_items: [
          <>Pixel tags and web beacons are tiny graphic images and small blocks of code placed on website pages, ads, or in our e-mails that allow us to determine whether you performed a specific action. When you access these pages, or when you open an e-mail, the pixel tags and web beacons, let us know you have accessed the web page or opened the e-mail. These tools help us measure the response to our communications and improve our web pages and promotions.</>
        ],
      },{
        title: <b>Server Logs and Other Technologies</b>,
        list_items: [
          <>We collect many different types of information from server logs and other technologies. For example, we collect information about the device you use to access our website, your operating system type, browser type, domain, and other system settings, as well as the language your system uses, the country and time zone where your device is located. Our server logs also record the IP address of the device you use to connect to the Internet. An IP address is a unique identifier that devices require to identify and communicate with each other on the Internet. We may also collect information about the website you were visiting before you came to our website and the website you visit after you leave our site. These tools help us improve users’ experience and deliver our services.</>
        ],
      },{
        title: <b>Device Information</b>,
        list_items: [
          <>We may use device-related information to authenticate users. For example, we may use your IP address, browser information, or other data provided by your browser or device to identify the device being used to access our platform. We may also use these device-related techniques for associating you with different devices that you may use to access our content including for fraud-protection purposes and to better target advertising.</>
        ],
      },]
    }, {
      paragraf: `Note that the Platform has no access to or control over the cookies that are used by third-party websites. The Platform may contain links, content, advertising, or references to other websites by third parties, including advertisers, ad networks and servers, content providers, and application providers. These third parties may use cookies or other tracking technologies to collect information about you when you use the Platform. This Policy does not cover the use of tracking technologies by third parties.`
    }],
  },{
    sectionId: 'how_to_delete_cookies',
    title: `HOW CAN I DELETE OR OPT-OUT OF COOKIES?`,
    description: [{
      paragraf: `At any point, you may choose whether to accept Cookies, and we have explained how you can exercise this right below. However, please note that if you choose to refuse, you may not be able to use the full functionality of our Platform. Most browsers allow you to change your cookie settings. These settings can typically be found in the “options” or “preferences” menu of your browser. To customize your browser’s Cookie setting, please visit the link to your web browser below:`,
    },{
      paragraf: <a className="legal__link" href="https://support.microsoft.com/hub/4338813/windows-help" target="_blank" rel="noreferrer">Cookie settings in Internet Explorer</a>
    },{
      paragraf: <a className="legal__link" href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&redirectslug=Cookies" target="_blank" rel="noreferrer">Cookie settings in Firefox</a>
    },{
      paragraf: <a className="legal__link" href="https://support.google.com/chrome/answer/95647?hl=en" target="_blank" rel="noreferrer">Cookie settings in Chrome</a>
    },{
      paragraf: <><a className="legal__link" href="https://support.apple.com/kb/PH5042?locale=en_GB" target="_blank" rel="noreferrer">Cookie settings in Safari web</a> and <a className="legal__link" href="https://support.apple.com/en-gb/HT201265" target="_blank" rel="noreferrer">iOS</a></>
    },{
      paragraf: <>To opt out of Cookies from Google: <a className="legal__link" href="https://www.google.com/settings/ads" target="_blank" rel="noreferrer">https://www.google.com/settings/ads</a></>
    },{
      paragraf: <>If you want to delete or disable other Cookies, see: <a className="legal__link" href="http://www.allaboutcookies.org" target="_blank" rel="noreferrer">http://www.allaboutcookies.org</a></>
    }]
  },{
    sectionId: 'changes_to_cookies_policy',
    title: `CHANGES TO THIS COOKIES POLICY`,
    description: [{
      paragraf: `We will occasionally update this Cookies Policy to reflect changes in our practices and services. When we post changes to this Cookies Policy, we will revise the “Last Revised” date at the top of this Cookies Policy. If we make any material changes in the way we collect, use, and/or share information held in Cookies, we will notify you by prominently posting notice of the changes on the Platform. We recommend you check this page from time to time to inform yourself of any changes in this Cookies Policy or any of our other policies.`,
    }]
  },{
    sectionId: 'more_info_about_cookies',
    title: `MORE INFORMATION ABOUT COOKIES`,
    description: [{
      paragraf: `The following websites also provide some useful information on how to do this, as well as about your rights under current data protection and privacy regulations.`,
    },{
      paragraf: <>For more information, please go to: <a className="legal__link" href="http://www.youronlinechoices.eu" target="_blank" rel="noreferrer">http://www.youronlinechoices.eu</a> if you are based in the EU, or <a className="legal__link" href="http://www.aboutads.info/choices/" target="_blank" rel="noreferrer">http://www.aboutads.info/choices/</a> if you are based in the US.</>,
    }]
  }]
}
