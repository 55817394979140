import { Switch, Redirect } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import UploadProvider from '../../components/UploadArt/UploadProvider';
import RouteWithSubRoutes from '../RouteWithSubRoutes';
import ScrollToTopOnMount from '../../components/ScrollToTopOnMount/ScrollToTopOnMount';

function UploadContainer ({routes}: any) {
  const { active } = useWeb3React<Web3Provider>();

  return (
    <UploadProvider>
      <ScrollToTopOnMount />
      <section className="container upload__container">
        <Switch>
          {!active && <Redirect from='/upload' to='/' />}
          { routes.map((route:any, i: number) => (
            <RouteWithSubRoutes key={i} {...route} />
          ))}
        </Switch>
      </section>
    </UploadProvider>
  )
}

export default UploadContainer;