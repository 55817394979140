interface IpfsImageInterface {
  metaData: string,
  className: string,
}

function IpfsImage (props: IpfsImageInterface) {

  const {metaData, className} = props;
  
  return (
    <img 
      src={`https://ipfs.infura.io/ipfs/${metaData}`}
      alt="art" 
      className={className}
    />
  );
}

export default IpfsImage;