export async function ipfsUploadFiles (
  ipfs:any, 
  files: File[], 
) {

  const result = [];

  for await (const resultPart of ipfs.addAll(files, {
    pin: true
  })) {
    result.push(resultPart)
  }

  return result
}

export async function ipfsUploadDetails (ipfs: any, file: string) {
  const result = await ipfs.add(file, {
    content: `${file}`,
    pin: true,
  })

  return result
}
