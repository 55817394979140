import { Link } from 'react-router-dom';
import { useCallback, useEffect, useState } from 'react';
import './singleArtCard.sass';
import IpfsImage from '../IpfsImage/IpfsImage';
import SkeletonArtData from '../SkeletonArtCard/SkeletonArtData';
import SkeletonArtImage from '../SkeletonArtCard/SkeletonArtImage';
import SkeletonArtCard from '../SkeletonArtCard/SkeletonArtCard';
import { useAppSelector } from '../../store/hooks';
import { selectArtworksAuctionsByTokenId } from '../../store/features/auctionEvents/auctionEventsSlice';
import { WEI } from '../../data/constants';
import { auctionContractAddress } from '../../network/networkConfig';
import {
  selectArtworkById,
  selectArtworksLoadingStatus
} from '../../store/features/allArtworks/allArtworksSlice';
import SingleArtCardPartDataUserBid from './Parts/SingleArtCardPartDataUserBid';
import { UserBidDataInterface } from '../../routes/Bids/UsersBids/SingleUserBid/SingleUserBid';
import { EventData } from "web3-eth-contract";
import { useGetAuctionDataByTokenId } from '../SingleArtwork/useGetAuctionDataByTokenId';
import SingleArtCardPartTimer from './Parts/SingleArtCardPartTimer';
import SingleArtCardPartDataBid from './Parts/SingleArtCardPartDataBid';
import { auctionContract } from '../../network/service/web3';
import SkeletonArtBidData from '../SkeletonArtCard/SkeletonArtBidData';

interface SingleArtCardInterface {
  tokenId: string | number | null,
  isLoaded?: boolean,
  isBidModal?: boolean,
  userBidData?: UserBidDataInterface,
  notOnSale?: boolean,
}

function SingleArtCard(
  props: SingleArtCardInterface,
) {

  const {
    tokenId,
    isBidModal,
    userBidData,
    notOnSale,
  } = props;

  const getArtworkById = useCallback((state) => selectArtworkById(state, Number(tokenId)), [tokenId])
  const getAuctionById = useCallback((state) => selectArtworksAuctionsByTokenId(state, Number(tokenId)), [tokenId])

  const loadingStatus = useAppSelector(selectArtworksLoadingStatus);
  const data = useAppSelector(getArtworkById);
  const artworkAuctions = useAppSelector(getAuctionById);

  const {
    isScheduled,
    isAuctionActive,
    setIsAuctionActive,
    minimumBid,
    setMinimumBid,
    beneficiary,
    startPrice,
    endTime,
    setEndTime,
    timeIsEnded,
    setTimeIsEnded,
    highestBid,
    setTokenId,
    setGettingData,
  } = useGetAuctionDataByTokenId();

  const [isLoaded, setIsLoaded] = useState(false);
  const [isOnSale, setIsOnSale] = useState(false)
  const [currentOwner, setCurrentOwner] = useState('');
  const [timeIsUp, setTimeIsUp] = useState(false);

  const price = startPrice > highestBid ? startPrice : minimumBid;

  useEffect(() => {
    if (data !== undefined && loadingStatus === 'loaded') {
      setIsLoaded(true);
    }
  }, [data, loadingStatus]);

  useEffect(() => {
    if (data !== undefined) {
      setTokenId(data.tokenId)
    }
  }, [data, setTokenId])

  useEffect(() => {
    if (endTime && (+new Date(endTime*1000) - +new Date()) < 0) {
      setTimeIsUp(true)
    }
    return () => setTimeIsUp(false)
  }, [endTime, timeIsEnded])

  useEffect(() => {
    if (timeIsEnded) {
      setTimeIsUp(true)
    }
  }, [timeIsEnded])

  useEffect(() => {
    if ( artworkAuctions !== undefined &&
      artworkAuctions.auctionsHistory[0].event !== 'AuctionComplete'
    ) {
      setIsOnSale(true)
    }
  }, [artworkAuctions, isOnSale])

  useEffect(() => {
    if (artworkAuctions?.auctionsHistory.length) {
      if (artworkAuctions.auctionsHistory[0].event === 'Bid') {
        setMinimumBid(artworkAuctions.auctionsHistory[0].returnValues.minNextBid/WEI);
      }
    }
  }, [artworkAuctions?.auctionsHistory, artworkAuctions?.auctionsHistory.length, data?.tokenId, setMinimumBid])

  useEffect(() => {
    if (data !== undefined) {
      auctionContract.events.AuctionStart({
        filter: {artId: data.tokenId},
      }, (error: Error, result: EventData) => {
        if (!error && +data.tokenId === +result.returnValues.tokenId) {
          setGettingData(true);
          setIsAuctionActive(true);
          setEndTime(result.returnValues.endTime)
        }
      })
    }
  }, [data, setEndTime, setGettingData, setIsAuctionActive])

  useEffect(() => {
    if (data !== undefined) {
      auctionContract.events.AuctionScheduled({
        filter: {artId: data.tokenId},
      }, (error: Error, result: any) => {
        if (!error && +data.tokenId === +result.returnValues.tokenId) {
          setGettingData(true);
          setMinimumBid(result.returnValues.startPrice/WEI)
        }
      })
    }
  }, [data, setGettingData, setMinimumBid])

  useEffect(() => {
    if (data !== undefined) {
      auctionContract.events.Bid({
        filter: {artId: data.tokenId},
      }, (error: Error, result: any) => {
        if (!error && +data.tokenId === +result.returnValues.tokenId) {
          setGettingData(true);
          setMinimumBid(result.returnValues.minNextBid/WEI)
        }
      })
    }
  }, [data, setGettingData, setMinimumBid])

  useEffect(() => {
    if (data !== undefined) {
      auctionContract.events.AuctionEndTimeChanged({
        filter: {artId: data.tokenId},
      }, (error: Error, result: EventData) => {
        if (!error && +data.tokenId === +result.returnValues.artId) {
          setEndTime(result.returnValues.endTime)
        }
      })
    }
  }, [data, setEndTime])

  useEffect(() => {
    if (isAuctionActive) {
      setCurrentOwner(beneficiary)
    } else if (!isAuctionActive && data?.owner === auctionContractAddress) {
      const owner = artworkAuctions?.auctionsHistory.find((
        event: EventData) =>
          event.event === 'AuctionScheduled')
        .returnValues.beneficiary;
      setCurrentOwner(owner);
    } else {
      setCurrentOwner(data?.owner);
    }
    return () => {
      setCurrentOwner('')
    }

  }, [data, beneficiary, isAuctionActive, artworkAuctions?.auctionsHistory])

  return (
    <>
      { data ?
        <div className="single_art_card__container">
          { isLoaded && data.data ?
            <Link to={`/artworks/${data.tokenId}`}>
              {/* TODO: Расскоментить после того как решат вопрос с превьюхами и удалить img */}
              {/*<IpfsImage metaData={`${data.data.image}`} className="single_art_card__image"/>*/}
              <img
                src="https://greatmasters.com/static/media/bafybeig3khhqtm2vwz2fiq5zf2epeo6iug57nrud625diskq4bblfyzmnq_preview.jpg"
                className="single_art_card__image"
              />
              <div className="single_art_card__image_gradient">
              </div>
              { isOnSale &&
                <div className="single_art_card__timer">
                  { isScheduled &&
                    !isAuctionActive &&
                    !timeIsUp ?
                      <span> Waiting for the first bid</span>
                    : isScheduled &&
                      isAuctionActive &&
                      !timeIsUp ?
                        <SingleArtCardPartTimer
                          timeIsUp={timeIsUp}
                          endTime={endTime}
                          setTimeIsEnded={setTimeIsEnded}
                        />
                    : timeIsUp &&
                      <span>Auction is ended!</span>
                  }
                </div>
              }
            </Link>
          : <SkeletonArtImage/>
          }
          { isLoaded && data.data ?
            <div className="single_art_card__data">
              <div className="single_art_card__info">
                <h4 className="h4_title single_art_card__title">
                  <Link to={`/artworks/${data.tokenId}`}>
                    "{data.data.title}" <i>by {data.data.author}</i>
                  </Link>
                </h4>
                <p className="single_art_card__author">
                  <Link to={`/users/${currentOwner}`} className="single_art_card__author__link">
                    @{currentOwner?.substring(0, 4)}...{currentOwner?.substring(currentOwner?.length - 2)}
                  </Link>
                </p>
              </div>
              { artworkAuctions ?
                <div className="single_art_card__bid_container">
                  { isScheduled &&
                    !timeIsUp &&
                    userBidData === undefined ?
                      <SingleArtCardPartDataBid
                        price={price}
                        startPrice={startPrice}
                        data={data}
                        isBidModal={isBidModal}
                        beneficiary={beneficiary}
                      />
                  : !isScheduled &&
                    timeIsUp &&
                    userBidData === undefined &&
                    notOnSale ?
                    <SingleArtCardPartDataBid
                      price={price}
                      startPrice={startPrice}
                      data={data}
                      isBidModal={isBidModal}
                      notOnSale={notOnSale}
                      beneficiary={beneficiary}
                    />
                  : userBidData?.isActiveUserBid  &&
                    <SingleArtCardPartDataUserBid
                      userBidData={userBidData}
                      isAuctionActive={isAuctionActive}
                      data={data}
                    />
                  }
                </div>
                :
                <SkeletonArtBidData/>
              }
            </div>
          :
            <SkeletonArtData/>
          }
        </div>
      :
        <SkeletonArtCard/>
      }
    </>
  )
}

export default SingleArtCard;
