import { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { ArrowBackIcon } from '../Svg';
import './uploadArt.sass'
import Button from '../Button/Button';
import { ipfsUploadFiles } from '../../ipsf/ipfsUploadFiles';
import useIpfs from '../../ipsf/useIpfs';
import UploadItem from './UploadItem';
import { UploadContext } from './UploadProvider';
import { ipfsInfuraUpload } from '../../ipsf/ipfsInfuraUpload';
import { useAppSelector } from '../../store/hooks';
import { selectUserIsCreator, selectUserIsTrader } from '../../store/features/userData/userDataSlice';
import RefInput from '../RefInput/RefInput';
import { showToastEnterNumber, showToastNotUploaded, showToastUploadedIntoIPFS } from '../../data/toasts';

declare global {
  interface Window {
      BinanceChain:any;
  }
}

const UploadArt = () => {
  const { ipfs } = useIpfs();
  const [uploading, setUploading] = useState(false);

  const {
    isArtSelected, 
    setSelectedArt,
    setArtPreviewUrl,
    setIsArtSetted, 
    selectedArt,
    artUrl,
    artHashSetted,
    setArtHash,
    setArtHashIsSetted,
    isDocSelected,
    setSelectedDoc,
    setDocPreviewUrl,
    setIsDocSetted,
    selectedDoc,
    docUrl,
    docHashSetted,
    setDocHash,
    setDocHashIsSetted,
    cloudLink,
    setCloudLink,
    isCloudLinkAdded,
    setIsCloudLinkAdded,
    changeHandler,
    handleDelete,
  } = useContext(UploadContext);

  const history = useHistory<unknown>();
  
  const handleSubmitAll = (data: File[]) => {
    setUploading(true)
    ipfsUploadFiles(ipfs, data)
    .then(() => ipfsInfuraUpload(
      data,
      setArtHash, 
      setArtHashIsSetted, 
      setDocHash, 
      setDocHashIsSetted
    ))
    .then(() => {
      setUploading(false)
      showToastUploadedIntoIPFS()
    })
    .then(() => handleNextStep())
    .catch(console.log)
	};

  const handleNextStep = () => {
    history.push("/upload/step_2")
  }

  const handleNotUploaded = () => {
    showToastNotUploaded()
  }

  const handleInputValue = (
    e: ChangeEvent<HTMLInputElement>, 
    setAttr: React.Dispatch<React.SetStateAction<string>>,
    setIsSetted: React.Dispatch<React.SetStateAction<boolean>>,
  ) => {
    setAttr(e.target.value);
    setIsSetted(true);
    const value = e.target.value;
    if (value) {
      if (e.target.id === 'price' && isNaN(+value)) {
        e.target.style.borderColor = "#EB4141";
        showToastEnterNumber();
      } else {
        e.target.style.borderColor = '#706FF6';
        e.target.style.backgroundColor = '#fff';
      }
    } else {
      e.target.style.borderColor = '';
      e.target.style.backgroundColor = '';
    }
  }

  const oneNotSetted = !isArtSelected || !isDocSelected || !isCloudLinkAdded;
  const allFilesSetted = isArtSelected && isDocSelected && isCloudLinkAdded;
  const noHashSetted = !artHashSetted || !docHashSetted;

  const isCreator = useAppSelector(selectUserIsCreator);
  const isTrader = useAppSelector(selectUserIsTrader);

  useEffect(() => {
    if (!isCreator && isTrader) { 
      if (ipfs !== null) {
        history.push("/invite");
      }
    } else if (!isCreator && !isTrader) {
      if (ipfs !== null) {
        history.push("/kyc");
      }
    }
  }, [history, ipfs, isCreator, isTrader])

	return (
    <>
      <div className="upload__back_to_account">
        <Link to="/account"><ArrowBackIcon /> Back to account</Link>
      </div>
      <h3 className="h3_title upload_section__title">Upload your item</h3>
      <div className="upload_section__steps">Step 1 of 2</div>
      <RefInput
        type="text" 
        id="cloudLink"
        value={cloudLink}
        placeholder="Enter link to cloud source of your Artwork here"
        isRequired={true}
        className="cloudlink_input"
        onChange={(e) => handleInputValue(e, setCloudLink, setIsCloudLinkAdded)}
        label="Link to the cloud source"
      />
      <UploadItem 
        isSelected = {isArtSelected}
        setSelected = {setSelectedArt}
        setPreviewUrl = {setArtPreviewUrl}
        setIsSetted = {setIsArtSetted} 
        selected = {selectedArt}
        setHash={setArtHash}
        setIsHashSetted={setArtHashIsSetted}
        url = {artUrl}
        hashSetted = {artHashSetted}
        changeHandler = {changeHandler}
        handleDelete = {handleDelete}
        docType="image"
        uploading={uploading}
      />
      <UploadItem 
        isSelected = {isDocSelected}
        setSelected = {setSelectedDoc}
        setPreviewUrl = {setDocPreviewUrl}
        setIsSetted = {setIsDocSetted} 
        selected = {selectedDoc}
        setHash={setDocHash}
        setIsHashSetted={setDocHashIsSetted}
        url = {docUrl}
        hashSetted = {docHashSetted}
        changeHandler = {changeHandler}
        handleDelete = {handleDelete}
        docType="document"
        uploading={uploading}
      />
      <section className="container artwork_upload__container">
        <div className="artwork_upload__buttons">
          { oneNotSetted ?
              <Button 
                className="button__big artwork_upload__button_next" 
                onClick={() => handleNotUploaded()}
              >
                Next step
              </Button> 
            : allFilesSetted && noHashSetted
              ?
                <Button 
                  className="button__big artwork_upload__button_next active" 
                  onClick={() => handleSubmitAll([selectedArt, selectedDoc])}
                >
                  Next step
                </Button>
              : 
                <Button 
                  className="button__big artwork_upload__button_next active" 
                  onClick={() => handleNextStep()}
                >
                  Next step
                </Button>
          }
        </div>
      </section>
    </>
	)
};

export default UploadArt;
