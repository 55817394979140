import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { fetchAuctionEvents } from '../auctionEvents/fetchAuctionEvents';
import { StateStatusInterface, Tokens } from '../types';

const accounts: Tokens = new Map();

export interface AllAccountsInterface extends StateStatusInterface {
  allAccounts: Tokens;
}

const initialState: AllAccountsInterface = {
  allAccounts: [],
  status: 'loaded',
};

export const getAllAccounts = createAsyncThunk(
  'allAccounts/getAllAccounts',
  async () => {
    const response = await fetchAuctionEvents('allEvents');

    for (let event of response) {
      const currentAccount = event.returnValues.beneficiary || event.returnValues.winner
      if (currentAccount !== undefined) { 
        if (!accounts.has(currentAccount)) {
          accounts.set(currentAccount, {
            account: currentAccount,
            artworks: []
          });
          accounts.get(currentAccount).artworks.push(event.returnValues.artId);
        } else {
          accounts.get(currentAccount).artworks.push(event.returnValues.artId);
        }
      }
    }

    return Array.from(accounts.values());
  }
)

export const allAccountsSlice = createSlice({
  name: 'allAccounts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllAccounts.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(getAllAccounts.fulfilled, (state, action) => {
        state.status = 'loaded';
        state.allAccounts = action.payload;
      })
      .addCase(getAllAccounts.rejected, (state) => {
        state.status = 'failed';
      })
  },
});

export const selectAllAccounts = (state: RootState) => state.allAccounts.allAccounts;
export const selectAccountsLoadingStatus = (state: RootState) => state.allAccounts.status;

export default allAccountsSlice.reducer;