import { forwardRef } from 'react';
import { handleInputBlur } from '../../utils/handleInputBlur';
import './input.sass';

interface Props {
  type: string | undefined;
  id: string;
  value?: string | number;
  placeholder: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  isRequired: boolean;
  label: string;
  className?: string;
}

const RefInput = forwardRef<HTMLInputElement, Props>((
  props,
  ref
) => {
  const {
    type,
    id,
    value,
    placeholder,
    onChange,
    isRequired,
    label,
    className
  } = props;

  return (
    <div className="input_item">
      <input
        ref={ref} 
        type={type}
        name={id}
        id={id}
        value={value}
        placeholder={placeholder}
        className={`input ${className}`}
        onChange={onChange}
        onBlur={handleInputBlur}
      />
      <label 
        htmlFor={id}
        className={`${isRequired && "label_required"}`}
      >
        {label}
      </label>
    </div>
  )
})

export default RefInput;