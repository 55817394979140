const moderationContactEmail = 'moderation@greatmasters.com';
const partnersContactEmail = 'partner@greatmasters.com';

export const faqText = [{
  sectionId: 'user_types',
  section: `Status and users`,
  paragrafs: [{
    paragrafId: 'status__undefined_user',
    title: `Undefined user`,
    description: `How to obtain the status: Install Metamask and connect it by Metamask signature.
    Abilities: view the gallery and apply for Trader.`,
  },{
    paragrafId: 'status__trader',
    title: `Trader`,
    description: `How to obtain the status: pass the KYC using instructions in your account (click on “Pass the KYC” button).
    Abilities: buy and resell Items, apply for Creator.`,
  },{
    paragrafId: 'status__agent',
    title: `Agent`,
    description: <>How to obtain the status: be at least a Trader and apply via <a
      className="legal__link"
      href={`mailto:${partnersContactEmail!}`}
      target="_blank"
      rel="noreferrer">
        {partnersContactEmail!}
      </a>.
      <br/>
      Abilities: same as Trader + getting commission on artists who you invited.</>
    ,
  },{
    paragrafId: 'status__creator',
    title: `Creator`,
    description: `How to obtain the status: be at least a Trader and apply using instructions in your account (click on “upload” button).
    Abilities: same as Trader + create your own NFT for sale.`,
  },{
    paragrafId: 'buy_items',
    title: `Who is able to buy Items?`,
    description: `Only Traders and Creators are able to buy Items. To get Trader status you need to go through KYC.`,
  },{
    paragrafId: 'how_verification',
    title: `How does user's verification (KYC) happen?`,
    description: `In your account you can find button “Go through KYC”. By clicking it you’ll get to KYC form. Follow instructions there. After completing the form you will get email about results and Role will be changed to Trader if your data is alright.`,
  },{
    paragrafId: 'confirm_identity',
    title: `Which information do you need to confirm my identity?`,
    description: `We ask for a photo/scan of your document and a selfie with your document.`,
  },{
    paragrafId: 'why_confirm_identity',
    title: `Why do I have to confirm my identity by a document to complete the registration and become a Trader?`,
    description: `This is not necessary for viewing gallery or Artwork’s info but needed to sell/buy/apply for a Creator. These measures are required to ensure safety for other users. This information is not available to any of our employees. The data is essential to solve complex situations and to improve security on the platform.`,
  },{
    paragrafId: 'know_kyc_results',
    title: `How do I know KYC results?`,
    description: `Once you went through KYC we check your form and you get answer via email during 24h. If everything is OK you get Trader status very soon.  All other situations will be executed by the personal manager.`,
  },]
},{
  sectionId:'metamask_and_blockchain',
  section: `Metamask and blockchain`,
  paragrafs: [{
    paragrafId: 'what_browsers_can_I_use',
    title: `What browsers can I use?`,
    description: `Google Chrome or Mozilla Firefox`,
  },{
    paragrafId: 'no_nft_in_my_metamask',
    title: `Why don't I see NFT in My Metamask account?`,
    description: <>
    You need to add token <b>0x0000071e9eB1199a1cEA199363e88B6f34399933</b> to your Metamask account. Check <a href="https://metamask.zendesk.com/hc/en-us/articles/360015489031-How-to-add-unlisted-tokens-custom-tokens-in-MetaMask"
    className="legal__link"
    target="_blank"
    rel="noreferrer"
    >here</a> how to add custom tokens to your account. 
    </>,
  },{
    paragrafId: 'bid_commission',
    title: `Do I pay commission for placing a bid?`,
    description: `You’ll need to pay a gas fee each time you place a bid. It’s required to to successfully conduct a transaction on Ethereum. We don’t receive any of this.`,
  },{
    paragrafId: 'transaction_fail',
    title: `Why did my transaction fail?`,
    description: <ul>
      <li>
        <b>Metamask</b><br/>
        Open Metamask and check if you are connected to Main Etherium Network.<br/>
        Check if you have enough money to buy the Item
      </li>
      <li>
        <b>Double transaction</b><br/>
        Your transaction may fail because someone else has bid the Item right before you.
      </li>
      <li>
        <b>Wrong status</b><br/>
        You can’t buy an Item without confirmed Trader or Creator status. Undefined Users are not allowed to make any purchases. You can’t upload an Item on Market for Sale without Creator status.
      </li>
    </ul>,
  },{
    paragrafId: 'pay_for_creator_status',
    title: `Do I have to pay for an artwork registration in blockchain as Creator?`,
    description: `You don’t have to pay us. You just need to pay a tiny fee for the blockchain transaction — it goes to the blockchain network, not us.`,
  },]
},{
  sectionId:'items_artworks',
  section: `Items (artworks)`,
  paragrafs: [{
    paragrafId: 'what_items_allowed',
    title: `What kind of Items are allowed on the Great Masters?`,
    description: `We appreciate digital versions of the fine art collections.`,
  },{
    paragrafId: 'what_to_upload',
    title: `What should I upload to create a NFT?`,
    description: `You should upload: preview picture for the website, link for a large file (full resolution) on cloud, some documents to prove your ownership.`,
  },{
    paragrafId: 'files_requirements',
    title: `Whether there are any certain requirements to a loaded file?`,
    description: `Yes, the preview file has to be at least 1000px on the larger side, less than 50mb. Jpeg or Png file.`,
  },{
    paragrafId: 'how_send_for_moderation?',
    title: `How do I send my artwork for moderation?`,
    description: <>
      After you fill all the fields and upload files you will be transferred to the page with a text. You need to copy this text and send us an email (to <a
      className="legal__link"
      href={`mailto:${moderationContactEmail!}`}
      target="_blank"
      rel="noreferrer">
        {moderationContactEmail!}
      </a>. This text consists of all the info you just filled including files — they were uploaded to storage and you got hash in the text. 
    </>,
  },{
    paragrafId: 'how_long_does_moderation_takes',
    title: `How long does moderation takes?`,
    description: `About 24h.`,
  },{
    paragrafId: 'upload_modified_image',
    title: `What will happen if I insignificantly modify an image which is already on a platform and upload it again?`,
    description: `Before we register an artwork in blockchain we verify it with special services and this helps us to identify cases like this. If we doubt – we go for manual inspection and can reject uploading.`,
  },{
    paragrafId: 'whare_files_store',
    title: `Where do you keep files?`,
    description: `We store files using a peer-to-peer hypermedia protocol. It is safe and secure.`,
  },]
},{
  sectionId:'trading_and_Auction',
  section: `Trading and Auction`,
  paragrafs: [{
    paragrafId: 'sell_resell_items',
    title: `What's the difference between sale and resale?`,
    description: `The first sale on Great Masters platform is made by an Item’s Creator. Resale can be proceeded an unlimited number of times by the one who bought it (Trader or another Creator).`,
  },{
    paragrafId: 'how_price_formed',
    title: `How is the price of the Item formed?`,
    description: `Item’s minimum price is set by Creator. Every next bid is at least 10% bigger than previous one. Final price is the last bid.`,
  },{
    paragrafId: 'what_currency_should_I_have',
    title: `What currency should I have on my wallet to place a bid?`,
    description: `You need some ETH to pay gas and some USDT to Place a bid.`,
  },{
    paragrafId: 'place_a_bid',
    title: `How do I place a bid?`,
    description: `If you place a bid for the first time on Great Masters you need to approve the USDT contract first (sign Metamask transaction — you’ll have to pay small Transaction fee). It allows our smart contract to hold bid amount during the Auction and get payment and send you NFT if you win the Auction.`,
  },{
    paragrafId: 'i_placed_a_bid_but_it_s_gone',
    title: `I placed a bid, but now it's gone from the Bids tab. Why?`,
    description: `You won the Auction and can find your artwork on the Account page. Or the Auction is over and you’re not the winner.`,
  },{
    paragrafId: 'what_when_auction_over',
    title: `What happens when auction is over?`,
    description: `Seller and winner both can see button “End the Action”  on the Item page. One of them should press in and the winner will get the Item, and the seller will get the money.`,
  },{
    paragrafId: 'what_do_I_get_when_I_win',
    title: `What do i get when win the auction?`,
    description: `You get the NFT. You can view it in Account.`,
  },]
},]
