import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="8" height="16" viewBox="0 0 8 16" {...props}>
      <path d="M0.3548 2.45194L5.06893 8.00746L0.3548 13.563C0.242315 13.6955 0.153086 13.8529 0.0922095 14.0261C0.0313331 14.1993 6.48376e-07 14.385 6.50612e-07 14.5724C6.52847e-07 14.7599 0.0313331 14.9455 0.0922095 15.1187C0.153086 15.2919 0.242315 15.4493 0.3548 15.5819C0.467285 15.7144 0.600825 15.8196 0.747794 15.8913C0.894763 15.9631 1.05228 16 1.21136 16C1.37044 16 1.52796 15.9631 1.67493 15.8913C1.8219 15.8196 1.95544 15.7144 2.06792 15.5819L7.64469 9.00975C7.75732 8.87728 7.84668 8.71994 7.90765 8.54673C7.96862 8.37351 8 8.18783 8 8.0003C8 7.81278 7.96862 7.62709 7.90765 7.45388C7.84668 7.28067 7.75732 7.12332 7.64469 6.99086L2.06792 0.418732C1.95552 0.285996 1.82201 0.180688 1.67503 0.108836C1.52805 0.0369847 1.37049 7.90562e-08 1.21136 8.09537e-08C1.05224 8.28512e-08 0.894676 0.0369847 0.747695 0.108836C0.600715 0.180688 0.467202 0.285996 0.3548 0.418732C-0.106893 0.977148 -0.119042 1.89352 0.3548 2.45194Z" fill="#999999"/>
    </Svg>
  );
};

export default Icon;
