import { LegalListItem } from "./LegalListItem";
import { LegalListI, LegalListItemI } from "./types";

export function LegalOrderedList({
  list,
  className,
}: LegalListI) {
  return (
    <ol className={`ordered_list legal__text_item ${className}`}>
      { list?.map((item: LegalListItemI, i: number) => (
          <LegalListItem
            key={`ordered_list__${i}`}
            {...item}
          />
        ))
      }
    </ol>
  )
}
