import { Link } from "react-router-dom";
import { CURRENCY } from "../../../data/constants";
import { Artwork } from "../../../network/types";
import { selectUserAddress } from "../../../store/features/userData/userDataSlice";
import { useAppSelector } from "../../../store/hooks";
import { getRoundedValue } from "../../../utils/getRoundedValue";
import Button from "../../Button/Button";

interface Props {
  price: number,
  startPrice: number,
  data: Artwork,
  isBidModal: boolean | undefined,
  notOnSale?: boolean,
  beneficiary?: string,
}

function SingleArtCardPartDataBid (props: Props) {
  const {
    price,
    startPrice,
    data,
    isBidModal,
    notOnSale,
    beneficiary,
  } = props;
  
  const account = useAppSelector(selectUserAddress);

  return (
    <>
      <p className="single_art_card__price__container">
        { notOnSale ? '': 
          <>
            <span className="single_art_card__price price__crypto">
              {getRoundedValue(price)} 
            </span>
            <span className="single_art_card__price price__crypto">
              {CURRENCY} 
            </span>
            <span className="single_art_card__current_bid"> {price === startPrice ? 
                'Reserve price' 
                : 'Minimum bid'
              }
            </span>
          </>
        }
      </p>
      <Link to={`/artworks/${data.tokenId}`}>
        { isBidModal ? '' 
          : notOnSale && data.owner === account ?
            <Button className="place_a_bid__button">Put on sale</Button> 
          : notOnSale === undefined && beneficiary === account ? 
            ''
          : notOnSale && data.owner !== account ?
            ''
          :
            <Button className="place_a_bid__button">Place a bid</Button>
        }
      </Link>
    </>
  )
}

export default SingleArtCardPartDataBid;
