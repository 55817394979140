import { useState } from 'react';
import { useAppSelector } from '../../store/hooks';
import {
  selectUserAddress,
  selectUserPublicName,
  selectUserReferrer,
} from '../../store/features/userData/userDataSlice';
import { getInviteTexts } from '../../data/lettersPagesTexts';
import { statusContactEmail } from '../../data/lettersPagesTexts';
import LetterPage from '../LetterPage/LetterPage';

function GetInvited () {
  const account = useAppSelector(selectUserAddress);
  const publicName = useAppSelector(selectUserPublicName);
  const referrer = useAppSelector(selectUserReferrer);

  const [letterHeading, setLetterHeading] = useState(getInviteTexts.letter);

  const messageData = {
    subject: 'Get invite',
    body: {
      heading: `${letterHeading}`,
      message: `%0D%0A%0D%0A----- DO NOT CHANGE TEXT BELOW -----%0D%0A%0D%0A- My account: ${account}%0D%0A- My public name: ${publicName}%0D%0A${referrer === '' ? 'I have no referral' : `- My referral: ${referrer}`}`,
    }
  }

  return (
    <LetterPage
      pageText={getInviteTexts}
      setLetterHeading={setLetterHeading}
      messageData={messageData}
      letterHeading={letterHeading}
      contactEmail={statusContactEmail}
    />
  )
};

export default GetInvited;