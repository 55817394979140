import Button from "./Button";

export type SignMessage = (
  account: string,
  message: string,
  provider?: any,
) => Promise<string>;

interface ButtonPassKYCI {
  title: string,
  className?: string,
}

function ButtonKYC({
  title,
  className,
}: ButtonPassKYCI
) {

  return (
    <a
      href="/kyc"
      target="_blank"
      rel="noreferrer"
    >
      <Button
        className={className}
      >
        {title}
      </Button>
    </a>
  )
}

export default ButtonKYC;
