import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="17" height="14" viewBox="0 0 17 14" {...props}>
      <path d="M1 6.1998C0.447715 6.1998 0 6.64752 0 7.1998C0 7.75209 0.447715 8.1998 1 8.1998V6.1998ZM15 7.1998L15.7071 7.90691L16.4142 7.1998L15.7071 6.4927L15 7.1998ZM10.3071 1.0927C9.91658 0.702174 9.28342 0.702174 8.89289 1.0927C8.50237 1.48322 8.50237 2.11639 8.89289 2.50691L10.3071 1.0927ZM8.89289 11.8927C8.50237 12.2832 8.50237 12.9164 8.89289 13.3069C9.28342 13.6974 9.91658 13.6974 10.3071 13.3069L8.89289 11.8927ZM1 8.1998H15V6.1998H1V8.1998ZM8.89289 2.50691L14.2929 7.90691L15.7071 6.4927L10.3071 1.0927L8.89289 2.50691ZM14.2929 6.4927L8.89289 11.8927L10.3071 13.3069L15.7071 7.90691L14.2929 6.4927Z"/>
    </Svg>
  );
};

export default Icon;
