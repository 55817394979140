import { useState } from 'react';

export function useUpload () {
  const [selectedArt, setSelectedArt] = useState<any>('');
	const [isArtSelected, setIsArtSetted] = useState(false);
  const [artUrl, setArtPreviewUrl] = useState<string>('');
  const [artHash, setArtHash] = useState<string>('');
  const [artHashSetted, setArtHashIsSetted] = useState(false);
  const [cloudLink, setCloudLink] = useState<string>('');
  const [isCloudLinkAdded, setIsCloudLinkAdded] = useState(false);

  const [selectedDoc, setSelectedDoc] = useState<any>('');
  const [isDocSelected, setIsDocSetted] = useState(false);
  const [docUrl, setDocPreviewUrl] = useState<string>('');
  const [docHash, setDocHash] = useState<string>('');
  const [docHashSetted, setDocHashIsSetted] = useState(false);

  const [signedMessage, setSignedMessage] = useState<string>();

  const [detailsHash, setDetailsHash] = useState<any>();
  const [artworkPrice, setArtworkPrice] = useState<string>('');

  const changeHandler = (event:any, setSelected: any, setPreviewUrl: any, setIsSetted: any) => {
		setSelected(event.target.files[0]);
    setPreviewUrl(URL.createObjectURL(event.target.files[0]))
		setIsSetted(true);
	};

  const handleDelete = (
    event: any, 
    setSelected: any, 
    setPreviewUrl: any, 
    setIsSetted: any, 
    setHash: any,
    setIsHashSetted: any
  ) => {
		setSelected('');
    setPreviewUrl('')
		setIsSetted(false);
    setHash('');
    setIsHashSetted(false);
  }

  const handleDeleteAll = (event:any) => {
    setSelectedArt('');
    setArtPreviewUrl('');
    setArtHash('');
    setSelectedDoc('');
    setDocPreviewUrl('');
    setDocHash('');
    setIsArtSetted(false);
    setIsDocSetted(false);
    setArtHashIsSetted(false);
    setDocHashIsSetted(false);
    setSignedMessage('');
  }

  return {
    changeHandler,
    handleDelete,
    handleDeleteAll,
    isArtSelected, 
    setSelectedArt,
    setArtPreviewUrl,
    setIsArtSetted, 
    selectedArt,
    artUrl,
    artHashSetted,
    artHash,
    setArtHash,
    setArtHashIsSetted,
    isDocSelected,
    setSelectedDoc,
    setDocPreviewUrl,
    setIsDocSetted,
    selectedDoc,
    docUrl,
    docHashSetted,
    setDocHash,
    docHash,
    setDocHashIsSetted,
    cloudLink,
    setCloudLink,
    isCloudLinkAdded,
    setIsCloudLinkAdded,
    signedMessage,
    setSignedMessage,
    detailsHash,
    setDetailsHash,
    artworkPrice, 
    setArtworkPrice,
  }
}