import { LegalHeaderI } from "./types";

export function LegalHeader({
  title,
  date,
} : LegalHeaderI) {
  return (
    <header className="legal_page__header">
      <h1 className="legal_page__heading">{title}</h1>
      {!!date && <p className="legal_page__update">Last update - {date}</p>}
    </header>
  )
}
