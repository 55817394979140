import { etherscanLink } from "../../data/constants";

interface Props {
  address: string,
}

export default function AccountLink({ address }: Props) {
  return (
    <a 
      className="h3_title account_info__title"
      target="_blank"
      rel="noreferrer"
      href={`${etherscanLink}${address}`}>
        {address.substring(0, 5)}...{address.substring(address.length - 3)}
    </a>
)
}