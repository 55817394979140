import { Link } from 'react-router-dom';
import { useWeb3React } from '@web3-react/core';
import makeBlockie from 'ethereum-blockies-base64';

interface AccountLinkInterface {
  name: string | null | undefined,
}

export default function AccountLink({name}: AccountLinkInterface) {
  const { active } = useWeb3React();

  return (
    <Link to="/account" className="account__link">
      <span className="header__account_title">{active && `${name?.substring(0,5)}...${name?.substring(name?.length - 3)}`}</span>
      <img 
        className="header__account_img" 
        src={makeBlockie(`${name}`)} 
        alt="user account"
      />
    </Link>
  )
}


