import Button from '../../components/Button/Button';
import { ResizeCloseIcon, ResizeIcon } from '../../components/Svg';


interface ResizeImageButtonInterface {
  onClick: () => void,
  isOpen?: boolean,
}

function ResizeImageButton ({ 
  onClick,
  isOpen 
}: ResizeImageButtonInterface) {
  return (
    <Button 
      className="single_artwork__image_button_open"
      onClick={onClick}
    >
      {isOpen ? <ResizeCloseIcon width="24px"/> : <ResizeIcon width="24px"/>}
    </Button>
  )
}

export default ResizeImageButton;
