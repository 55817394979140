import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 25 25" {...props}>
      <path d="M21.6663 11C21.8432 11 22.0127 10.9298 22.1377 10.8047C22.2628 10.6797 22.333 10.5101 22.333 10.3333C22.333 10.1565 22.2628 9.98695 22.1377 9.86193C22.0127 9.7369 21.8432 9.66667 21.6663 9.66667L15.273 9.66667L21.4397 3.5C21.5095 3.44023 21.5661 3.36669 21.6062 3.28398C21.6462 3.20127 21.6687 3.11118 21.6722 3.01937C21.6758 2.92755 21.6603 2.836 21.6268 2.75045C21.5932 2.6649 21.5424 2.5872 21.4774 2.52223C21.4125 2.45726 21.3348 2.40642 21.2492 2.37291C21.1637 2.33939 21.0721 2.32392 20.9803 2.32747C20.8885 2.33101 20.7984 2.3535 20.7157 2.39351C20.633 2.43353 20.5594 2.49021 20.4997 2.56L14.333 8.72667L14.333 2.33333C14.333 2.15652 14.2628 1.98695 14.1377 1.86193C14.0127 1.7369 13.8431 1.66666 13.6663 1.66666C13.4895 1.66666 13.32 1.7369 13.1949 1.86193C13.0699 1.98695 12.9997 2.15652 12.9997 2.33333L12.9997 10C12.9997 10.5523 13.4474 11 13.9997 11L21.6663 11Z" fill="white"/>
      <path d="M11 21.6668C11 21.8436 10.9298 22.0132 10.8047 22.1382C10.6797 22.2633 10.5101 22.3335 10.3333 22.3335C10.1565 22.3335 9.98695 22.2633 9.86193 22.1382C9.7369 22.0132 9.66667 21.8436 9.66667 21.6668L9.66667 15.2735L3.5 21.4402C3.44024 21.5099 3.36669 21.5666 3.28398 21.6066C3.20127 21.6467 3.11118 21.6691 3.01937 21.6727C2.92756 21.6762 2.836 21.6608 2.75045 21.6273C2.6649 21.5937 2.5872 21.5429 2.52223 21.4779C2.45726 21.413 2.40642 21.3353 2.37291 21.2497C2.33939 21.1642 2.32392 21.0726 2.32747 20.9808C2.33101 20.889 2.3535 20.7989 2.39352 20.7162C2.43353 20.6335 2.49021 20.5599 2.56 20.5002L8.72667 14.3335L2.33333 14.3335C2.15652 14.3335 1.98695 14.2633 1.86193 14.1382C1.7369 14.0132 1.66667 13.8436 1.66667 13.6668C1.66667 13.49 1.7369 13.3204 1.86193 13.1954C1.98695 13.0704 2.15652 13.0002 2.33333 13.0002L10 13.0002C10.5523 13.0002 11 13.4479 11 14.0002L11 21.6668Z" fill="white"/>
    </Svg>
  );
};

export default Icon;
