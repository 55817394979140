import React from 'react';
import { privacyText } from '../../data/privacy';
import { LegalHeader, LegalOrderedList, LegalPreamble, LegalUnorderedList } from '../LegalGenerics';
import './privacyPolicy.sass';

function PrivacyPolicy() {
  return (
    <div className="legal_page__section_container">
      <LegalHeader
        title={privacyText.title}
        date={privacyText.lastUpdate}
      />
      <div className="legal_page__data privacy_policy__data">
        <LegalPreamble
          preamble={privacyText.preamble}
        />
        <ol className="legal__sections_list">
          { privacyText.sections.map((section, i: number) => {
              return (
                <li
                  className="legal__section_item"
                  key={section.sectionId}
                  id={section.sectionId}
                >
                  <h2
                    className="legal__section_item_header"
                  >{section.title}</h2>
                  <div className="legal__section_item_description">
                    { section.description?.map((item: any, key: number) => (
                        <div
                          key={`item_description__${key}_${i}`}
                          className="legal__paragraf legal__text_item"
                        >
                          <div className="legal__text_item">{item.paragraf}</div>
                          { item.ol_list &&
                            <LegalOrderedList
                              list={item.ol_list}
                            />
                          }
                          { item.ul_list &&
                            <LegalUnorderedList
                              list={item.ul_list}
                            />
                          }
                        </div>
                      ))
                    }
                    { section.subSections?.map((subSection: any, j: number) => (
                        <div 
                          key={`${i}.${j}_subsection_container`}
                          className="legal__text_item privacy__subsection_container"
                        >
                          <div className="legal__text_item privacy__subsection_numbering">
                            {i+1}.{j+1}
                          </div>
                          { !!subSection.heading &&
                            <h3 className="legal__text_item privacy__subsection_heading">{subSection.heading}</h3>
                          }
                          { subSection.subSection?.map((article: any, k: number) => (
                              article.article.map((item: any, key: number) => (
                                <React.Fragment key={`${i}.${j}.${k}.${key}`}>
                                  <div
                                    className="legal__text_item">
                                      {item.paragraf}
                                  </div>
                                  { !!item.ul_list && 
                                    <LegalUnorderedList
                                      list={item.ul_list}
                                    />
                                  }
                                  { !!item.subArticles && 
                                    item.subArticles.map((subArticle: any) => (
                                      subArticle.subArticle.map((paragraf: any, m: number) => (
                                        <div
                                          key={`${i+1}.${j+1}.${m+1}`}
                                          className="legal__text_item privacy__subsection_subarticle"
                                        >
                                          <div className="legal__text_item privacy__subsection_numbering">
                                            {i+1}.{j+1}.{m+1}
                                          </div>
                                          <div className="legal__text_item">{paragraf.paragraf}</div>
                                          { !!paragraf.ol_list &&
                                            <LegalOrderedList
                                              list={paragraf.ol_list}
                                            />
                                          }
                                        </div>
                                      ))
                                    ))
                                  }
                                </React.Fragment>
                              ))
                            ))
                          }
                        </div>
                      ))
                    }
                  </div> 
                </li>
              )
            })
          }
        </ol>
      </div> 
    </div>
  )
}

export default PrivacyPolicy;
