import SingleArtCard from '../SingleArtCard/SingleArtCard';
import { Artwork } from '../../network/types';
import { auctionContractAddress } from '../../network/networkConfig';
import { ZERO_ADDRESS } from '../../data/constants';

interface UserAllArtsInterface {
  usersArtworks: Artwork[],
  userAccount: string,
}

function UserAllArts ({usersArtworks, userAccount}: UserAllArtsInterface) {

  return (
    <section className="user_artworks__container">
      { usersArtworks.map((item: Artwork, i:number) => {
          let isloaded = Boolean(item.data != null)
          const isFirstSale = item.history[0].returnValues.to === auctionContractAddress &&
            item.history[0].returnValues.from === ZERO_ADDRESS;
          const isSecondarySale = item.history[0].returnValues.to === auctionContractAddress &&
            item.history[0].returnValues.from === userAccount;

          const wasOwner = item.history.find((item: any) => item.returnValues.from === userAccount)

          const isWasOwner = item.history[0].returnValues.to === auctionContractAddress &&
          item.history[0].returnValues.from !== userAccount && wasOwner !== undefined;

          const sold = item.owner !== userAccount &&
            (item.owner !== auctionContractAddress || 
              item.history[0].returnValues.from === auctionContractAddress ||
              isWasOwner
            );

          const onSale = item.history[0] !== undefined && (isFirstSale || isSecondarySale);

          const notOnSale = item.owner === userAccount;

          return (
            <div 
              className={`user_artworks__art ${
                sold ? 'art__sold' 
                : onSale ? 'art__on_sale'
                : notOnSale ? 'art__not_on_sale' 
                : ''
              }`}
              key={`${item.owner}_${item.tokenId}_${i}`} 
            >
              <SingleArtCard 
                tokenId={item.tokenId} 
                isLoaded={isloaded} 
                notOnSale={notOnSale}
              />
            </div>
          )
        })
      }
    </section>
  )
}

export default UserAllArts;