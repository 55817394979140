import { EventData } from "web3-eth-contract";
import SingleUserBid from "./SingleUserBid/SingleUserBid";

interface UsersBidsInterface {
  userBids: EventData[]
}

function UsersBids (props: UsersBidsInterface) {
  const { userBids } = props;

  return (
    <section className="container user_bids__section">
      <h2 className="h2_title all_arts__title">
        My bids
      </h2>
      <div className="container user_bids__container">
        { userBids.map((bid: EventData, index: number) => {
          return <SingleUserBid 
            data={bid}
            key={`user_bid_${index}`}
          />
        })}
      </div>
    </section>
  )
}

export default UsersBids;