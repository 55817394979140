import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg viewBox="0 0 101 34" {...props}>
      <path d="M15.1236 8.34943V8.93498C15.1236 10.829 14.4595 12.4266 13.1314 13.7278C11.8032 15.0145 10.0538 15.6579 7.88311 15.6579C5.59815 15.6579 3.71306 14.9061 2.22783 13.4025C0.742611 11.8844 0 10.0265 0 7.82895C0 5.63135 0.735471 3.78074 2.20641 2.27712C3.67736 0.759039 5.54102 0 7.79742 0C9.1684 0 10.4251 0.332531 11.5676 0.997594C12.7244 1.66266 13.6169 2.55182 14.2453 3.66507L13.2385 4.27231C12.7386 3.31809 11.996 2.55182 11.0106 1.9735C10.0395 1.39519 8.96846 1.10603 7.79742 1.10603C5.85521 1.10603 4.25573 1.75663 2.99901 3.05784C1.75656 4.3446 1.13534 5.93496 1.13534 7.82895C1.13534 9.72293 1.7637 11.3205 3.02043 12.6217C4.27716 13.9085 5.89805 14.5519 7.88311 14.5519C9.63967 14.5519 11.0606 14.0675 12.146 13.0988C13.2313 12.1302 13.8383 10.9085 13.9668 9.43377H7.60463V8.34943H15.1236Z" fill="#0E1039"/>
      <path d="M27.4372 15.4193L23.8384 9.19522H19.1043V15.4193H17.9689V0.238555H23.8813C25.0952 0.238555 26.1377 0.67952 27.0088 1.56145C27.88 2.44338 28.3155 3.49881 28.3155 4.72773C28.3155 5.75424 28.0013 6.66509 27.373 7.46027C26.7589 8.25546 25.9734 8.78317 25.0166 9.04341L28.744 15.4193H27.4372ZM19.1043 1.3229V8.11088H23.8813C24.7953 8.11088 25.5736 7.78557 26.2162 7.13497C26.8589 6.4699 27.1802 5.66749 27.1802 4.72773C27.1802 3.78797 26.8589 2.98555 26.2162 2.32049C25.5736 1.65543 24.7953 1.3229 23.8813 1.3229H19.1043Z" fill="#0E1039"/>
      <path d="M32.2417 14.335H39.7392V15.4193H31.1063V0.238555H39.6321V1.3229H32.2417V7.22172H39.0966V8.30606H32.2417V14.335Z" fill="#0E1039"/>
      <path d="M52.8271 15.4193L51.3704 11.5591H43.8515L42.3948 15.4193H41.1952L47.0218 0.238555H48.2214L54.0267 15.4193H52.8271ZM44.2799 10.4747H50.9634L47.6216 1.6482L44.2799 10.4747Z" fill="#0E1039"/>
      <path d="M64.354 0.238555V1.3229H59.6413V15.4193H58.4845V1.3229H53.7718V0.238555H64.354Z" fill="#0E1039"/>
      <path d="M26.5396 18.579V33.6106H25.4027V20.2539L19.8468 29.4876H19.6538L14.0979 20.2539V33.6106H12.9609V18.579H14.3767L19.7396 27.4691L25.1024 18.579H26.5396Z" fill="#0E1039"/>
      <path d="M40.368 33.6106L38.9093 29.7882H31.3799L29.9212 33.6106H28.72L34.5547 18.579H35.756L41.5693 33.6106H40.368ZM31.8089 28.7146H38.5018L35.1554 19.9748L31.8089 28.7146Z" fill="#0E1039"/>
      <path d="M47.9269 33.8468C46.5397 33.8468 45.3599 33.5318 44.3875 32.9019C43.4293 32.2577 42.7786 31.3916 42.4354 30.3036L43.4222 29.7238C43.6939 30.6544 44.2087 31.3916 44.9667 31.9356C45.7389 32.4796 46.7257 32.7516 47.9269 32.7516C49.1425 32.7516 50.0721 32.4725 50.7156 31.9141C51.3735 31.3558 51.7024 30.6329 51.7024 29.7453C51.7024 28.915 51.3878 28.2779 50.7585 27.8341C50.1293 27.3904 49.1497 26.9537 47.8197 26.5242C47.3335 26.3668 46.9759 26.2522 46.7471 26.1807C46.5326 26.0948 46.218 25.9731 45.8033 25.8156C45.3885 25.6438 45.0811 25.4935 44.8808 25.3647C44.6806 25.2358 44.4375 25.064 44.1515 24.8493C43.8798 24.6346 43.6796 24.4198 43.5509 24.2051C43.4222 23.976 43.3078 23.7112 43.2076 23.4106C43.1075 23.0956 43.0575 22.752 43.0575 22.3798C43.0575 21.163 43.4865 20.1895 44.3446 19.4594C45.2169 18.715 46.3038 18.3428 47.6052 18.3428C48.7778 18.3428 49.7789 18.6362 50.6084 19.2232C51.4378 19.8101 52.0456 20.5689 52.4317 21.4994L51.4664 22.0362C50.7514 20.304 49.4643 19.4379 47.6052 19.4379C46.5755 19.4379 45.7532 19.7099 45.1383 20.2539C44.5233 20.7979 44.2159 21.4922 44.2159 22.3369C44.2159 23.1529 44.5019 23.7613 45.0739 24.1621C45.6459 24.563 46.5827 24.9781 47.884 25.4076C48.7135 25.6796 49.357 25.9158 49.8147 26.1162C50.2866 26.3024 50.7871 26.5672 51.3162 26.9108C51.8597 27.24 52.253 27.6409 52.4961 28.1133C52.7392 28.5714 52.8607 29.1082 52.8607 29.7238C52.8607 30.9693 52.4103 31.9714 51.5093 32.7301C50.6084 33.4746 49.4142 33.8468 47.9269 33.8468Z" fill="#0E1039"/>
      <path d="M64.3335 18.579V19.6527H59.6142V33.6106H58.4559V19.6527H53.7366V18.579H64.3335Z" fill="#0E1039"/>
      <path d="M67.8309 32.5369H75.3389V33.6106H66.694V18.579H75.2316V19.6527H67.8309V25.4935H74.6953V26.5672H67.8309V32.5369Z" fill="#0E1039"/>
      <path d="M87.6134 33.6106L84.0096 27.4476H79.2688V33.6106H78.1319V18.579H84.0525C85.2681 18.579 86.312 19.0156 87.1844 19.8889C88.0567 20.7621 88.4929 21.8072 88.4929 23.024C88.4929 24.0405 88.1783 24.9424 87.549 25.7297C86.9341 26.5171 86.1476 27.0396 85.1894 27.2973L88.9219 33.6106H87.6134ZM79.2688 19.6527V26.3739H84.0525C84.9677 26.3739 85.7471 26.0518 86.3907 25.4076C87.0342 24.7491 87.356 23.9546 87.356 23.024C87.356 22.0935 87.0342 21.299 86.3907 20.6405C85.7471 19.9819 84.9677 19.6527 84.0525 19.6527H79.2688Z" fill="#0E1039"/>
      <path d="M95.6687 33.8468C94.2815 33.8468 93.1017 33.5318 92.1292 32.9019C91.1711 32.2577 90.5204 31.3916 90.1772 30.3036L91.1639 29.7238C91.4356 30.6544 91.9505 31.3916 92.7084 31.9356C93.4807 32.4796 94.4674 32.7516 95.6687 32.7516C96.8843 32.7516 97.8139 32.4725 98.4574 31.9141C99.1152 31.3558 99.4441 30.6329 99.4441 29.7453C99.4441 28.915 99.1295 28.2779 98.5003 27.8341C97.871 27.3904 96.8914 26.9537 95.5615 26.5242C95.0752 26.3668 94.7177 26.2522 94.4889 26.1807C94.2744 26.0948 93.9598 25.9731 93.545 25.8156C93.1303 25.6438 92.8228 25.4935 92.6226 25.3647C92.4224 25.2358 92.1793 25.064 91.8933 24.8493C91.6216 24.6346 91.4214 24.4198 91.2926 24.2051C91.1639 23.976 91.0495 23.7112 90.9494 23.4106C90.8493 23.0956 90.7993 22.752 90.7993 22.3798C90.7993 21.163 91.2283 20.1895 92.0863 19.4594C92.9587 18.715 94.0456 18.3428 95.3469 18.3428C96.5196 18.3428 97.5207 18.6362 98.3501 19.2232C99.1796 19.8101 99.7874 20.5689 100.173 21.4994L99.2082 22.0362C98.4931 20.304 97.2061 19.4379 95.3469 19.4379C94.3173 19.4379 93.495 19.7099 92.88 20.2539C92.2651 20.7979 91.9576 21.4922 91.9576 22.3369C91.9576 23.1529 92.2437 23.7613 92.8157 24.1621C93.3877 24.563 94.3244 24.9781 95.6258 25.4076C96.4553 25.6796 97.0988 25.9158 97.5564 26.1162C98.0284 26.3024 98.5289 26.5672 99.058 26.9108C99.6015 27.24 99.9947 27.6409 100.238 28.1133C100.481 28.5714 100.603 29.1082 100.603 29.7238C100.603 30.9693 100.152 31.9714 99.2511 32.7301C98.3501 33.4746 97.156 33.8468 95.6687 33.8468Z" fill="#0E1039"/>
    </Svg>
  );
};

export default Icon;
