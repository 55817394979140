import "./about.sass";
import { LegalHeader } from "../LegalGenerics";
import { faqContactEmail } from "../../data/constants";

export default function About() {
  return (
    <>
      <LegalHeader
        title="About"
      />
      <article className="about__article article_preamble">
        <h2 className="h2_title about__article_title">About us</h2>
        <div className="article__paragrafs_container">
          <p className="about__article_paragraf">
            Our platform allows crypto enthusiasts and art collectors to tokenize and purchase digital versions of select art works from the world's finest collections. 
          </p>
          <p className="about__article_paragraf">
            Fast and secure.
          </p>
        </div>
        
      </article>
      <article className="about__article article_description">
        <h2 className="h2_title about__article_title">Community</h2>
        <div className="article__paragrafs_container article_description__container">
          <div className="about__article_paragraf__container">
            <h3 className="h3_title article__subtitle">Buyers:</h3>
            <p className="about__article_paragraf">
              Anyone can become a buyer. Confirmation of identity and selection of the desired auction lot is all that is required for a buyer to participate.
            </p>
          </div>
          <div className="about__article_paragraf__container">
            <h3 className="h3_title article__subtitle">Sellers:</h3>
            <p className="about__article_paragraf">
              We welcome large museums and private collectors to take part in our auctions. All owners and art works are rigorously prescreened and possess the necessary proof of ownership, thereby providing for a secure NFT safe transaction from the get go.
            </p>
          </div>
        </div>
      </article>
      <article className="about__article article_description">
        <h2 className="h2_title about__article_title">How it works</h2>
        <div className="article__paragrafs_container article_description__container">
          <div className="about__article_paragraf__container">
            <h3 className="h3_title article__subtitle">Trader</h3>
            <ol className="about__article_paragraf about__list">
              <li>Connects metamask to log into website</li>
              <li>Proves his identity</li>
              <li>Buys and resells items</li>
            </ol>
          </div>
          <div className="about__article_paragraf__container">
            <h3 className="h3_title article__subtitle">Creator</h3>
            <ol className="about__article_paragraf about__list">
              <li>Proves his identity</li>
              <li>Applies for cooperation</li>
              <li>Creates and sells NFT</li>
            </ol>
          </div>
          <div className="about__article_paragraf__container">
            <h3 className="h3_title article__subtitle">Auction flow</h3>
            <ol className="about__article_paragraf about__list">
              <li>NFT is created</li>
              <li>First bid is placed</li>
              <li>In 48 hrs latest and largest bid secures the deal</li>
            </ol>
          </div>
          <div className="about__article_paragraf__container">
            <h3 className="h3_title article__subtitle">After auction flow</h3>
            <ol className="about__article_paragraf about__list">
              <li>NFT is sold</li>
              <li>Creator gets his award</li>
              <li>Buyer receives NFT on his wallet</li>
            </ol>
          </div>
        </div>
      </article>
      <article className="about__article about__contacts">
        <h2 className="h2_title about__article_title">Support</h2>
        <p className="about__article_paragraf">
          <a
            className="legal__link"
            href={`mailto:${faqContactEmail!}`}
            target="_blank"
            rel="noreferrer">
            {faqContactEmail!}
          </a>
        </p>
      </article>
    </>
  )
}
