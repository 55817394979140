import { useContext, useState } from 'react';
import { UploadContext } from './UploadProvider';
import { selectUserAddress } from '../../store/features/userData/userDataSlice';
import { useAppSelector } from '../../store/hooks';
import { uploadArtDoneTexts } from '../../data/lettersPagesTexts';
import { moderatorContactEmail } from '../../data/lettersPagesTexts';
import LetterPage from '../LetterPage/LetterPage';

function UploadArtDone () {
  const account = useAppSelector(selectUserAddress);
  
  const { 
    artHash, 
    docHash,
    detailsHash,
    cloudLink,
    signedMessage,
    artworkPrice,
  } = useContext(UploadContext);

  const [letterHeading, setLetterHeading] = useState(uploadArtDoneTexts.letter)

  const messageData = {
    subject: 'Atrwork on moderation',
    body: {
      heading: `${letterHeading}`,
      message: `%0D%0A%0D%0A----- DO NOT CHANGE TEXT BELOW -----%0D%0A%0D%0A- creator: '${account}'%0D%0Astart_price_usdt: ${artworkPrice}%0D%0Aartwork_ipfs: '${artHash}'%0D%0Adocument_ipfs: '${docHash}'%0D%0Adetails_ipfs: '${detailsHash}'%0D%0Alink_to_artwork: '${cloudLink}'%0D%0Asigned_message: '${artHash}%0D%0A${docHash}%0D%0A${detailsHash}'%0D%0Adetails_link: 'https://ipfs.infura.io/ipfs/${detailsHash}'%0D%0Asignature_hash: '${signedMessage}'`,
    },
  }
  
  return (
    <LetterPage
      pageText={uploadArtDoneTexts}
      setLetterHeading={setLetterHeading}
      messageData={messageData}
      letterHeading={letterHeading}
      contactEmail={moderatorContactEmail}
    />
  )

};

export default UploadArtDone;
