import { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import SingleWalletButton from './SingleWalletButton';
import config from "./config";
import { connectorsByName } from '../../utils/web3React';
import useAuth from '../../hooks/useAuth';
import { ModalContext } from '../Modal/ModalProvider';
import { useSetActivatingConnector } from '../../hooks/useSetActivatingConnector';
import './walletModal.sass';
import { useWeb3React } from '@web3-react/core';
import RefCheckbox from '../../components/RefCheckbox/RefCheckbox';
import {
  walletModalLinkPrivacyPolicy,
  walletModalLinkTerms
} from '../modalTexts';

function WalletModal() {
  const { active } = useWeb3React();
	const { login } = useAuth();
  const { handleModalClose } = useContext(ModalContext);
  const { setActivatingConnector } = useSetActivatingConnector();
  const [isChecked, setIsChecked] = useState(false);
  const [isWarningShown, setShowWarning] = useState(false);

  const handleShowWarning = () => {
    setShowWarning(true)
  }
  
  useEffect(() => {
    if (active) {
      handleModalClose()
    }
  }, [active, handleModalClose])

  return (
    <> 
      <div className="modal__wallets_container">
        { Object.keys(connectorsByName).map((name, i) => {
          const currentConnector:any = (connectorsByName as any)[name];
          let data;

          config.forEach(item => {
            if (item.connectorId === name) {
              data = item;
              return data
            }
          })

          return data ? 
            <SingleWalletButton
              isChecked = {isChecked}
              handleShowWarning={handleShowWarning}
              handleCloseModal={handleModalClose}
              key={name}
              login={login}
              walletConfig={data}
              connector = {currentConnector}
              setActivatingConnector={setActivatingConnector}
          /> 
          : ''
        })}
      </div>
      <RefCheckbox
        id="accept"
        className="modal__wallet_accept"
        setIsChecked={setIsChecked}
        isChecked={isChecked}
        setShowWarning={setShowWarning}
        label={<>I accept <Link
          to={walletModalLinkTerms}
          className="wallet__link"
        >
            Terms of Service
          </Link> and <Link 
            to={walletModalLinkPrivacyPolicy}
            className="wallet__link"
          >Privacy Policy</Link></>}
        data=""
      />
      { isWarningShown && 
        !isChecked && 
        <div className="wallet_modal__warning">Please accept terms and conditions first</div>}
    </>
  )
};

export default WalletModal;