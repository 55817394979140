import React from "react";
import './buttons.sass';

interface Props {
  children?: React.ReactNode;
  className?: string;
  onClick?: (arg?:any) => void;
}

function Button({ 
    onClick,
    children,
    className
  }: Props ) { 
  return (
    <button 
      className={className}
      onClick={onClick}
    >
    {children}
    </button>
  );
};

export default Button;