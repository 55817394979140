import React from "react";
import Svg from "../Svg";
import { SvgProps } from "../types";

const Icon: React.FC<SvgProps> = (props) => {
  return (
    <Svg width="17" height="14" viewBox="0 0 17 14" {...props}>
      <path d="M15.3999 6.19998H3.5399L7.1699 1.83998C7.33964 1.63577 7.4213 1.37249 7.39692 1.10806C7.37254 0.843633 7.24412 0.599721 7.0399 0.429983C6.83569 0.260244 6.5724 0.178582 6.30798 0.202962C6.04355 0.227342 5.79964 0.355766 5.6299 0.559983L0.629902 6.55998C0.596263 6.60771 0.566182 6.65784 0.539902 6.70998C0.539902 6.75998 0.539903 6.78998 0.469903 6.83998C0.424576 6.95464 0.400843 7.07669 0.399902 7.19998C0.400843 7.32327 0.424576 7.44532 0.469903 7.55998C0.469903 7.60998 0.469902 7.63998 0.539902 7.68998C0.566182 7.74212 0.596263 7.79226 0.629902 7.83998L5.6299 13.84C5.72392 13.9529 5.84166 14.0436 5.97475 14.1059C6.10783 14.1681 6.25299 14.2002 6.3999 14.2C6.63355 14.2004 6.85999 14.1191 7.0399 13.97C7.14116 13.886 7.22486 13.7829 7.28621 13.6666C7.34757 13.5502 7.38536 13.4229 7.39744 13.292C7.40951 13.161 7.39563 13.0289 7.35659 12.9033C7.31754 12.7777 7.2541 12.661 7.1699 12.56L3.5399 8.19998H15.3999C15.6651 8.19998 15.9195 8.09463 16.107 7.90709C16.2945 7.71955 16.3999 7.4652 16.3999 7.19998C16.3999 6.93477 16.2945 6.68041 16.107 6.49288C15.9195 6.30534 15.6651 6.19998 15.3999 6.19998Z" />
    </Svg>
  );
};

export default Icon;
