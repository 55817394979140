import './navBar.sass';
import Button from '../Button/Button';

export interface TabsInterface {
  [key: string]: {
    title: string,
    component: JSX.Element,
  }
}

interface TabsNavBarInterface {
  setSelectedTab: React.Dispatch<React.SetStateAction<string>>,
  selectedTab: string,
  tabs: TabsInterface
}

function TabsNavBar ({ setSelectedTab, selectedTab, tabs }: TabsNavBarInterface) {
  return(
    <ul className="tabs_navigation__list">
      { Object.keys(tabs).map((item: string, key: number) => {
        return (
          <li
            className="tabs_navigation__item"
            key={key}
          >
            <Button
              onClick={() => setSelectedTab(item)} 
              className={`tabs_navigation__button ${item === selectedTab ? 'active' : ''}`}
            >{tabs[item].title}</Button>
          </li>
        )
      })}
    </ul>
  )
}

export default TabsNavBar;