import SingleArtCard from '../SingleArtCard/SingleArtCard';
import { Artwork } from '../../network/types';

interface UserFilteredArtsInterface {
  artworks: Artwork[],
  sold?: boolean,
  onSale?: boolean,
  notOnSale?: boolean,
}

function UserFilteredArts (props: UserFilteredArtsInterface) {
  const {
    artworks,
    sold,
    onSale,
    notOnSale
  } = props;

  return (
    <section className="user_artworks__container">
      { artworks.map((item:Artwork, i:number) => {
          let isloaded = Boolean(item.data != null);
          return (
            <div 
              className={`user_artworks__art ${
                sold ? 'art__sold' 
                : onSale ? 'art__on_sale'
                : notOnSale ? 'art__not_on_sale' 
                : ''
              }`}
              key={`${item.owner}_${item.tokenId}_${i}`} 
            >
              <SingleArtCard 
                tokenId={item.tokenId} 
                isLoaded={isloaded} 
                notOnSale={notOnSale}
              />
            </div>
          )
        })
      }
    </section>
  )
}

export default UserFilteredArts;