import { useContext } from 'react'; 
import { Link } from "react-router-dom";
import { ModalContext } from '../Modal/ModalProvider';
import Button from "../../components/Button/Button";

interface Props {
  isInputsFilled: boolean;
  setShowWarning: React.Dispatch<React.SetStateAction<boolean>>;
  isAgent: boolean;
  setIsNoAgentShowWarning: React.Dispatch<React.SetStateAction<boolean>>;
}

function ButtonGetInvite({
  isInputsFilled,
  setShowWarning,
  isAgent,
  setIsNoAgentShowWarning,
}: Props) {
  const { handleModalClose } = useContext(ModalContext);
  
  return (
    <>
      { isInputsFilled && isAgent ?
        <Link to='/invite'>
          <Button
            className="button__big"
            onClick={handleModalClose}
          >Get invite</Button>
        </Link>
      : !isAgent ?
        <Button
          className="button__big not_active"
          onClick={() => setIsNoAgentShowWarning(true)}
        >Get invite</Button>
      :
        <Button
          className="button__big not_active"
          onClick={() => setShowWarning(true)}
        >Get invite</Button>
      }
    </>
  )
}

export default ButtonGetInvite;