import Web3 from 'web3';
import {
  contractAddress,
  auctionContractAddress,
  currencyContract,
  statusesContractAddress
} from '../networkConfig';
const abi = require('../contracts/GreatMastersNFT.json');
const abiAuction = require('../contracts/GMAuction.json');
const IERC20 = require('../contracts/IERC20.json');
const abiStatuses = require('../contracts/GMAccessControl.json');

export const web3 = new Web3(new Web3.providers.WebsocketProvider(process.env.REACT_APP_WEB_3_LINK!,
{
  // @ts-ignore
  clientConfig: {
      keepalive: true,
      keepaliveInterval: 60000
  },
  reconnect: {
    auto: true,
    delay: 1000,
    maxAttempts: 10,
  },
}
));

export const contract = new web3.eth.Contract(abi.abi, contractAddress);

export const auctionContract = new web3.eth.Contract(abiAuction.abi, auctionContractAddress);

export const erc20Contract = new web3.eth.Contract(IERC20.abi, currencyContract);

export const statusesContract = new web3.eth.Contract(abiStatuses.abi, statusesContractAddress);

export const accounts = web3.eth.getAccounts()
