import { useState, useContext, useEffect, useCallback } from 'react';
import './singleArtwork.sass';
import IpfsImage from '../IpfsImage/IpfsImage';
import TabsNavBar, { TabsInterface } from '../Tabs/TabsNavBar';
import Spinner from '../Spinner/Spinner';
import SingleArtworkInfo from './SingleArtworkInfo/SingleArtworkInfo';
import ResizeImageButton from '../../widgets/ImageModal/ResizeImageButton';
import ImageModal from '../../widgets/ImageModal/ImageModal';
import { ImageModalData } from '../../widgets/ImageModal/config';
import { ModalContext } from '../../widgets/Modal/ModalProvider';
import { useWeb3React } from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers';
import ButtonPlaceABid from './ButtonPlaceABid/ButtonPlaceABid';
import Button from '../Button/Button';
import NoAuctionStarted from './NoAuctionStarted/NoAuctionStarted';
import { auctionContract, contract, web3 } from '../../network/service/web3';
import { auctionContractAddress } from '../../network/networkConfig';
import Timer from '../Timer/Timer';
import ButtonCompleteAuction from './ButtonCompleteAuction/ButtonCompleteAuction';
import SingleArtworkPresentBidding from './SingleArtworkPresentBidding/SingleArtworkPresentBidding';
import ButtonApproveNft from './ButtonApproveNft/ButtonApproveNft';
import SoonStartingMessage from './SoonStartingMessage/SoonStartingMessage';
import {
  useAppDispatch,
  useAppSelector
} from '../../store/hooks';
import { selectUserAddress } from '../../store/features/userData/userDataSlice';
import {
  selectArtworksAuctionsByTokenId
} from '../../store/features/auctionEvents/auctionEventsSlice';
import { WEI } from '../../data/constants';
import SingleArtworkAuction from './SingleArtworkAuction/SingleArtworkAuction';
import { useGetAuctionDataByTokenId } from './useGetAuctionDataByTokenId';
import { EventData } from "web3-eth-contract";
import { TransactionReceipt } from 'web3-core';
import OwnershipInfo from './OwnershipInfo/OwnershipInfo';
import { showToastAuctionComplete, showToastBid, showToastCustomTextError } from '../../data/toasts';
import Tooltip from '../Tooltip/Tooltip';

function SingleArtwork (props: any) {
  const { active } = useWeb3React<Web3Provider>();

  const dispatch = useAppDispatch();

  const { handleModalOpen } = useContext(ModalContext);

  const {
    isScheduled,
    setIsScheduled,
    isAuctionActive,
    setIsAuctionActive,
    minimumBid,
    setMinimumBid,
    beneficiary,
    setBeneficiary,
    startPrice,
    setStartPrice,
    endTime,
    setEndTime,
    startTime,
    timeIsEnded,
    setTimeIsEnded,
    highestBid,
    setHighestBid,
    highestBidder,
    setHighestBidder,
    setTokenId,
    isBidPlaced,
    setIsBidPlaced,
    setGettingData,
  } = useGetAuctionDataByTokenId();

  const { data } = props;

  const account = useAppSelector(selectUserAddress);
  const getArtworkAuctions = useCallback((state) =>
    selectArtworksAuctionsByTokenId(state, data?.tokenId), [data?.tokenId])
  const artworkAuctions = useAppSelector(getArtworkAuctions);

  const [selectedTab, setSelectedTab] = useState('auction');
  const [isAuctionCompleted, setIsAuctionCompleted] = useState(false);
  const [isAuctionFailed, setIsAuctionFailed] = useState(false);
  const [currentOwner, setCurrentOwner] = useState('');
  const [isNftApproved, setIsNftApproved] = useState(false);
  const [winningPrice, setWinningPrice] = useState(0);
  const [lastUserBid, setLastUserBid] = useState(0);
  const [bidder, setBidder] = useState('');
  const [isProcessing, setIsProcessing] = useState(false);
  const [timeIsUp, setTimeIsUp] = useState(false);
  const [completeInProgress, setCompleteInProgress] = useState(false);
  const [bidTransactionHash, setBidTransactionHash] = useState('');
  const [isApproveProcessing, setIsApproveProcessing] = useState(false);
  const [isAuctionCanBeApproved, setIsAuctionCanBeApproved] = useState(false);
  const [isOwner, setIsOwner] = useState(false);
  const [isUserCanCompleteAuction, setIsUserCanCompleteAuction] = useState(false);
  const [isAuctionRecentlyEnded, setIsAuctionRecentlyEnded] = useState(false);

  const timeNotCome = startTime && (+new Date(startTime*1000) >= +new Date());

  const artworkTabs: TabsInterface = {
    'info': {
      title: 'Info',
      component: <SingleArtworkInfo
          {...data}
          artworkAuctions={artworkAuctions?.auctionsHistory}
        />
    },
    'auction': {
      title: 'Auction events',
      component: artworkAuctions ?
          <SingleArtworkAuction
            {...artworkAuctions}
            beneficiary={beneficiary}
            endTime={endTime}
            timeIsUp={timeIsUp}
            artworkTitle={data?.data.title}
            isProcessing={isProcessing}
          />
        : <NoAuctionStarted />
    },
  }

  const handleUserLastBid = useCallback(() => {
    const lastStart = artworkAuctions.auctionsHistory.find((auction: any) => auction.event === 'AuctionScheduled');
      const lastUserBid = artworkAuctions.auctionsHistory.find((auction: any) => auction.returnValues.bidder === account)
      if (lastUserBid && lastStart.blockNumber <= lastUserBid.blockNumber) {
        setLastUserBid(lastUserBid.returnValues.price/WEI);
        setBidder(lastUserBid.returnValues.bidder)
      }
      setIsProcessing(false)
  },[account, artworkAuctions?.auctionsHistory])

  const isUserCanNotPlaceBid = account === data?.owner || account === beneficiary || timeIsUp;

  useEffect(() => {
    if (data !== undefined) {
      setTokenId(data.tokenId)
    }
  }, [data, setTokenId])

  useEffect(() => {
    if (account === beneficiary || account === highestBidder) {
      setIsUserCanCompleteAuction(true);
    } else {
      setIsUserCanCompleteAuction(false);
    }
  },[account, beneficiary, highestBidder]);

  useEffect(() => {
    if (!isAuctionActive && timeIsUp && !isAuctionCompleted) {
      setIsAuctionRecentlyEnded(true);
    } else {
      setIsAuctionRecentlyEnded(false);
    }
  }, [isAuctionActive, isAuctionCompleted, timeIsUp])

  useEffect(() => {
    if (data?.owner === account || currentOwner === account) {
      setIsOwner(true);
    } else {
      setIsOwner(false);
    }
  },[account, currentOwner, data, data?.owner])

  useEffect(() => {
    if (endTime && (+new Date(endTime*1000) - +new Date()) < 0) {
      setTimeIsUp(true)
    }
    return () => setTimeIsUp(false)
  }, [endTime, timeIsEnded])

  useEffect(() => {
    if (timeIsEnded) {
      setTimeIsUp(true)
    }
  }, [timeIsEnded])

  useEffect(() => {
    if (isScheduled && data) {
      if (data?.owner === auctionContractAddress) {
        setCurrentOwner(beneficiary)
      } else {
        setCurrentOwner(data?.owner)
      }
    } else if (!isAuctionActive && data?.owner === auctionContractAddress){
      setCurrentOwner(data.history[0].returnValues.from)
    } else {
      setCurrentOwner(data?.owner)
    }

    return () => {
      setCurrentOwner('')
    }

  }, [isAuctionActive, data, beneficiary, isScheduled])

  useEffect(() => {
    if (data !== undefined) {
      auctionContract.events.AuctionComplete({
        filter: {artId: data.tokenId},
      }, (error: Error, result: any) => {
        if (!error) {
          setWinningPrice(result.returnValues.price/WEI);
          setCurrentOwner(result.returnValues.winner);
          web3.eth.getTransactionReceipt(result.transactionHash)
            .then((receipt: TransactionReceipt) => {
              const isUserCompleteAuction = result.returnValues.winner === account || beneficiary === account;
              if (!!receipt && isUserCompleteAuction && !!receipt.status) {
                showToastAuctionComplete();
              } else if (!!receipt && isUserCompleteAuction && !!!receipt.status) {
                showToastCustomTextError('An error occurred while Auction completion');
              }
            })
            .catch(console.log)
          setIsScheduled(false);
          setIsAuctionCompleted(true)
          setIsNftApproved(false);
          setIsAuctionActive(false);
          setIsProcessing(false);
        } else {
          console.log(error)
        }
      });
    }

    return () => {
      setIsProcessing(false)
    }
  }, [account, beneficiary, data, dispatch, isAuctionCompleted, setIsAuctionActive, setIsScheduled])

  useEffect(() => {
    if (isAuctionCompleted && isOwner) {
      setIsAuctionCanBeApproved(active && isOwner && !isScheduled && !isAuctionActive && isAuctionCompleted);
    } else {
      setIsAuctionCanBeApproved(false);
    }
  }, [active, isAuctionActive, isAuctionCompleted, isOwner, isScheduled])

  useEffect(() => {
    if (data !== undefined) {
      contract.methods.getApproved(data.tokenId).call()
        .then((result: string) => {
          if (result === auctionContractAddress && data.owner === account) {
            setIsNftApproved(true)
          }
        })
    }
  }, [data, isNftApproved, active, account])

  useEffect(() => {
    if (data !== undefined && artworkAuctions && artworkAuctions.tokenId === +data.tokenId) {
      const current = artworkAuctions.auctionsHistory[0];
      if (current.event === 'AuctionComplete' && data.owner === current.returnValues.winner) {
        setIsAuctionCompleted(true)
        setWinningPrice(current.returnValues.price/WEI)
      } else if (current.event === 'AuctionFailure' && data.owner === account) {
        setIsAuctionCompleted(true)
        setIsAuctionFailed(true)
      } else {
        setIsAuctionCompleted(false)
        setIsAuctionFailed(false)
      }
    }
  }, [data, artworkAuctions, active, account])

  useEffect(() => {
    if (artworkAuctions?.auctionsHistory[0].event === 'AuctionComplete') {
      setIsAuctionCompleted(true);
      setCurrentOwner(artworkAuctions.auctionsHistory[0].returnValues.winner);
      setCompleteInProgress(false);
    }
  }, [artworkAuctions?.auctionsHistory])

  useEffect(() => {
    if (data !== undefined && isBidPlaced) {
      setIsProcessing(true)
      auctionContract.events.Bid({
        filter: {artId: data.tokenId},
      }, (error: Error, result: any) => {
          if (!error) {
            if (result.returnValues.bidder !== artworkAuctions.auctionsHistory[0].returnValues.bidder) {
              setHighestBid(result.returnValues.price/WEI);
              setHighestBidder(result.returnValues.bidder);
              setMinimumBid(result.returnValues.minNextBid/WEI);
            }
            web3.eth.getTransactionReceipt(result.transactionHash)
              .then((receipt: TransactionReceipt) => {
                if (!!receipt && result.returnValues.bidder === account && !!receipt.status) {
                  showToastBid();
                } else if (!!receipt && result.returnValues.bidder === account && !!!receipt.status) {
                  showToastCustomTextError('An error occurred while bid placing');
                }
              })
              .catch(console.log)
          } else {
            console.log(error)
          }
        setIsBidPlaced(false)
      })
    }
  }, [artworkAuctions?.auctionsHistory, bidTransactionHash, data, isBidPlaced, setHighestBid, setHighestBidder, setIsBidPlaced, setMinimumBid, account, dispatch])

  useEffect(() => {
    if (data !== undefined) {
      auctionContract.events.AuctionStart({
        filter: {artId: data.tokenId},
      }, (error: Error, result: any) => {
        if (!error) {
          setGettingData(true);
        } else {
          console.log(error)
        }
      })
    }
  }, [data, setGettingData])

  useEffect(() => {
    if (artworkAuctions?.auctionsHistory.length) {
      if (artworkAuctions.auctionsHistory[0].event === 'Bid') {
        setMinimumBid(artworkAuctions.auctionsHistory[0].returnValues.minNextBid/WEI);
        setHighestBidder(artworkAuctions.auctionsHistory[0].returnValues.bidder);
      }
    }
  }, [artworkAuctions?.auctionsHistory, artworkAuctions?.auctionsHistory.length, data?.tokenId, setHighestBidder, setMinimumBid])

  useEffect(() => {
    if (active && artworkAuctions?.auctionsHistory.length) {
      handleUserLastBid()
    }
  }, [active, artworkAuctions?.auctionsHistory, handleUserLastBid])

  useEffect(() => {
    if (data !== undefined) {
      auctionContract.events.AuctionScheduled({
        filter: {artId: data.tokenId},
      }, (error: Error, result: EventData) => {
        setIsProcessing(true)
        if (!error) {
          setIsScheduled(true)
          setBeneficiary(result.returnValues.beneficiary);
          setStartPrice(result.returnValues.startPrice/WEI);
          setLastUserBid(0);
          setBidder('');
          setIsProcessing(false);
        } else {
          console.log(error)
          setIsProcessing(false);
        }
      });

    }
    return () => {
      setIsProcessing(false)
    }
  }, [data, dispatch, setBeneficiary, setIsScheduled, setStartPrice])

  useEffect(() => {
    if (data !== undefined) {
      auctionContract.events.AuctionEndTimeChanged({
        filter: {artId: data.tokenId},
      }, (error: Error, result: EventData) => {
        if (!error) {
          setEndTime(result.returnValues.endTime)
        }
      })
    }
  }, [data, setEndTime])

  useEffect(() => {
    if (data !== undefined) {
      auctionContract.events.Bid({
        filter: {artId: data.tokenId},
      }, (error: Error, result: EventData) => {
        if (!error) {
          setHighestBidder(result.returnValues.bidder)
        }
      })
    }
  }, [data, setHighestBidder])

  useEffect(() => {
    if (data !== undefined && isApproveProcessing) {
      contract.events.Approval({
        filter: {tokenId: data.tokenId},
      }, (error: Error, result: EventData) => {
        if (!error) {
          web3.eth.getTransactionReceipt(result.transactionHash)
            .then((receipt: TransactionReceipt) => {
              if (!!receipt && result.returnValues.owner === account && !!receipt.status) {
                setIsNftApproved(true);
              } else if (!!receipt && result.returnValues.owner === account && !!!receipt.status) {
                showToastCustomTextError('An error occurred while approving NFT')
              }
            })
            .catch(console.log)
            setIsApproveProcessing(false);
        }
      })
    }
  }, [account, data, isApproveProcessing])

  const isAuctionWaiting = isScheduled && !isAuctionActive && endTime === 0
  const isAuctionGoingOn = isScheduled && isAuctionActive && endTime !== 0 && !timeIsUp

  return (
    <>
      { data && data.data ?
        <>
          <div className="single_artwork__top_info_container">
            <div className="single_artwork__image_container">
              {/* TODO: Расскоментить после того как решат вопрос с превьюхами и удалить img */}
              {/*<IpfsImage metaData={data?.data.image} className="single_artwork__image"/>*/}
              <img
                src="https://greatmasters.com/static/media/bafybeig3khhqtm2vwz2fiq5zf2epeo6iug57nrud625diskq4bblfyzmnq_preview.jpg"
                className="single_artwork__image"
              />
              <ResizeImageButton
                onClick={() => handleModalOpen(<ImageModal data={data.data}/>, ImageModalData)}
              />
            </div>
            <div className="single_artwork__info">
              <div className="single_artwork__heading">
                <h1 className="single_artwork__title">{data?.data.title}</h1>
                <p className="single_artwork__description">
                  {data?.data.description}
                </p>
                <OwnershipInfo
                  currentOwner={currentOwner}
                  data={data}
                  completeInProgress={completeInProgress}
                />
                <div className="single_artwork_auction_timer">
                  { artworkAuctions !== undefined &&
                    !timeNotCome &&
                      <>
                        { isAuctionWaiting ?
                          <>Waiting for the first bid</>
                        : isAuctionGoingOn ?
                          <>Ending in&nbsp;
                            <Timer
                              endTime={endTime !== 0 ? endTime : 0}
                              setTimeIsEnded={setTimeIsEnded}
                            />
                          </>
                        : timeIsUp &&
                          <>Auction is ended!</>
                        }
                      </>
                  }
                </div>
              </div>
              <div className="single_artwork__bidding">
              { !timeNotCome &&
                  <SingleArtworkPresentBidding
                    minimumBid={minimumBid}
                    startPrice={startPrice}
                    highestBid={highestBid}
                    highestBidder={highestBidder}
                    winningPrice={winningPrice}
                    isAuctionCompleted={isAuctionCompleted}
                    isAuctionActive={isAuctionActive}
                    isAuctionFailed={isAuctionFailed}
                    lastUserBid={lastUserBid}
                    isScheduled={isScheduled}
                    bidder={bidder}
                    endTime={endTime}
                    timeIsUp={timeIsUp}
                    timeIsEnded={timeIsEnded}
                    isProcessing={isProcessing}
                  />
                }
                { isUserCanNotPlaceBid ?
                    ''
                  : account === highestBidder ?
                    <Button className="button__big highest_bidder">Your bid is the highest!</Button>
                  : isScheduled && !isProcessing?
                    <ButtonPlaceABid
                      metaData={data}
                      minimumBid={minimumBid}
                      startPrice={startPrice}
                      highestBid={highestBid}
                      setIsBidPlaced={setIsBidPlaced}
                      setIsProcessing={setIsProcessing}
                      beneficiary={beneficiary}
                      setBidTransactionHash={setBidTransactionHash}
                    />
                  : isProcessing &&
                    <Button className="button__big not_active">You just placed a bid</Button>
                }
              </div>
              { isAuctionRecentlyEnded ?
                  isUserCanCompleteAuction ?
                    <div className="single_artwork__bidding">
                      <ButtonCompleteAuction
                        beneficiary={beneficiary}
                        highestBidder={highestBidder}
                        tokenId={data.tokenId}
                        setCurrentOwner={setCurrentOwner}
                        setIsAuctionCompleted={setIsAuctionCompleted}
                        setIsProcessing={setIsProcessing}
                        setCompleteInProgress={setCompleteInProgress}
                      />
                      <Tooltip
                        innerText="After pressing this button during next 24 hours the Winner will recive an e-mail with a link to his NFT"
                        className="button__page_tooltip"
                      />
                    </div>
                  :
                    ''
                : !isAuctionRecentlyEnded && isAuctionCanBeApproved ?
                  <div className="single_artwork__bidding">
                    <ButtonApproveNft
                      tokenId={data.tokenId}
                      owner={data.owner}
                      setIsApproveProcessing={setIsApproveProcessing}
                      setIsScheduled={setIsScheduled}
                      setIsNftApproved={setIsNftApproved}
                      isNftApproved={isNftApproved}
                    />
                  </div>
                :
                  ''
              }
            </div>
          </div>
          { isAuctionActive || !isAuctionCompleted ?
              ''
            :
              <SoonStartingMessage
                isNftApproved={isNftApproved}
                isScheduled={isScheduled}
                isAuctionCompleted={isAuctionCompleted}
              />
            }
          <div className="single_artwork__history_container">
            <TabsNavBar
              selectedTab = {selectedTab}
              setSelectedTab={setSelectedTab}
              tabs={artworkTabs}
            />
            <div className="single_artwork__history">
              {artworkTabs[selectedTab].component}
            </div>
          </div>
        </>
      :
        <div className="single_artwork__loading">
          <Spinner width="56px" color="#706FF6"/>
        </div>
      }
    </>
  )
}

export default SingleArtwork;
