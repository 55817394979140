import { useState, useEffect } from 'react';
import SingleArtCard from '../SingleArtCard/SingleArtCard';
import { Artwork } from '../../network/types';
import './allArts.sass';
import { ArrowDownIcon } from '../Svg';
import Button from '../Button/Button';
import SkeletonArtCard from '../SkeletonArtCard/SkeletonArtCard';
import { useAppSelector } from '../../store/hooks';
import { selectAllArtworks, selectArtworksLoadingStatus } from '../../store/features/allArtworks/allArtworksSlice';

function AllArtsMainPage() {
  const [uploadedArts, setUploadedArts] = useState(9);
  const [randomArtworks, setRandomArtworks] = useState([])
  const [isAllLoaded, setIsAllLoaded] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const loadingStatus = useAppSelector(selectArtworksLoadingStatus);
  const allArtworks = useAppSelector(selectAllArtworks);

  const artToAdd = 9;

  const shuffle = (arr: any) => {
    return arr.sort(() => Math.round(Math.random() * 100) - 50);
  }

  useEffect(() => {
    if (allArtworks.length > 0 && loadingStatus === 'loaded') {
      const artworks = allArtworks;
      setIsLoaded(true);
      const random = shuffle(artworks);
      setRandomArtworks(random);
    }
    return () => setIsLoaded(false)
  }, [allArtworks, loadingStatus])

  const handleLoadMore = () => {
    if (uploadedArts >= randomArtworks.length) {
      setUploadedArts(uploadedArts);
      setIsAllLoaded(true);
    } else if (uploadedArts + artToAdd > randomArtworks.length) {
      setUploadedArts(uploadedArts + artToAdd);
      setIsAllLoaded(true);
    } else {
      setUploadedArts(uploadedArts + artToAdd);
      setIsAllLoaded(false);
    }
  }

  useEffect(() => {
    if (randomArtworks.length <= uploadedArts) {
      setIsAllLoaded(true);
    } else {
      setIsAllLoaded(false);
    }
  }, [randomArtworks.length, uploadedArts])

  return(
    <section className="container all_arts__section">
      <h2 className="h2_title all_arts__title">
        Items gallery
      </h2>
      <div className="arts_grid__container">
        { isLoaded ? 
          randomArtworks.slice(0, uploadedArts).map((item:Artwork, i:number) => {
            let isloaded = Boolean(item.data != null)
            return (<SingleArtCard 
              tokenId={item.tokenId} 
              isLoaded={isloaded} 
              key={`${item.owner}_${item.tokenId}`} 
            />)
          })        
          :
          <>
            <SkeletonArtCard/>
            <SkeletonArtCard/>
            <SkeletonArtCard/>
          </>
        }
      </div>
      {!isAllLoaded ? 
        <Button 
          className="button__load_more"
          onClick={handleLoadMore}
        >
          <p>Load more</p>
          <ArrowDownIcon width="24"/>
        </Button>
        :
        <p className="all__loaded">All artworks are loaded</p>
      }
    </section>
  )
}

export default AllArtsMainPage;
