import { Link } from "react-router-dom";

const contactEmail = 'support@greatmasters.com';

export const privacyText = {
  title: 'Privacy Policy',
  lastUpdate: 'May 1, 2021',
  preamble: [
    <>
      This Privacy Policy together with our <Link className="legal__link" to="/terms">Terms of use</Link> (hereinafter the “Terms”) and any additional provisions that may be incorporated by reference into the Terms apply to your use of services of the GreatMasters platform (hereinafter referred to as “Company”, “We”, “Us”, “Our”, “Platform”) and Sum & Substance Ltd. (hereinafter referred to “Sum & Substance”), at the website located at <Link className="legal__link" to="/">https://greatmasters.com</Link> (“Website”) once you have accessed the Website on your computer, mobile telephone or handheld device and any of the Services accessible through the Website (collectively referred to as “Platform”).
    </>,
    <>
      We respect the privacy of every individual who visits our Platform. Thus, this Privacy Policy sets out the types of information collected from you and how the information is used. Please read the following carefully to understand our practices regarding your Personal Data and how we will treat it.
    </>,
    <>
      <u>Restriction to Collect Personal Data from Children. The Platform is not directed to children</u> under the age of majority, as defined by applicable law in your country of residence. We do not knowingly collect Data, including Personal Data, from children or other individuals who are not legally able to use our Platform. If we obtain actual knowledge that we have collected Personal Data from a child under the age of majority, we will promptly delete it, unless we are legally obligated to retain such data. Contact us if you believe that we have mistakenly or unintentionally collected Data from a child under the age of majority.
    </>
  ],
  sections: [{
    sectionId: 'who_we_are',
    title: `IMPORTANT DATA AND WHO WE ARE`,
    subSections: [{
      subSection: [{
        article: [{
          paragraf: <>The following entities are the controllers and are responsible for your Personal Data . The full details are:</>,
          subArticles: [{
            subArticle: [{
              paragraf: <>Starbit Ventures Limited
                , Email address: <a className="legal__link" href={`mailto:${contactEmail}`}
                target="_blank" rel="noreferrer">{contactEmail}</a>, Address: Craigmuir Chambers, PO Box 71, Road Town, Tortola VG1110, British Virgin Islands</>
            },{
              paragraf: <>Sum & Substance Ltd. Office 7 Westwood Business Centre, Continental Approach, Westwood, Kent, United Kingdom, contact e-mail: <a className="legal__link" href="mailto:info@sumsub.com" target="_blank" rel="noreferrer">info@sumsub.com</a></>
            }]
          },]
        }],
      }],
    }, {
      subSection: [{
        article: [{
          paragraf: <>You have the right to make a complaint at any time to the supervisory authority for data protection issues of your jurisdiction. We would, however, appreciate the chance to deal with your concerns before you approach the authority, so please contact us in the first instance, whenever you are experiencing an issue concerning your Personal Data.</>
        }]
      }]
    }, {
      subSection: [{
        article: [{
          paragraf: <>You should also be aware that we do not sell your Personal Data to any third parties and we shall not incorporate such practice in our business, unless providing you with a clear and explicit way to exercise your opt-out right and before introducing appropriate changes to this Policy.</>
        }]
      }]
    }]
  }, {
    sectionId: 'what_data_we_process',
    title: `WHAT DATA, FOR WHAT PURPOSES, BASED ON WHAT GROUNDS AND HOW LONG WE PROCESS`,
    subSections: [{
      heading: `Registration and verification`,
      subSection: [{
        article: [{
          paragraf: <>When you register to use our Services by create an account on our Platform (“Account”) and go through verification procedure, the GreatMasters and Sum & Substance may collect following Personal Data:</>,
          ul_list: [{
            list_items: [
              <>name;</>
            ],
          }, {
            list_items: [
              <>username;</>
            ],
          }, {
            list_items: [
              <>country of residence;</>
            ],
          }, {
            list_items: [
              <>phone number;</>
            ],
          }, {
            list_items: [
              <>e-mail;</>
            ],
          }, {
            list_items: [
              <>password;</>
            ],
          }, {
            list_items: [
              <>identity document data (name of the identity document), issuing country, series, number, expiry date, information embedded to document barcodes (may vary depending on the document), security feature;</>
            ],
          }, {
            list_items: [
              <>image data, such as photos of face (including selfie) and photo or scan of face on the identity document;</>
            ],
          }, {
            list_items: [
              <>assets remittance details, wallet number;</>
            ],
          }, {
            list_items: [
              <>IP address, geolocation, browser type/version, and operating system.</>
            ],
          },]
        }, {
          paragraf: <>This is necessary for us in order to enter into a contractual relationship with you in your interest or your request and we need to comply with legal or regulatory obligations.</>,
        }, {
          paragraf: <>We collect this information for the following purposes:</>,
          ul_list: [{
            list_items: [
              <>authenticate your access to an Account and our Platform;</>
            ],
          }, {
            list_items: [
              <>communicate with you about the Platform;</>
            ],
          }, {
            list_items: [
              <>manage and protect the Platform and fraud by verifying your identity;</>
            ],
          }, {
            list_items: [
              <>respond to your requests, for example to contact you about a question you submitted to our customer service team;</>
            ],
          }, {
            list_items: [
              <>сarry out any actions for which we have received your consent.</>
            ],
          }]
        }]
      },]
    }, {
      heading: `When you use our Platform`,
      subSection: [{
        article: [{
          paragraf: <>When you use our Platform, the GreatMasters and Sum & Substance will collect the following Personal Data:</>,
          ul_list: [{
            list_items: [
              <>first and last name, e-mail, password;</>
            ],
          }, {
            list_items: [
              <>information associated with the transaction such as amount sent or requested;</>
            ],
          }, {
            list_items: [
              <>wallet address.</>
            ],
          },]
        }, {
          paragraf: <>The GreatMasters and Sum & Substance may require you to provide us with additional Personal Data as you use our Platform which may vary depending on your status. You can also provide certain Personal Data in your Account.</>,
        }, {
          paragraf: <>We request your Personal Data fulfill our contract with you, and because we need to comply with legal or regulatory obligations.</>,
        }, {
          paragraf: <>We collect this information for the following purposes:</>,
          ul_list: [{
            list_items: [
              <>facilitate the creation of and secure your Account on our Platform;</>
            ],
          }, {
            list_items: [
              <>fulfill our contractual obligations;</>
            ],
          }, {
            list_items: [
              <>identify you as a user in our system, display your username next to the digital assets you wish to sell on the Platform;</>
            ],
          }, {
            list_items: [
              <>provide the Services you request, including but not limited to facilitating your cryptocurrency transactions through third-party electronic wallets;</>
            ],
          }, {
            list_items: [
              <>improve the quality of experience when you interact with our Service;</>
            ],
          }, {
            list_items: [
              <>send you e-mail notifications related to actions on the Platform, including but not limited to notifications of offers on your assets, administrative e-mail notifications, e-mails to verify ownership of the e-mail address provided when your Account was created;</>
            ],
          }, {
            list_items: [
              <>contact you about your own and third parties’ assets;</>
            ],
          }, {
            list_items: [
              <>comply with our obligations and to enforce our policies, including to interact with partners and comply with all applicable laws and regulations;</>
            ],
          }, {
            list_items: [
              <>prevent and investigate fraudulent or other criminal activity;</>
            ],
          }, {
            list_items: [
              <>meet legal and regulatory requirements;</>
            ],
          }, {
            list_items: [
              <>carry out any actions for which we have received your consent.</>
            ],
          },]
        }, {
          paragraf: <>We also may create anonymous data records from Personal Data by excluding information (for example, your name) that allows to identify you and to analyze usage patterns so that we may improve the content and navigation tools on our Platform. We reserve the right to use anonymous data for any purpose and to disclose it to third parties without restriction.</>,
        }]
      }]
    }, {
      heading: `Logging in to the Website`,
      subSection: [{
        article: [{
          paragraf: <>When you logging in to the Website we will collect following Personal Data:</>,
          ul_list: [{
            list_items: [
              <>information about the device you use to access your account, including the hardware model, operating system and version, and unique device identifiers, but this information is anonymized and not tied to any particular person;</>
            ],
          }, {
            list_items: [
              <>technical information about your use of the Platform, including the type of browser and version you use, last access time, the Internet Protocol (IP) address used to access your account;</>
            ],
          }, {
            list_items: [
              <>interaction with the Platform or marketing materials, operating systems and other technology;</>
            ],
          }, {
            list_items: [
              <>location data includes your current location disclosed by GPS technology;</>
            ],
          }, {
            list_items: [
              <>information on page response time, loading errors, duration of visits to certain pages, information about the relationship of pages (for example, scrolling, clicks and mouse hovering) and methods used to navigate from the Website;</>
            ],
          }, {
            list_items: [
              <>inferences drawn from any of the information identified above reflecting your preferences, behavior, and interests.</>
            ],
          },]
        }, {
          paragraf: <>This information is necessary for our legitimate interests (or those of a third party) and your interests and fundamental rights do not override those interests.</>,
        }, {
          paragraf: <>We collect this information for the following purposes:</>,
          ul_list: [{
            list_items: [
              <>improve our Websites, Platform and our services;</>
            ],
          }, {
            list_items: [
              <>provide personalized services (also called interest-based marketing) offered by us on third-party websites and online services;</>
            ],
          }, {
            list_items: [
              <>to tailor the marketing content and certain services experiences to better match your interests and other third-party websites</>
            ],
          },]
        }, {
          paragraf: <>We will only send you direct marketing communications by e-mail or text if we have your consent. You have the right to withdraw that consent at any time by contacting us.</>,
        }]
      }]
    }, {
      subSection: [{
        article: [{
          paragraf: <>We will process your Personal Data legally and fairly and not use it outside the purposes of which we have informed you, including selling it individually or in the aggregate for commercial use.</>
        }],
      }],
    }, {
      subSection: [{
        article: [{
          paragraf: <>We retain Personal Data in an identifiable format for the least amount of time necessary to fulfil our legal or regulatory obligations and for our business purposes. We may retain Personal Data for longer periods than required by law if it is in our legitimate business interests and is not directly prohibited by the applicable law. If your Account is deleted, we may take steps to mask Personal Data and other Data, but we reserve our right to retain and access the data for so long as required to comply with applicable laws. We will continue to use and disclose such Personal Data in accordance with this Policy.</>
        }],
      }],
    }, {
      subSection: [{
        article: [{
          paragraf: <>We also collect, use and share aggregated data such as statistical or demographic data for any purpose (<b>Aggregated Data</b>). Aggregated Data could be derived from your Personal Data but is not considered personal data under the law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your Usage Data to calculate the percentage of users accessing a specific software feature. However, if we combine or connect Aggregated Data with your Personal Data so that it can directly or indirectly identify you, we treat the combined data as personal Data which will be used in accordance with this Policy.</>
        }],
      }],
    }, {
      subSection: [{
        article: [{
          paragraf: <>We do not collect any special categories of Personal Data about you (for example, details about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political opinions, trade union membership, Data about your health, and genetic and biometric data).</>
        }],
      }],
    },]
  }, {
    sectionId: 'how_we_acquire_data',
    title: `HOW WE ACQUIRE YOUR DATA`,
    subSections: [{
      subSection: [{
        article: [{
          paragraf: <><u>Data you give us</u>. This is Data (including registration and usage data,  assets remittance data) you consent to giving us about you by filling in forms via the Platform, or by corresponding with us (for example, by email). It includes data you provide when you register with the Platform and when you report a problem with our Services or Website. If you contact us, we will keep a record of that correspondence.</>
        }]
      }]
    }, {
      subSection: [{
        article: [{
          paragraf: <><u>Data we collect about you and your devices</u>. Each time you visit our Platform or use our services we will automatically collect Personal Data including device, and usage data, some of assets remittance data and login data, for example, browser type, operating system, IP address, domain name, wallet type, and/or a date/time stamp for your visit. We collect that data to analyse trends, administer the Platform, users’ actions, gather demographic information about our user base.</>
        }]
      },]
    }, {
      subSection: [{
        article: [{
          paragraf: <><u>Data we receive from other sources including third parties and publicly available sources</u>. We will receive Personal Data about you from various third parties and public sources. For example, we may purchase or otherwise acquire such data from third party data suppliers. We reserve the right to merge or co-mingle this other data with your Personal Data collected on or through the services.</>
        }]
      }]
    }, {
      subSection: [{
        article: [{
          paragraf: <>The Platform does not currently respond to “do not track” signals or other mechanisms that might enable users to opt out of tracking on our site.</>
        }]
      },]
    }, {
      subSection: [{
        article: [{
          paragraf: <>For conducting transactions, we use third-party electronic wallets; your interactions with third-party electronic wallet extensions are governed by the applicable privacy policies.</>
        }]
      },]
    },]
  }, {
    sectionId: 'opting_out',
    title: `OPTING OUT OF MARKETING MAILINGS`,
    subSections: [{
      subSection: [{
        article: [{
          paragraf: <>If you provide us with your email address and agree to this Privacy Policy, We and our partners may send you emails regarding or the products we offer. You can choose to opt out of receiving email from us at any time. If you wish to opt out with respect to more than one email address, you must provide a separate request for each email address. Every email sent from the Company will include appropriate unsubscribe instructions. To opt out of all email marketing messages from us, you must follow the instructions provided with each email.</>
        }],
      }]
    }]
  }, {
    sectionId: 'cookies',
    title: `COOKIES`,
    description: [{
      paragraf: <>We also may use “cookies” from time to time to help personalise your online experience with us. We will process Personal Data collected through cookies in accordance with this Privacy Policy and <Link className="legal__link" to="/cookies">Cookies Policy</Link>. If you have set your browser to warn you before accepting cookies, you should receive a warning message with each cookie. You may refuse cookies by turning them off in your browser, however, you should be aware that our sites, like most other sites, may not work well with cookies disabled.</>
    }],
  }, {
    sectionId: 'disclosures_of_personal_data',
    title: `DISCLOSURES OF YOUR PERSONAL DATA`,
    subSections: [{
      subSection: [{
        article: [{
          paragraf: <>To verify your Account, we may disclose Personal Data to third parties such as Sum & Substance, financial institutions, third-party reference agencies and data verification service providers to comply with our legal obligations. We will also disclose your Personal Data to other third parties including, but not limited to regulatory authorities and law enforcement agencies; non-affiliated third parties for payments processing, payments, sales, data analysis, research and surveys; and/or professional advisors such as tax or legal advisors, consultants and accountants.</>
        }],
      }]
    }, {
      subSection: [{
        article: [{
          paragraf: <>We may share some or all of your Personal Data with third party service providers to:</>,
          ul_list: [{
            list_items: [
              <>provide you with the Services that we offer you through our Platform;</>,
            ]
          }, {
            list_items: [
              <>to conduct quality testing;</>,
            ]
          }, {
            list_items: [
              <>to facilitate creation of accounts;</>,
            ]
          }, {
            list_items: [
              <>to provide technical support; and/or to provide other services.</>,
            ]
          },]
        }]
      }]
    }, {
      subSection: [{
        article: [{
          paragraf: <>We may share some or all of your Personal Data in connection with potential corporate changes, such as merger, financing, acquisition, dissolution transaction or proceeding involving sale, transfer, divestiture, or disclosure of all or a portion of our business or assets. In the event of an insolvency, bankruptcy, or receivership, Personal Data may also be transferred. If another company acquires our company, business, or assets, that company will possess the Personal Data collected by us.</>
        }]
      }]
    }, {
      subSection: [{
        article: [{
          paragraf: <>We also may disclose your Personal Data to third-party contractors to maintain our Platform or, for example, receive administrative services, when needed.</>
        }]
      }]
    },]
  }, {
    sectionId: 'international_transfers',
    title: `INTERNATIONAL TRANSFERS`,
    description: [{
      paragraf: <>Your Personal Data is processed and maintained on servers and databases that are located within the territory of our jurisdiction where there is a sufficient level of data privacy protection. We reserve the right to transfer your Data to and from any state, province, country or other governmental jurisdiction. Your consent to this Privacy Policy followed by your submission or our collection of such Personal Data represents your agreement to any such transfer. We will ensure that transfers of Personal Data to a third country or an international organisation are subject to appropriate safeguards.</>,
    }],
  }, {
    sectionId: 'legal_rights',
    title: `YOUR LEGAL RIGHTS`,
    subSections: [{
      subSection: [{
        article: [{
          paragraf: <>Under certain circumstances you have the following rights under data protection laws in relation to your Personal Data. Please be aware that any requests will be processed only after your identity is confirmed. This limitation protects your Data against fraudulent actors wishing to access or harvest personal data of our users for personal, commercial, or illegitimate uses. Please note that we will not discriminate against you for exercising your rights under this policy and applicable legislation.</>
        }]
      }]
    }, {
      subSection: [{
        article: [{
          paragraf: <>You have the right to:</>,
          subArticles: [{
            subArticle: [{
              paragraf: <><b>Request access</b> to your Personal Data (commonly known as a “data subject access request”). This enables you to receive a copy of the Personal Data we hold about you and to check that we are lawfully processing it. We will provide a response only regarding your Personal Data collected within the last twelve (12) months before the request. When legally obliged, you may be provided with the following:
              </>,
              ol_list: [{
                list_items: [
                  <>The categories of Personal Data collected about that specific individual in the twelve (12) months preceding the request;</>
                ],
              }, {
                list_items: [
                  <>The categories of sources for that Personal Data collected;</>
                ],
              }, {
                list_items: [
                  <>The business or commercial purposes for collecting or selling that Personal Data;</>
                ],
              }, {
                list_items: [
                  <>The categories of third parties with whom the business shares that Personal Data;</>
                ],
              }, {
                list_items: [
                  <>The specific pieces of Personal Data collected.</>
                ],
              },]
            }, {
              paragraf: <><b>Request deletion</b> of your Personal Data. This enables you to ask us to delete or remove Personal Data where there is no good reason for us continuing to process it. You also have the right to ask us to delete or remove your Personal Data where you have successfully exercised your right to object to processing (see below), where we may have processed your Data unlawfully or where we are required to erase your Personal Data to comply with local law. Note, however, that we may not always be able to comply with your request of erasure for specific legal reasons which will be notified to you, if applicable, at the time of your request.</>
            }, {
              paragraf: <><b>Request the transfer</b> of your Personal Data to you or to a third party. We will provide to you, or a third party you have chosen, your Personal Data in a structured, commonly used, machine-readable format. Note that this right only applies to automated data which you initially provided consent for us to use or where we used the data to perform a contract with you.</>
            }, {
              paragraf: <><b>Withdraw consent at any time</b> where we are relying on consent to process your Personal Data. However, this will not affect the lawfulness of any processing carried out before you withdraw your consent. If you withdraw your consent, we may not be able to provide certain services to you. We will advise you if this is the case at the time you withdraw your consent. We also reserve a right to set more incentive prices for those users who are providing their consent. Nevertheless, you will never be discriminated against on the basis of your decision to withdraw your consent.</>
            }]
          }]
        }]
      }]
    }, {
      subSection: [{
        article: [{
          paragraf: <>You also have the right to ask us not to continue to process your Personal Data for marketing purposes.</>
        }]
      }],
    }, {
      subSection: [{
        article: [{
          paragraf: <>You can exercise any of these rights by contacting us at <a className="legal__link" href={`mailto:${contactEmail}`}
          target="_blank" rel="noreferrer">{contactEmail}</a>.</>
        }]
      }]
    }, {
      subSection: [{
        article: [{
          paragraf: <>We will respond to your request within forty-five (45) to ninety (90) calendar days and provide any data free of charge, unless manifestly unfounded or excessive.</>
        }]
      }]
    }]
  }, {
    sectionId: 'changes_to_privacy_pilicy',
    title: `CHANGES TO THE PRIVACY POLICY`,
    description: [{
      paragraf: <>This Policy may be modified from time to time, so check back often. To make you aware of the changes that have been made, we will amend the <b>“Last Revised”</b> date at the beginning of this document. The new policy may be displayed on-screen, and you may be required to read and accept the changes to continue your use of the Platform. Continued access to the Platform by you will constitute your acceptance of any changes or revisions to this Policy.</>
    }]
  }, {
    sectionId: 'compliance_with_applicable_legislation',
    title: `COMPLIANCE WITH APPLICABLE LEGISLATION`,
    description: [{
      paragraf: <>We are committed to complying with applicable privacy and data protection laws and regulations designed to protect your personal information, including, but not limited to, both of the European Union (“EU”) and United Kingdom (“UK”) versions of the General Data Protection Regulation (“GDPR”), the UK Data Protection Act of 2018, the California Consumer Privacy Act, and other applicable current or future regional, national and state privacy and data protection laws and regulations worldwide as they become effective.</>
    }]
  },]
}
