import React, { createContext } from "react";
import { ModalContextInterface } from "./types";
import AppModal from './Modal';
import useModal from './useModal';

type Props = {
  children: React.ReactNode,
}

export const ModalContext = createContext<ModalContextInterface>({
  handleModalOpen: () => null,
  handleModalClose: () => null,
  modalContent: null,
  modalIsOpen: false,
  modalData: {
    title: '',
    subTitle: '',
    className: ''
  },
});

const { Provider } = ModalContext;

let ModalProvider = ({ children } : Props) => {
  let { handleModalOpen, 
    handleModalClose, 
    modalContent, 
    modalIsOpen, 
    modalData 
  } = useModal();

  return (
    <Provider value={{ 
      handleModalOpen, 
      handleModalClose, 
      modalContent, 
      modalIsOpen, 
      modalData 
    }}>
      <AppModal />
      {children}
    </Provider>
  );
};

export default ModalProvider;