import { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import './getInviteModal.sass';
import Tooltip from '../../components/Tooltip/Tooltip';
import {
  getUserReferrer,
  getUserPublicName,
  selectUserReferrer,
  selectUserPublicName,
} from '../../store/features/userData/userDataSlice';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { AppThunk } from '../../store/store';
import ButtonGetInvite from './ButtonGetInvite';
import { ModalContext } from '../Modal/ModalProvider';
import RefInput from '../../components/RefInput/RefInput';
import RefCheckbox from '../../components/RefCheckbox/RefCheckbox';
import { statusesContract, web3 } from '../../network/service/web3';
import {
  getInviteModalTooltipName,
  getInviteModalTooltipReferrer
} from '../modalTexts';

function GetInviteModal() {
  const dispatch = useAppDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const [isInputsFilled, setInputsFilled] = useState(false);
  const [isWarningShown, setShowWarning] = useState(false);
  const publicName = useAppSelector(selectUserPublicName);
  const referrer = useAppSelector(selectUserReferrer);
  const modalRef = useRef<HTMLDivElement>(null);
  const publicNameInput = useRef<HTMLInputElement>(null);
  const referrerInput = useRef<HTMLInputElement>(null);
  const checkbox = useRef<HTMLInputElement>(null);
  const isReferrerFilled = referrer !== '' || isChecked;
  const [isAgent, setIsAgent] = useState(false);
  const [isNoAgentShowWarning, setIsNoAgentShowWarning] = useState(false);

  const { handleModalClose } = useContext(ModalContext);

  const handleInputValue = (
    e: ChangeEvent<HTMLInputElement>,
    getState: (value: string) => AppThunk,
    ref?: React.RefObject<HTMLInputElement>,
  ) => {
    dispatch(getState(e.target.value));
    
    if (e.target.value.trim().length > 0) {
      setShowWarning(false);
    } else if (e.target.value === ''){
      setShowWarning(false);
    } else {
      setShowWarning(true);
    }

    if (ref?.current && referrerInput.current) {
      if (referrerInput.current.value !== '') {
        ref.current.disabled = true;
      } else {
        ref.current.disabled = false;
      }
    }
  }

  useEffect(() => {
    if (referrerInput.current) {
      if (isChecked === true) {
        referrerInput.current.style.borderColor = '#E7E8FF';
        referrerInput.current.style.backgroundColor = '#F6F6F6';
        referrerInput.current.disabled = true;
        referrerInput.current.required = false;
        referrerInput.current.value = '';
      } else {
        referrerInput.current.style.borderColor = '';
        referrerInput.current.style.backgroundColor = '';
        referrerInput.current.disabled = false;
        referrerInput.current.required = true;
        referrerInput.current.value = referrer;
      }
    }
  }, [dispatch, isChecked, referrer])

  useEffect(() => {
    if (publicName.trim().length > 0 && isReferrerFilled) {
      setInputsFilled(true);
    } else {
      setInputsFilled(false);
    }
  }, [isReferrerFilled, publicName])

  useEffect(() => {
    function handleClickOutside(event: any) {
      const modal = event.target.classList.contains('ReactModal__Content');
      if (modal) {
        handleModalClose();
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [modalRef, handleModalClose])

  useEffect(() => {
    if (referrer.length > 0) {
      const isAddress = web3.utils.isAddress(referrer);
      if (isAddress) {
        statusesContract.methods.isAgent(referrer).call()
          .then((result: boolean) => {
            setIsAgent(result)
          })
      } else {
        setIsAgent(false)
        setIsNoAgentShowWarning(true);
      }
    }
  }, [referrer])

  useEffect(() => {
    if (!isAgent && referrer.length > 0) {
      setIsNoAgentShowWarning(true);
    } else if (referrer.length === 0 || isAgent) {
      setIsNoAgentShowWarning(false);
    }
  }, [isAgent, referrer.length])

  useEffect(() => {
    if (isChecked) {
      setIsAgent(true);
      setIsNoAgentShowWarning(false);
    } 
  }, [isChecked])

  return (
    <div
      ref={modalRef}
      className="get_invite__data"
    > 
      <div className="get_invite__item">
        <RefInput
          ref={publicNameInput}
          type="text" 
          id="publicName"
          value={publicName}
          placeholder="Enter your Public name here"
          isRequired={true}
          className="publicName_input"
          onChange={(e) => handleInputValue(e, getUserPublicName)}
          label="Public name"
        />
        <Tooltip
          innerText={getInviteModalTooltipName}
          className="get_invite_modal"
        />
      </div>
      <div className="get_invite__item">
        <RefInput
          ref={referrerInput} 
          type="text" 
          id="referrer"
          value={isChecked ? '': referrer}
          placeholder={isChecked ? 'No referrer' :'Enter referral ID here'}
          isRequired={true}
          className="referrer_input"
          onChange={(e) => handleInputValue(e, getUserReferrer)}
          label="Referrer ID"
        />
        <Tooltip
          innerText={getInviteModalTooltipReferrer}
          className="get_invite_modal"
        />
      </div>
      <RefCheckbox
        ref={checkbox}
        id="accept"
        className="modal_invite__no_refferer"
        setIsChecked={setIsChecked}
        isChecked={isChecked}
        setShowWarning={setShowWarning}
        data={referrer}
        label="I don't have referrer"
      />
      { isWarningShown && 
        <div className="get_invite_modal__warning">Please fill required fields</div>
      }
      { isNoAgentShowWarning && 
        <div className="get_invite_modal__warning">There is no such referrer, please check your referrer ID</div>
      }
      <ButtonGetInvite
        isInputsFilled={isInputsFilled}
        setShowWarning={setShowWarning}
        isAgent={isAgent}
        setIsNoAgentShowWarning={setIsNoAgentShowWarning}
      />
    </div>
  )
};

export default GetInviteModal;