import React, { useState } from "react";
import { ModalData } from "./types";

export default function useModal() {
  let [modalIsOpen, setModal] = useState(false);
  let [modalContent, setModalContent] = useState<any>(null);
  let [modalData, setModalData] = useState<ModalData>({});

  let handleModalOpen = (node?: React.ReactNode, data?: ModalData, key?: string) => {
    setModal(!modalIsOpen);
    if (node) {
      setModalContent(node);
      if (data) {
        setModalData(data)
      }
    }
  };

  let handleModalClose = () => {
    setModal(false);
    setModalContent('');
    setModalData({})
  }

  return { modalData, modalIsOpen, handleModalOpen, handleModalClose, modalContent };
};
