const { create } = require('ipfs-http-client')

const client = create({
  url: 'https://ipfs.infura.io:5001/api/v0',
  protocol: 'https',
  port: 5001,
  path: '/api/v0'
})

export async function ipfsInfuraUpload(
  files: File[],
  setArtHash: React.Dispatch<React.SetStateAction<string>>, 
  setArtHashIsSetted: React.Dispatch<React.SetStateAction<boolean>>, 
  setDocHash: React.Dispatch<React.SetStateAction<string>>, 
  setDocHashIsSetted: React.Dispatch<React.SetStateAction<boolean>>,
) {
  const result = [];

  for await (const resultPart of client.addAll(files, {
    pin: true
  })) {
    result.push(resultPart)
  }
  setArtHash(result[0].path)
  setDocHash(result[1].path)
  setArtHashIsSetted(true)
  setDocHashIsSetted(true)
  return result
}

export async function ipfsInfuraUploadDetails (file: string) {
  const result = await client.add(file, {
    content: `${file}`,
    pin: true,
  })

  return result
}
